import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useEffect, useState} from "react";
import CheckinSettings from "./wellness-settings-templates/CheckinSettings";
import RoundsSettings from "./wellness-settings-templates/RoundsSettings";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import EscalationSettings from "./wellness-settings-templates/EscalationSettings";
import {formFail, formSuccess, handleSubmitActions, iAmHigherAuthority, isBannerPresent} from "../libs";

const WellnessSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Main Needed data for page
    const [loadState, setLoadState] = useState("checkin");
    const [wellnessContent, setWellnessContent] = useState(<></>);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [count, setCount] = useState(1);

    // Data collected for sub components
    const [checkinData, setCheckinData] = useState({});
    const [roundsData, setRoundsData] = useState([]);
    const [escData, setEscData] = useState([]);
    const [resData, setResData] = useState([]);
    const [resGroupData, setResGroupData] = useState([]);
    const [devData, setDevData] = useState([]);
    const [escTypeData, setEscTypeData] = useState([]);
    const [campusData, setCampusData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [areaGroupData, setAreaGroupData] = useState([]);

    // Loading state flags.
    const [allLoaded, setAllLoaded] = useState(false);
    const [checkinLoaded, setCheckinLoaded] = useState(false);
    const [roundsLoaded, setRoundsLoaded] = useState(false);
    const [resLoaded, setResLoaded] = useState(false);
    const [devLoaded, setDevLoaded] = useState(false);
    const [escLoaded, setEscLoaded] = useState(true);
    const [escTypeLoaded, setEscTypeLoaded] = useState(false);
    const [campusLoaded, setCampusLoaded] = useState(false)
    const [defEscLoaded, setDefEscLoaded] = useState(false);
    const [groupsLoaded, setGroupsLoaded] = useState(false);
    const [resGroupLoaded, setResGroupLoaded] = useState(false);
    const [areaGroupLoaded, setAreaGroupLoaded] = useState(false);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0) {
            // Checkin items
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                            procedure: "sp_dev_getcheckinwindow", reqType: "stored"}).then(data => {
                setCheckinData(() => data);
                setCheckinLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getresidentsbycampusid", reqType: "stored"}).then(data => {
                setResData(() => data ? data : []);
                setResLoaded(true);
            });
            // Rounds items
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                            procedure: "sp_dev_getroundswindow", reqType: "stored"}).then(data => {
                setRoundsData(() => data);
                setRoundsLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                          procedure: "sp_dev_getescalationbycampusid", reqType: "stored"}).then(data => {
                setEscData(data);
                setEscLoaded(true)
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_event_categories",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEscTypeData(data);
                setEscTypeLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getgroupsbycampusid", reqType: "stored"}).then(data => {
                setGroupData(data);
                setGroupsLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_campus",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setCampusData(data);
                setCampusLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setDevData(data);
                setDevLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_resident_groups",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setResGroupData(data);
                setResGroupLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_area_groups",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setAreaGroupData(data);
                setAreaGroupLoaded(true);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    const navigation = (
        <div className={`row align-items-center ${isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}`}
             style={{backgroundColor: "#f9fbfd"}}>
            <div className={"col"}>
                <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                    {allLoaded
                        ? <>
                            <li key={"set-checkin"} className={"nav-item"}>
                                <Button text={"Check-in"} class={loadState === "checkin" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("checkin")} />
                            </li>
                            <li key={"set-rounds"} className={"nav-item"}>
                                <Button text={"Rounds"} class={loadState === "rounding" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("rounding")} />
                            </li>
                            <li key={"set-escalation"} className={"nav-item"}>
                                <Button text={"Careflow"} class={loadState === "escalation" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("escalation")} />
                            </li>
                        </>
                        : <></>
                    }
                </ul>
            </div>
        </div>);


    useEffect(() => {
        if (allLoaded){
            if (loadState === "checkin"){
                setWellnessContent(<CheckinSettings setCount={(value) => setCount(value)}
                                                    data={checkinData} resGroupData={() => resGroupData} />);
            }else if (loadState === "rounding"){
                setWellnessContent(<RoundsSettings setCount={(value) => setCount(value)}
                                                   data={roundsData} areaGroupData={areaGroupData} />);
            }else if (loadState === "escalation"){
                setWellnessContent(<EscalationSettings setCount={(value) => setCount(value)} groupData={groupData}
                                                       escData={escData} devData={devData} resGroupData={resGroupData}
                                                       escTypeData={escTypeData} campusData={campusData}
                                                       areaGroupData={areaGroupData} />);
            }else{
                setWellnessContent(<></>);
            }
        }else{
            setWellnessContent(<p>Wellness settings loading. . .</p>);
        }
    }, [loadState, roundsData, allLoaded, checkinData, resData, campusData, groupData, escData, escTypeData, devData, areaGroupData, resGroupData]);

    useEffect(() => {
        if (![checkinLoaded, roundsLoaded, resLoaded, escTypeLoaded, campusLoaded, escLoaded, groupsLoaded, devLoaded, areaGroupLoaded].includes(false)){
            setAllLoaded(true);
        }
    }, [checkinLoaded, roundsLoaded, resLoaded, escTypeLoaded, campusLoaded, escLoaded, groupsLoaded,
                devLoaded, resGroupLoaded, areaGroupLoaded]);

    function syncSubmit(){
        let msg;
        handleSubmitActions("syncSubmit", "syncLoadSubmit");
        ApiRequester({reqEndpoint: "mqtt", Operation: "reboot-server", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if(data[0]){
                msg = "Sync triggered, please allow up to 3 minutes for changes to take effect.";
                formSuccess(msg, "syncSubmit", "syncLoadSubmit");
            }
            else{
                msg = "Error syncing, contact support for assistance if error persists.";
                formFail(msg, "syncSubmit", "syncLoadSubmit");
            }
        });
    }

    function restoreSubmit(){
        let msg;
        handleSubmitActions("restoreSubmit", "restoreLoadSubmit");
        ApiRequester({reqEndpoint: "mqtt", Operation: "restore-server", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if(data[0]){
                msg = "Restore triggered, please allow up to 3 minutes for changes to take effect.";
                formSuccess(msg, "restoreSubmit", "restoreLoadSubmit");
            }
            else{
                msg = "Error restoring, contact support for assistance if error persists.";
                formFail(msg, "restoreSubmit", "restoreLoadSubmit");
            }
        });
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                {iAmHigherAuthority(99, dData?.role_weight)
                    ? <Button class={"btn btn-primary me-4"} iconClass={"material-icons md-18 md-dark me-2 align-text-bottom"}
                         icon={true} iconSize={"tiny"} iconColor={"#FFFFFF"} iconType={"google"} iconName={"restore_page"}
                         text={"Restore Data"} id={"restoreSubmit"} style={{height: "41.5px", whiteSpace: "nowrap"}} onClick={() => restoreSubmit()}
                         title={"Restore data performs the sync local reboot, however deletes the database file triggering a re-create."} />
                    : <></>}
                <Button class={"btn btn-primary me-4 d-none"} disabled={true} spinner={true} readonly={true} style={{height: "41.5px"}}
                         text={"Restoring..."} id={"restoreLoadSubmit"} />
                <Button class={"btn btn-primary me-4"} iconClass={"material-icons md-18 md-dark me-2 align-text-bottom"}
                         icon={true} iconSize={"tiny"} iconColor={"#FFFFFF"} iconType={"google"} iconName={"cloud_sync"}
                         text={"Sync Local"} id={"syncSubmit"} style={{height: "41.5px", whiteSpace: "nowrap"}} onClick={() => syncSubmit()}
                         title={"Syncs local server with cloud data, will cause the server to go offline momentarily during the process"} />
                <Button class={"btn btn-primary me-4 d-none"} disabled={true} spinner={true} readonly={true} style={{height: "41.5px"}}
                         text={"Syncing..."} id={"syncLoadSubmit"} />
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {
                setAllLoaded(false);
                setCheckinLoaded(false);
                setRoundsLoaded(false);
                setResLoaded(false);
                setDefEscLoaded(false);
                setEscLoaded(false);
                setEscTypeLoaded(false);
                setCampusLoaded(false);
                setGroupsLoaded(false);
                setAreaGroupLoaded(false);
                setResGroupLoaded(false);
            }} />
        </div>
    )

    return (
        <div className={"main-content"}>
            <Header preTitle={"Settings"} Title={"Wellness Settings"} content={headerContent} />
            <div className={"container-fluid"}>
                {navigation}
                {wellnessContent}
            </div>
            <div className={"footer-spacer"}> </div>
            <Footer left={loadState === "escalation" ? <h3 style={{textAlign: "left"}}>* The PalCare alert system is ENABLED by default. The system broadcasts an emergency alert to users of the PalMobile application.
                Each resident device is ACTIVE throughout the 24/7 cycle, every day of the week. To alter the system settings for generation of the alerts,
                please use the attached screens</h3> : <></>} />
        </div>
    );
};

export default WellnessSettings;