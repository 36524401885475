import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import TableHeaders from "../component-assets/TableHeaders";
import TableBodySimple from "../component-assets/TableBodySimple";
import {isBannerPresent} from "../../libs";

const CheckInHistory = (props) => {
    let dData, tableBody, tableBody2, summaryClass, detailedClass, headerData, headerData2;

    headerData = [
        {label: "Resident", key: "resident_name"},
        {label: "Check In Date", key: "generated_alarm_time", align: "center", type: "datetime-date"},
        {label: "Check In Start", key: "window_start", align: "center", type: "timeofday-utc"},
        {label: "Check In Alarm Time", key: "alarm_at", align: "center", type: "timeofday-utc"},
        {label: "Check In End", key: "window_end", align: "center", type: "timeofday-utc"},
        {label: "Status", key: "in_window", align: "center", type: "bool", t: "Checked In", f: "Not Checked In"},
        {label: "Check In Time", key: "local_last_check_in", align: "center", type: "datetime"},
        {label: "Alarm Generated", key: "alarm_generated", align: "center", type: "bool", t: "Yes", f: "No"},
        {label: "Check In Description", key: "description"}
    ]
    headerData2 = [
        {label: "Resident", key: "resident_name"},
        {label: "Success Check-ins", key: "check_in_success_count", align: "center"},
        {label: "Failed Check-ins", key: "check_in_fail_count", align: "center"},
        {label: "Last Check-in", key: "local_last_check_in", align: "center"}
    ]


    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (props.repType === "Detailed"){
        summaryClass = "d-none";
        detailedClass = "";
    }else{
        summaryClass = "";
        detailedClass = "d-none";
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={props?.sorter?.sortOrder}
                                       sortColumn={props?.sorter?.sortColumn} sorter={props?.sorter} setSorter={props?.setSorter}/>
    const tableHeaders2 = <TableHeaders checkbox={false} headerData={headerData2} sortOrder={props?.sorter?.sortOrder}
                                       sortColumn={props?.sorter?.sortColumn} sorter={props?.sorter} setSorter={props?.setSorter}/>
    if (props?.tableRows !== undefined){
        tableBody = (<TableBodySimple tableData={props.tableRows} checkbox={false} tableHeaders={headerData}
                                      tzOffset={parseInt(dData?.campusData[props?.campusId]?.tz_offset ? dData?.campusData[props?.campusId]?.tz_offset : 0)} />)
    }else{
        tableBody = ""
    }
    if (props?.summaryRows !== undefined){
        tableBody2 = (<TableBodySimple tableData={props.summaryRows} checkbox={false} tableHeaders={headerData2}
                                       tzOffset={parseInt(dData?.campusData[props?.campusId]?.tz_offset ? dData?.campusData[props?.campusId]?.tz_offset : 0)} />)
    }else{
        tableBody2 = ""
    }

    return (
        <>
            <div className={summaryClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {props.repType}: <strong className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</strong> - <strong className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</strong>
                </h6>
                <div className={"pt-1 pb-4 px-4 my-2"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders2}
                        </thead>
                        <tbody>
                            {tableBody2}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={detailedClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {dData.campus} {props.repType} Report: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</span> - <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</span>
                </h6>
                <div className={"pt-1 pb-4 px-4 my-2"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {tableBody}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default CheckInHistory;