import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useEffect, useState} from "react";
import LegacyAppSystemSettings from "./system-settings-templates/LegacyAppSystemSettings";
import LegacyAlertSystemSettings from "./system-settings-templates/LegacyAlertSystemSettings";
import LegacyCustomSystemSettings from "./system-settings-templates/LegacyCustomSystemSettings";
import LegacyAFRSystemSettings from "./system-settings-templates/LegacyAFRSystemSettings";
import LegacyReasonSystemSettings from "./system-settings-templates/LegacyReasonSystemSettings";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import {campusToGuid, isBannerPresent} from "../libs";
import ServerSystemSettings from "./system-settings-templates/ServerSystemSettings";

const LegacySystemSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [loadState, setLoadState] = useState("app");
    const [mobileContent, setMobileContent] = useState(<></>);
    const [configData, setConfigData] = useState({});
    const [deviceData, setDeviceData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [globalReasons, setGlobalReasons] = useState([]);
    const [serverData, setServerData] = useState({});
    const [loading, setLoading] = useState(true);
    const [mainLoading, setMainLoading] = useState(true);
    const [deviceLoading, setDeviceLoading] = useState(true);
    const [reasonLoading, setReasonLoading] = useState(true);
    const [globalLoading, setGlobalLoading] = useState(true);
    const [serverLoading, setServerLoading] = useState(true);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [count, setCount] = useState(1);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0) {
            ApiRequester({reqEndpoint: "getitem", Payload: {Table: "CampusConfig",
                                PartitionKey: "CampusId", PartitionValue: campusToGuid(dData, campusId),
                                CampusId: campusToGuid(dData, campusId)}}).then(data => {
                    setConfigData(data ? data : []);
                    setMainLoading(false);
            });
            ApiRequester({reqEndpoint: "redget", Table: "devices", CampusId: campusToGuid(dData, campusId),
                                Column: "devicename", GetType: "distinct",
                                SortColumn: "devicename", "SortOrder": "ASC"}).then(data => {
                    let dataList = [];
                    if (!data){
                        data = [];
                    }
                    for (let index1=0; index1 < data?.length ? data.length : 0; index1++){
                        dataList.push(data[index1].devicename);
                    }
                    setDeviceData(dataList);
                    setDeviceLoading(false);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "CampusReasonCode", PKey: "CampusId", CampusId: campusToGuid(dData, campusId),
                                PVal: campusToGuid(dData, campusId)}).then(data => {
                setReasonData(data ? data : []);
                setReasonLoading(false);
            });
            ApiRequester({reqEndpoint: "getall", CampusId: campusToGuid(dData, campusId),
                                Table: "ReasonCode", Search: "", Projection: ""}).then(data => {
                setGlobalReasons(data ? data : []);
                setGlobalLoading(false);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "server_system_config", reqType: "stored"}).then(data => {
                setServerData(data ? data : {});
                setServerLoading(false);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![globalLoading, reasonLoading, deviceLoading, mainLoading, serverLoading].includes(true)){
            setLoading(false);
        }
    }, [globalLoading, reasonLoading, deviceLoading, mainLoading, serverLoading]);

    const navigation = (
        <div className={`row align-items-center ${isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}`}
             style={{backgroundColor: "#f9fbfd"}}>
            <div className={"col"}>
                <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                    {!loading
                        ? <>
                            <li key={"set-app"} className={"nav-item"}>
                                <Button text={"App"} class={loadState === "app" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("app")}/>
                            </li>
                            <li key={"set-custom"} className={"nav-item"}>
                                <Button text={"Customization"}
                                        class={loadState === "custom" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("custom")}/>
                            </li>
                            <li key={"set-alert"} className={"nav-item"}>
                                <Button text={"Alerts"} class={loadState === "alert" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("alert")}/>
                            </li>
                            <li key={"set-device"} className={"nav-item"}>
                                <Button text={"Devices"} class={loadState === "device" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("device")}/>
                            </li>
                            <li key={"set-reason"} className={"nav-item"}>
                                <Button text={"Reasons"} class={loadState === "reason" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("reason")}/>
                            </li>
                            <li key={"set-server"} className={"nav-item"}>
                                <Button text={"Server"} class={loadState === "server" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("server")}/>
                            </li>
                        </>
                        : <></>}
                </ul>
            </div>
        </div>);


    useEffect(() => {
        if (!loading) {
            if (loadState === "alert") {
                setMobileContent(<LegacyAlertSystemSettings AcceptButtonEnabled={!!configData?.AcceptButtonEnabled}
                                                            FinishButtonEnabled={!!configData?.FinishButtonEnabled}
                                                            ReasonCodeEnabled={!!configData?.ReasonCodeEnabled}
                                                            ClearTimeInterval={configData?.ClearTimeInterval ? configData.ClearTimeInterval : 1}
                                                            FinishTimeInterval={configData?.FinishTimeInterval ? configData.FinishTimeInterval : 1}
                                                            ReleaseTimeInterval={configData?.ReleaseTimeInterval ? configData.ReleaseTimeInterval : 1}
                                                            HighPriorityAlertTypes={configData?.HighPriorityAlertTypes ? configData.HighPriorityAlertTypes : []}
                                                            setGlobalConfigData={(value) => setConfigData(value)}/>);
            }else if(loadState === "app"){
                setMobileContent(<LegacyAppSystemSettings AlertTone={configData?.AlertTone ? configData.AlertTone : ""}
                                                          CampusTimeZone={configData?.CampusTimeZone ? configData.CampusTimeZone : 0}
                                                          LogOutTimeInterval={configData?.LogOutTimeInterval ? configData.LogOutTimeInterval : 0}
                                                          setGlobalConfigData={(value) => setConfigData(value)}/>);
            }else if(loadState === "custom"){
                setMobileContent(<LegacyCustomSystemSettings UserEditDisplayName={!!configData?.UserEditDisplayName}
                                                             CaregiverChgToneEnabled={!!configData?.CaregiverChgToneEnabled}
                                                             CaregiverChgGroupEnabled={!!configData?.CaregiverChgGroupEnabled}
                                                             setGlobalConfigData={(value) => setConfigData(value)}/>);
            }else if(loadState === "device") {
                setMobileContent(<LegacyAFRSystemSettings AFRDevices={configData?.AFRDevices} deviceData={deviceData}
                                                          setGlobalDeviceData={(value) => setConfigData(value)}/>);
            }else if(loadState === "reason"){
                setMobileContent(<LegacyReasonSystemSettings ReasonCodes={reasonData} GlobalCodes={globalReasons}
                                                             setGlobalReasonData={(value) => setReasonData(value)}/>);
            }else if(loadState === "server"){
                setMobileContent(<ServerSystemSettings serverData={serverData}
                                                       emergency_contact={serverData?.emergency_contact ? serverData.emergency_contact : ""}
                                                       setServerData={(value) => setServerData(value)}/>);
            }else{
                setMobileContent(<></>);
            }
        }else{
            setMobileContent(<p>System settings loading. . .</p>);
        }
    }, [loadState, configData, loading]);

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {
                setLoading(true);
                setDeviceLoading(true);
                setReasonLoading(true);
                setGlobalLoading(true);
            }} />
        </div>
    )

    return (
        <div className={"main-content"}>
            <Header preTitle={"Settings"} Title={"System Settings"} content={headerContent}/>
            <div className={"container-fluid"}>
                {navigation}
                {mobileContent}
                <div className={"footer-spacer"}>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LegacySystemSettings;