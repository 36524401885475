import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {toast, ToastContainer} from "react-toastify";
import {Modal} from 'bootstrap';
import {campusToGuid, getUTCTimestamp} from "../../libs";

const LegacyAFRSystemSettings = (props) => {

    let dData, clsname;

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const shortList = [
        "AIO Keypad Advanced",
        "AIO Station",
        "Call Cord",
        "Delayed Egress",
        "Door Sensor",
        "Door Sensor with Keypad",
        "Door Sensor with Reset",
        "Doorbell",
        "Fixed Pressure Pad",
        "Mobile Pressure Pad",
        "Motion Detector",
        "Pendant",
        "Pull Cord",
        "Smoke Transmitter",
        "Wandering",
        "Wandering Interface",
        "Wandering Tag",
        "Wandering Transmitter",
        "Wandering Transmitter with Reset",
        "Water Resistant Pendant",
        "Waterproof Pendant",
        "Window Sensor"
    ]

    const [displayData, setDisplayData] = useState([])
    const [useFullList, setUseFullList] = useState(false);
    const [deviceList, setDeviceList] = useState(props?.AFRDevices);
    const [saveList, setSaveList] = useState(props?.AFRDevices);

    useEffect(() => {
        if (useFullList){
            setDisplayData(() => props.deviceData);
        }
        else{
            setDisplayData(() => fillShortList());
        }
    }, [useFullList])


    function removeItem(theArray, theValue){
        theArray = theArray.filter(item => item !== theValue)
        return theArray
    }

    function performSave(){
        let payload, load, save, timestamp;
        save = document.getElementById("editSubmit");
        load = document.getElementById("loadEditSubmit");
        save.classList.add("d-none");
        load.classList.remove("d-none");
        timestamp = getUTCTimestamp();
        payload = {AFRDevices: saveList, CampusId: campusToGuid(dData, dData?.acnt), ModifiedBy: dData.username, DateModified: timestamp};
        ApiRequester({reqEndpoint: "save", Tbl: "CampusConfig", PKey: "CampusId", Payload: [payload],
                            CampusId: campusToGuid(dData, dData?.acnt)}).then(data => {
            if(data[0]){
                setDeviceList(saveList);
                props?.setGlobalDeviceData(prevVal => ({
                    ...prevVal, AFRDevices: saveList}));
                toast.success("Saved app settings.");
                closeModal("editClose");
            }
            else{
                toast.error("Error encountered, save failed.");
            }
            save.classList.remove("d-none");
            load.classList.add("d-none");
        })
    }

    function modalOpen(modalId){
        let modal;
        modal = new Modal(document.getElementById(modalId), {});
        modal.show();
    }

    function closeModal(closeBtn){
        document.getElementById(closeBtn).click();
        setSaveList(deviceList);
    }

    function fillShortList(){
        let deviceArray = shortList;
        for (let ii=0; ii < deviceList?.length ? deviceList.length: 0; ii++){
            if (!deviceArray?.includes(deviceList[ii])){
                deviceArray.push(deviceList[ii])
            }
        }
        return deviceArray
    }

    return (
        <>
            <div className={"card"} style={{maxWidth: "75vw"}}>
                <div className={"card-header"}>
                    <h4 className={"card-header-title"}>Accept Finish Reason Devices</h4>
                    <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Add/Remove"} class={"btn btn-primary"} onClick={() => modalOpen("editModal")} />
                </div>
                <div className={"card-body"}>
                    <div className={"list-group list-group-flush my-n3"}>
                        {deviceList?.map((device, d) => {
                            return (<div key={"device"+d} className={"ps-4 mb-2"}>
                                        {device}
                                    </div>)
                        })}
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editModal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Accept Finish Reason Devices</h2>
                            <button type={"button"} className={"btn-close"} id={"editClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <div className={"modal-body"}>
                            <h5 className={"py-3 mb-4 setHover"} style={{textAlign: "right", cursor: "pointer", borderBottom: "1px #e7e7e7 solid"}}
                                onClick={() => setUseFullList(!useFullList)}>{useFullList ? "See short list" : "See full list"}</h5>
                            <div className={"list-group list-group-flush my-n3"} style={{maxHeight: "600px", overflowY: "auto"}}>
                                {displayData?.map((dev, nd) => {
                                    if (deviceList?.includes(dev) || saveList?.includes(dev)){
                                        clsname = "list-group-item list-group-item-action list-fancy active ps-3 mt-1"
                                    }
                                    else{
                                        clsname = "list-group-item list-group-item-action list-fancy ps-3 mt-1"
                                    }
                                    return (
                                            <div key={"alldevice"+nd} className={clsname}
                                                 onClick={(e) => {
                                                     if (e.target.classList.contains("active")){
                                                         e.target.classList.remove("active");
                                                         setSaveList(() => removeItem(saveList, dev))
                                                     }
                                                     else{
                                                         setSaveList((prevVal => [...prevVal, dev]))
                                                         e.target.classList.add("active");
                                                     }
                                                 }}>
                                                {dev}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} onClick={() => closeModal("editClose")}>Cancel</button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                            <Button type={"button"} class={"btn btn-primary ms-3"} id={"editSubmit"} onClick={() => performSave()}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default LegacyAFRSystemSettings;