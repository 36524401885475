import {Link, useLocation, useNavigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    actionAppearance, callActionModal,
    checkAll, confirmModalHandler,
    convertIsoToLocal,
    formatPhone,
    formCheck,
    formFail,
    formSuccess,
    getAccessSettings,
    getCountryData,
    getStatesByCountry,
    getZipRegexByCountry,
    handleSubmitActions, iAmHigherAuthority, isBannerPresent,
    paginateData,
    reloadSessionCorp, setRoleInfo, showModal,
    sortFunc,
    toggleCollapse
} from "../../libs";
import MaterialIcon from "material-icons-react";
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";
import {Pagination} from "react-bootstrap";
import PaginationItems from "../component-assets/PaginationItems";
import Papa from 'papaparse'
import ConfirmModal from "../component-assets/ConfirmModal";
import {CSVLink} from "react-csv";
import {Modal} from "bootstrap";
import BulkDeleteModal from "../component-assets/BulkDeleteModal";

const CampusProfile = (props) => {
    let dData, bData, countryOptions = [], userHeaderData, groupHeaderData, afrHeaderData, hpHeaderData, noteHeaderData,
        reasonHeaderData, noteCSVHeaderData;

    const useLocationData = useLocation();
    const navigate = useNavigate();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    userHeaderData = [
        {label: "Username", key: "UserName", align: "left"},
        {label: "Display Name", key: "DisplayName", align: "left"},
        {label: "Email", key: "Email", align: "left"},
        {label: "Role", key: "UserRole", align: "left"}
    ];

    groupHeaderData = [
        {label: "Group Name", key: "GroupName", align: "left"},
        {label: "CapCode", key: "CapCode", align: "left"}
    ];

    afrHeaderData = [
        {label: "Device Name", key: "device_type_name", align: "left"},
    ];

    hpHeaderData = [
        {label: "Event Type", key: "event_type_name", align: "left"},
    ];

    reasonHeaderData = [
        {label: "Reason", key: "reason_code_description", align: "center"}
    ];

    noteHeaderData = [
        {label: "Timestamp", key: "last_modified", align: "center", type: "datetime-utc"},
        {label: "Created By", key: "display_name", align: "center"},
        {label: "Note", key: "note", align: "left"},
    ];

    noteCSVHeaderData = [
        {label: "Note ID", key: "note_id"},
        {label: "Timestamp", key: "last_modified"},
        {label: "Created By", key: "display_name"},
        {label: "Note", key: "note"},
        {label: "Created On", key: "create_time"}
    ];

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(80, dData.my_role_weight));
    const [editRights, setEditRights] = useState(iAmHigherAuthority(80, dData.my_role_weight));
    // Main Page Variables
    const [campusId, setCampusId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const [stateData, setStateData] = useState(useLocationData.state);
    const [corpData, setCorpData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [timezoneData, setTimezoneData] = useState([]);
    const [tabItem, setTabItem] = useState("init");
    const [corporateOptions, setCorporateOptions] = useState([]);
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [campusName, setCampusName] = useState("");
    const [corporateId, setCorporateId] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [zipcode, setZip] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("US");
    const [streetAddr, setStreetAddr] = useState("");
    const [timezone, setTimezone] = useState("");
    const [timezoneId, setTimezoneId] = useState(0);
    const [servicePlan, setServicePlan] = useState("");
    const [campusGuid, setCampusGuid] = useState("");
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [roleData, setRoleData] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    // Campus Settings
    const [isCloud, setIsCloud] = useState(false);
    const [settingLoaded, setSettingsLoaded] = useState(false);
    // Campus Notes
    const [noteData, setNoteData] = useState([]);
    const [noteTableRows, setNoteTableRows] = useState([]);
    const [noteLoaded, setNoteLoaded] = useState(false);
    const [noteIdList, setNoteIdList] = useState([]);
    const [notesStoredIndex, setNotesStoredIndex] = useState(0);
    const [notesPage, setNotesPage] = useState(1);
    const [notesPerPage, setNotesPerPage] = useState(100);
    const [noteDContent, setNoteDContent] = useState("");
    const [notesPPRules, setNotesPPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [noteSorter, setNoteSorter] = useState({
        sortColumn: "create_time",
        sortOrder: "desc"
    });
    const [noteCSVLink, setNoteCSVLink] = useState({
        filename: `campus_notes.csv`,
        headers: [],
        data: []
    });
    const [noteCSVButton, setNoteCSVButton] = useState(
        <CSVLink {...noteCSVLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                    class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);
    // Upload Variables
    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [zipReg, setZipReg] = useState(getZipRegexByCountry("US"));
    const [newFile, setNewFile] = useState("");
    const [newFileExt, setNewFileExt] = useState("");
    const [newFileName, setNewFileName] = useState("");
    const [newFileData, setNewFileData] = useState("");
    const [fileValid, setFileValid] = useState(true);
    const [uploadKey, setUploadKey] = useState("");
    const [uploadTimer, setUploadTimer] = useState(-1);
    const [stgPresent, setStgPresent] = useState(false);
    const [addDevices, setAddDevices] = useState(true);
    const [addAreas, setAddAreas] = useState(true);
    const [addResidents, setAddResidents] = useState(true);
    // User 2.0 Table To Migrate Card Variables
    const [userData, setUserData] = useState([]);
    const [userLoaded, setUserLoaded] = useState(false);
    const [userActiveData, setUserActiveData] = useState([]);
    const [myUserLoaded, setMyUserLoaded] = useState(false);
    const [myUserData, setMyUserData] = useState([]);
    const [userRows, setUserRows] = useState([]);
    const [userIdList, setUserIdList] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "UserName",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // Group 2.0 Table to Migrate Card Variables
    const [groupData, setGroupData] = useState([]);
    const [groupLoaded, setGroupLoaded] = useState(false);
    const [groupActiveData, setGroupActiveData] = useState([]);
    const [myGroupData, setMyGroupData] = useState([]);
    const [myGroupLoaded, setMyGroupLoaded] = useState(false);
    const [groupRows, setGroupRows] = useState([]);
    const [groupIdList, setGroupIdList] = useState([]);
    const [groupSearch, setGroupSearch] = useState("");
    const [groupPage, setGroupPage] = useState(1);
    const [dContent2, setDContent2] = useState("");
    const [groupSorter, setGroupSorter] = useState({
        sortColumn: "config_name",
        sortOrder: "asc"
    });
    const [groupPPRules, setGroupPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // AFR Settings
    const [afrData, setAFRData] = useState([]);
    const [devTypeData, setDevTypeData] = useState([]);
    const [devTypeLoaded, setDevTypeLoaded] = useState([]);
    const [myAFRData, setMyAFRData] = useState([]);
    const [afrLoaded, setAFRLoaded] = useState(false);
    const [myAFRLoaded, setMyAFRLoaded] = useState(false);
    const [afrDataSet, setAFRDataSet] = useState([]);
    const [afrRows, setAFRRows] = useState([]);
    const [afrIdList, setAFRIdList] = useState([]);
    const [afrOriginIdList, setAFROriginIdList] = useState([]);
    const [dContent3, setDContent3] = useState("");
    const [afrSorter, setAFRSorter] = useState({
        sortColumn: "device_type_name",
        sortOrder: "asc"
    });
    // High Priority Settings
    const [hpData, setHPData] = useState([]);
    const [myHPData, setMyHPData] = useState([]);
    const [hpLoaded, setHPLoaded] = useState(false);
    const [myHPLoaded, setMyHPLoaded] = useState(false);
    const [eventTypeData, setEventTypeData] = useState([]);
    const [eventTypeLoaded, setEventTypeLoaded] = useState(false);
    const [hpDataSet, setHPDataSet] = useState([]);
    const [hpRows, setHPRows] = useState([]);
    const [hpIdList, setHPIdList] = useState([]);
    const [hpOriginIdList, setHPOriginIdList] = useState([]);
    const [dContent4, setDContent4] = useState("");
    const [hpSorter, setHPSorter] = useState({
        sortColumn: "event_type_name",
        sortOrder: "asc"
    });
    // Alert Settings
    const [alertData, setAlertData] = useState([]);
    const [alertLoaded, setAlertLoaded] = useState(false);
    // Reason Code Settings
    const [reasonData, setReasonData] = useState([]);
    const [reasonLoaded, setReasonLoaded] = useState(false);
    const [myReasonData, setMyReasonData] = useState([]);
    const [myReasonLoaded, setMyReasonLoaded] = useState(false);
    const [reasonIdList, setReasonIdList] = useState([]);
    const [reasonOriginIdList, setReasonOriginIdList] = useState(false);
    const [reasonDataSet, setReasonDataSet] = useState([]);
    const [reasonRows, setReasonRows] = useState([]);
    const [dContent5, setDContent5] = useState("");
    const [reasonSorter, setReasonSorter] = useState({
        sortColumn: "reason_code_description",
        sortOrder: "asc"
    });

    const countries = getCountryData();
    for (let i2=0; i2 < countries?.length; i2++){
        countryOptions.push(
            <option key={"country-option-"+i2} value={countries[i2][0]}>{countries[i2][1]}</option>
        );
    }

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                payload: {campus_filter: campusId.toString()},
                                procedure: "getcampuses", reqType: "stored"}).then(data => {
                setBasicData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_corporate",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setCorpData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_timezones",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setTimezoneData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "getserverstatus", reqType: "stored"}).then(data => {
                setDeviceData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_user_roles",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setRoleData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_event_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEventTypeData(data);
                setEventTypeLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_inovonics_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setDevTypeData(data);
                setDevTypeLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                payload: {campus_id: campusId},
                                procedure: "getcampusnotes", reqType: "stored"}).then(data => {
                setNoteData(data ? data : []);
                setNoteLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_afr_entity",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setMyAFRData(data ? data : []);
                setMyAFRLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_user",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setMyUserData(data ? data : []);
                setMyUserLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_reason_codes",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setMyReasonData(data ? data : []);
                setMyReasonLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_high_priority_entity",
                                procedure: "gethighprioritytypes", reqType: "stored"}).then(data => {
                setMyHPData(data ? data : []);
                setMyHPLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getgroupsbycampusid", reqType: "stored"}).then(data => {
                setMyGroupData(data ? data : []);
                setMyGroupLoaded(true);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setUploadTimer(count => count - 1);
        }, 1000);
        if (uploadTimer > 0 && uploadTimer%5 === 0 && uploadKey !== ""){
            ApiRequester({reqEndpoint: "myfetch", pKey: "unique_key", pVal: uploadKey, Tbl: "stg_upload_keys",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                if (data && data.length > 0){
                    if (data[0]?.upload_complete === 1){
                        setUploadTimer(-1);
                        handleSubmitActions("loadUploadSubmit", "uploadSubmit");
                        document.getElementById("uploadCancel").click();
                        navigate(`/profiles/campus/${campusId}/${uploadKey}`,
                            {state: {message: {success: "Upload data staged for preview.", error: ""}}});
                    }else if (data[0]?.upload_error === 1){
                        setUploadTimer(-1);
                        handleSubmitActions("loadUploadSubmit", "uploadSubmit");
                        toast.error("Error encountered, run the file pre-check, if ok please notify software support.");
                    }
                }else{
                    console.log(data);
                }
            });
        }
        if (uploadTimer === 0 && stgPresent === false){
            handleSubmitActions("loadUploadSubmit", "uploadSubmit");
            toast.error("Timed out performing upload, please try again.");
        }
        return () => clearInterval(interval);
    }, [uploadTimer]);

    useEffect(() => {
        if (stateData && stateData?.message){
            if (stateData.message?.error){
                toast.error(stateData.message.error);
            }else if (stateData.message?.success){
                toast.success(stateData.message.success);
            }
        }
    }, [stateData]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount2(count => count - 1);
        }, 1000);
        if (count2 <= 0 && campusGuid){
            ApiRequester({reqEndpoint: "getitems", Table: "User", PKey: "CampusId", PVal: campusGuid,
                        SecondIndex: "CampusId-index", CampusId: campusId,
                        Projection: "UserId,DisplayName,Email,UserName,UserRole,GroupIds"}).then(data => {
                setUserData(data);
                setUserLoaded(true);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "Group", PKey: "CampusId", PVal: campusGuid,
                        SecondIndex: "CampusId-index", CampusId: campusId,
                        Projection: "GroupId,CapCode,GroupName"}).then(data => {
                setGroupData(data);
                setGroupLoaded(true);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "CampusConfig", PKey: "CampusId", PVal: campusGuid,
                        CampusId: campusId, Projection: "AFRDevices"}).then(data => {
                setAFRData(data);
                setAFRLoaded(true);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "CampusConfig", PKey: "CampusId", PVal: campusGuid,
                        CampusId: campusId, Projection: "HighPriorityAlertTypes"}).then(data => {
                setHPData(data);
                setHPLoaded(true);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "CampusConfig", PKey: "CampusId", PVal: campusGuid,
                        CampusId: campusId, Projection: "FinishButtonEnabled,FinishTimeInterval,ReleaseTimeInterval," +
                    "AcceptButtonEnabled,ReasonCodeEnabled,CaregiverChgGroupEnabled,UserEditDisplayName,ClearTimeInterval"}).then(data => {
                setAlertData(data);
                setAlertLoaded(true);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "CampusReasonCode", PKey: "CampusId", PVal: campusGuid,
                        CampusId: campusId, Projection: "ReasonCodeId,Description,GlobalReasonCode"}).then(data => {
                setReasonData(data);
                setReasonLoaded(true);
            });
            setCount2(() => 600);
        }
        return () => clearInterval(interval);
    }, [count2, campusGuid]);

    useEffect(() => {
        if (![basicLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded]);

    useEffect(() => {
        if (pageLoaded && tabItem === "init"){
            setTabItem("basic");
        }
    }, [pageLoaded]);

    useEffect(() => {
        if (timezoneData.length > 0){
            let tmpOptions = [];
            for (let i=0; i<timezoneData.length; i++){
                tmpOptions.push(
                    <option key={"timezone-option-"+timezoneData[i].timezone_id.toString()}
                            value={timezoneData[i].timezone_id}>{timezoneData[i].timezone_name}
                    </option>
                );
            }
            setTimezoneOptions(() => tmpOptions);
        }
    }, [timezoneData]);

    useEffect(() => {
        setNoteCSVLink(prevState => {
            return {...prevState,
                headers: noteCSVHeaderData,
                data: noteData}
        });
    }, [noteData]);

    useEffect(() => {
        setNoteCSVButton(
            <CSVLink {...noteCSVLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                        class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [noteCSVLink]);

    useEffect(() => {
        let tmp = [];
        if (roleData){
            roleData.sort((a, b) => sortFunc(a, b, "user_role_weight", "asc"));
            for (let i=0; i < roleData.length; i++){
                if (iAmHigherAuthority(roleData[i]?.user_role_weight, dData.my_role_weight)) {
                    tmp.push(
                        <option key={`role-option-${i}`} value={roleData[i]?.user_role_weight}>
                            {roleData[i]?.user_role_name}
                        </option>
                    );
                }
            }
            setRoleOptions(tmp);
        }
    }, [roleData]);

    useEffect(() => {
        let tmpData = [], tmpList = [];
        if (userLoaded && myUserLoaded){
            for (let i=0; i < myUserData.length; i++){
                tmpList.push(myUserData[i]?.cognito_id);
            }
            for (let i2=0; i2 < userData.length; i2++){
                if (userData[i2]?.UserId && !tmpList.includes(userData[i2]?.UserId)){
                    tmpData.push(userData[i2]);
                }
            }
            setUserActiveData(tmpData)
        }
    }, [userData, myUserData, userLoaded, myUserLoaded]);

    useEffect(() => {
        let tmpData = [], tmpList = [];
        if (groupLoaded && myGroupLoaded){
            for (let i=0; i < myGroupData.length; i++){
                tmpList.push(myGroupData[i]?.group_guid);
            }

            for (let i2=0; i2 < groupData.length; i2++){
                if (groupData[i2]?.GroupId && !tmpList.includes(groupData[i2]?.GroupId)){
                    tmpData.push(groupData[i2]);
                }
            }
            setGroupActiveData(tmpData)
        }
    }, [groupData, myGroupData, groupLoaded, myGroupLoaded]);

    useEffect(() => {
        let myIds = [], dataSet = [], afrDevices = [];
        if (afrLoaded && devTypeLoaded && myAFRLoaded && afrData.length > 0){
            for (let i=0; i < myAFRData.length; i++){
                myIds.push(myAFRData[i]?.device_type_id);
            }
            afrDevices = afrData[0].AFRDevices;
            for (let i2=0; i2 < afrDevices.length; i2++){
                for (let i3=0; i3 < devTypeData.length; i3++){
                    if (afrDevices[i2].toLowerCase() === devTypeData[i3].device_type_name.toLowerCase() && !myIds.includes(devTypeData[i3].inovonics_device_id)){
                        dataSet.push({
                            device_type_id: devTypeData[i3].inovonics_device_id,
                            device_type_name: devTypeData[i3].device_type_name
                        });
                        break;
                    }
                }
            }
            setAFRDataSet(dataSet);
            setAFROriginIdList(myIds);
        }
    }, [afrData, afrLoaded, myAFRData, myAFRLoaded, devTypeData, devTypeLoaded]);

    useEffect(() => {
        let myIds = [], dataSet = [], hpTypes = [];
        if (hpLoaded && myHPLoaded && eventTypeLoaded && hpData.length > 0){
            for (let i=0; i < myHPData.length; i++){
                myIds.push(myHPData[i].event_type_id);
            }
            hpTypes = hpData[0].HighPriorityAlertTypes;
            for (let i2=0; i2 < hpTypes.length; i2++){
                for (let i3=0; i3 < eventTypeData.length; i3++){
                    if (((hpTypes[i2].toLowerCase() === "alert" && eventTypeData[i3].event_type_name.toLowerCase() === "emergency")
                        || (hpTypes[i2].toLowerCase() === eventTypeData[i3].event_type_name.toLowerCase()))
                        && !myIds.includes(eventTypeData[i3].event_type_id)){
                        dataSet.push({
                            event_type_name: eventTypeData[i3].event_type_name,
                            event_type_id: eventTypeData[i3].event_type_id
                        });
                        break;
                    }
                }
            }
            setHPDataSet(dataSet);
            setHPOriginIdList(myIds);
        }
    }, [hpData, myHPData, eventTypeData, hpLoaded, myHPLoaded, eventTypeLoaded]);

    useEffect(() => {
        let myIds = [], dataSet = [];
        if (reasonLoaded && myReasonLoaded && reasonData.length > 0){
            for (let i=0; i < myReasonData.length; i++){
                myIds.push(myReasonData[i].reason_code_id);
            }
            for (let i2=0; i2 < reasonData.length; i2++){
                if (!myIds.includes(parseInt(reasonData[i2].ReasonCodeId))){
                    dataSet.push({
                        reason_code_description: reasonData[i2].Description,
                        reason_code_id: parseInt(reasonData[i2].ReasonCodeId),
                        pal_reason_code_id: parseInt(reasonData[i2].GlobalReasonCode)
                    });
                }
            }
            setReasonDataSet(dataSet);
            setReasonOriginIdList(myIds);
        }
    }, [reasonData, myReasonData, reasonLoaded, myReasonLoaded]);

    useEffect(() => {
        let pRules = paginateData(userActiveData, page, perPage);
        setPRules(pRules);
        if (userActiveData.length > 0) {
            userActiveData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setUserRows(<TableBody start={pRules.start} end={pRules.end} tableData={userActiveData} checkbox={addRights} unk={"b-"}
                                     writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckUser"}
                                     actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["migrateBtn"], "uCheckUser")}
                                     tableHeaders={userHeaderData} editModal={() => {}} parentComponent={"Campus"} dData={dData}
                                     modal={false} checkboxlist={userIdList} setCheckboxId={(value) => {setUserIdList(value)}}
                                     checkidkey={"UserId"} />);
    }, [userActiveData, sorter, page, perPage, userIdList]);

    useEffect(() => {
        let pRules = paginateData(groupActiveData, groupPage, perPage);
        setGroupPRules(pRules);
        if (groupActiveData.length > 0) {
            groupActiveData.sort((a, b) => sortFunc(a, b, groupSorter.sortColumn, groupSorter.sortOrder));
        }
        setGroupRows(<TableBody start={pRules.start} end={pRules.end} tableData={groupActiveData} checkbox={addRights} unk={"c-"}
                                writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckGroup"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["migrateGroupBtn"], "uCheckGroup")}
                                tableHeaders={groupHeaderData} editModal={() => {}} parentComponent={"Campus"} dData={dData}
                                modal={false} checkboxlist={groupIdList} setCheckboxId={(value) => {setGroupIdList(value)}}
                                checkidkey={"GroupId"} />);
    }, [groupActiveData, groupSorter, groupPage, perPage, groupIdList]);

    useEffect(() => {
        if (afrDataSet.length > 0) {
            afrDataSet.sort((a, b) => sortFunc(a, b, afrSorter.sortColumn, afrSorter.sortOrder));
        }
        setAFRRows(<TableBody start={0} end={afrDataSet.length} tableData={afrDataSet} checkbox={addRights} unk={"d-"}
                                writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckAFR"}
                                actionAppearance={() => actionAppearance(0, afrDataSet.length, ["migrateAFRBtn"], "uCheckAFR")}
                                tableHeaders={afrHeaderData} editModal={() => {}} parentComponent={"Campus"} dData={dData}
                                modal={false} checkboxlist={afrIdList} setCheckboxId={(value) => {setAFRIdList(value)}}
                                checkidkey={"device_type_id"} />);
    }, [afrDataSet, afrSorter, afrIdList]);

    useEffect(() => {
        if (hpDataSet.length > 0) {
            hpDataSet.sort((a, b) => sortFunc(a, b, hpSorter.sortColumn, hpSorter.sortOrder));
        }
        setHPRows(<TableBody start={0} end={hpDataSet.length} tableData={hpDataSet} checkbox={addRights} unk={"e-"}
                              writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckHP"}
                              actionAppearance={() => actionAppearance(0, hpDataSet.length, ["migrateHPBtn"], "uCheckHP")}
                              tableHeaders={hpHeaderData} editModal={() => {}} parentComponent={"Campus"} dData={dData}
                              modal={false} checkboxlist={hpIdList} setCheckboxId={(value) => {setHPIdList(value)}}
                              checkidkey={"event_type_id"} />);
    }, [hpDataSet, hpSorter, hpIdList]);

    useEffect(() => {
        if (reasonDataSet.length > 0) {
            reasonDataSet.sort((a, b) => sortFunc(a, b, reasonSorter.sortColumn, reasonSorter.sortOrder));
        }
        setReasonRows(<TableBody start={0} end={reasonDataSet.length} tableData={reasonDataSet} checkbox={addRights} unk={"f-"}
                                 writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckReason"}
                                 actionAppearance={() => actionAppearance(0, reasonDataSet.length, ["migrateReasonBtn"], "uCheckReason")}
                                 tableHeaders={reasonHeaderData} editModal={() => {}} parentComponent={"Campus"} dData={dData}
                                 modal={false} checkboxlist={reasonIdList} setCheckboxId={(value) => {setReasonIdList(value)}}
                                 checkidkey={"reason_code_id"} />);
    }, [reasonDataSet, reasonSorter, reasonIdList]);

    useEffect(() => {
        let pRules = paginateData(noteData, notesPage, notesPerPage);
        setNotesPPRules(pRules);
        if (noteData.length > 0) {
            noteData.sort((a, b) => sortFunc(a, b, noteSorter.sortColumn, noteSorter.sortOrder));
        }
        setNoteTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={noteData} checkbox={addRights} unk={"n-"}
                                    writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckNote"}
                                    actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["noteDeleteBtn"], "uCheckNote")}
                                    tableHeaders={noteHeaderData} editModal={editNoteModal} parentComponent={"Campus"} dData={dData}
                                    modal={editRights} checkboxlist={noteIdList} setCheckboxId={(value) => {setNoteIdList(value)}}
                                    tzOffset={parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0)}
                                    checkidkey={"note_id"} />);
    }, [noteData, noteSorter, noteIdList, notesPage, notesPerPage]);

    useEffect(() => {
        if (basicData?.length > 0){
            setIsCloud(() => basicData[0]?.is_cloud === 1);
            setCampusName(() => basicData[0]?.campus_name ? basicData[0]?.campus_name : "");
            setCorporateName(() => basicData[0]?.corporate_name ? basicData[0]?.corporate_name : "");
            setCorporateId(() => basicData[0]?.corporate_id ? basicData[0]?.corporate_id : "");
            setEmail(() => basicData[0]?.campus_email ? basicData[0]?.campus_email : "");
            setPhone(() => basicData[0]?.campus_phone ? basicData[0]?.campus_phone : "");
            setLatitude(() => basicData[0]?.campus_latitude ? basicData[0]?.campus_latitude : "");
            setLongitude(() => basicData[0]?.campus_longitude ? basicData[0]?.campus_longitude : "");
            setZip(() => basicData[0]?.campus_zip ? basicData[0]?.campus_zip : "");
            setCity(() => basicData[0]?.campus_city ? basicData[0]?.campus_city : "");
            setState(() => basicData[0]?.campus_state ? basicData[0]?.campus_state : "");
            setCountry(() => basicData[0]?.campus_country ? basicData[0]?.campus_country : "");
            setStreetAddr(() => basicData[0]?.campus_street_address ? basicData[0]?.campus_street_address : "");
            setServicePlan(() => basicData[0]?.date_service_plan_expire ? basicData[0]?.date_service_plan_expire : "");
            setCampusGuid(() => basicData[0]?.campus_guid ? basicData[0]?.campus_guid : "");
            setTimezone(() => basicData[0]?.timezone ? basicData[0]?.timezone : "");
            setTimezoneId(() => basicData[0]?.timezone_id ? basicData[0]?.timezone_id : "");
            setBasicLoaded(true);
        }
    }, [basicData]);

    useEffect(() => {
        setCountryOptionsFunction(country);
    }, [country]);

    useEffect(() => {
        let i, tmp = [];
        for (i = 0; i < corpData?.length; i++){
            tmp.push(
                <option key={"location-option"+i.toString()} value={corpData[i].corporate_id}>{corpData[i].corporate_name}</option>
            );
        }
        setCorporateOptions(() => tmp);
    }, [corpData]);

    function setCountryOptionsFunction(inCountry){
        let tmp = [];
        let stateData = getStatesByCountry(inCountry);
        for (let i=0; i < stateData?.length; i++){
            tmp.push(
                <option key={"state-option"+i} value={stateData[i][0]}>{stateData[i][1]}</option>
            );
        }
        setStateOptions(tmp);
        setZipReg(getZipRegexByCountry(inCountry));
    }

   function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("name-edit").classList.toggle("d-none");
        document.getElementById("corporate-static").classList.toggle("d-none");
        document.getElementById("corporate-edit").classList.toggle("d-none");
        document.getElementById("country-static").classList.toggle("d-none");
        document.getElementById("country-edit").classList.toggle("d-none");
        document.getElementById("street-static").classList.toggle("d-none");
        document.getElementById("street-edit").classList.toggle("d-none");
        document.getElementById("state-static").classList.toggle("d-none");
        document.getElementById("state-edit").classList.toggle("d-none");
        document.getElementById("city-static").classList.toggle("d-none");
        document.getElementById("city-edit").classList.toggle("d-none");
        document.getElementById("zipcode-static").classList.toggle("d-none");
        document.getElementById("zipcode-edit").classList.toggle("d-none");
        document.getElementById("timezone-static").classList.toggle("d-none");
        document.getElementById("timezone-edit").classList.toggle("d-none");
        document.getElementById("email-static").classList.toggle("d-none");
        document.getElementById("email-edit").classList.toggle("d-none");
        document.getElementById("phone-static").classList.toggle("d-none");
        document.getElementById("phone-edit").classList.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("name-edit").value = campusName;
            document.getElementById("corporate-edit").value = corporateId;
            document.getElementById("country-edit").value = country;
            document.getElementById("street-edit").value = streetAddr;
            document.getElementById("state-edit").value = state;
            document.getElementById("city-edit").value = city;
            document.getElementById("zipcode-edit").value = zipcode;
            document.getElementById("timezone-edit").value = timezoneId;
            document.getElementById("email-edit").value = email;
            document.getElementById("phone-edit").value = phone.replaceAll("-", "");
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
   }

    function basicSubmit(){
        let name, countryX, stateX, cityX, zipX, emailX, phoneX, addressX, corpId, payload, tzId, oldPayload;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            name = document.getElementById("name-edit")?.value;
            addressX = document.getElementById("street-edit")?.value;
            countryX = document.getElementById("country-edit")?.value;
            stateX = document.getElementById("state-edit")?.value;
            cityX = document.getElementById("city-edit")?.value;
            zipX = document.getElementById("zipcode-edit")?.value;
            emailX = document.getElementById("email-edit")?.value;
            phoneX = document.getElementById("phone-edit")?.value;
            corpId = document.getElementById("corporate-edit")?.value;
            tzId = document.getElementById("timezone-edit").value;
            payload = [{campus_id: parseInt(campusId), corporate_id: parseInt(corpId), campus_country: countryX,
                        campus_state: stateX, campus_name: name, campus_city: cityX, campus_zip: zipX,
                        campus_email: emailX, campus_phone: phoneX, timezone_id: parseInt(tzId),
                        modified_by: dData.user, campus_street_address: addressX}];
            oldPayload = [{campus_id: parseInt(campusId), corporate_id: corporateId, campus_country: country,
                           campus_state: state, campus_name: campusName, campus_city: city, campus_zip: zipcode,
                           campus_email: email, campus_phone: phone, timezone_id: timezoneId,
                           campus_street_address: streetAddr}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "campus_edit", Payload: payload, OldPayload: oldPayload,
                                CampusId: dData.acnt, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn",
                        "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function getGroupIdListByGuidList(userRec){
       let idList = [];
       for (let i=0; i < myGroupData?.length; i++){
           if (userRec?.GroupIds && userRec?.GroupIds.includes(myGroupData[i]?.group_guid)){
               if (!idList.includes(myGroupData[i]?.group_id)){
                   idList.push(myGroupData[i]?.group_id);
               }
           }
       }
       return idList;
    }

    function migratePerformSubmit(){
        let payload = [], role, pAccess, groupIds;
        if (addRights){
            handleSubmitActions("confirmModalSubmit", "loadConfirmModalSubmit");
            for (let i=0; i < userActiveData.length; i++){
                if (userIdList.includes(userActiveData[i]?.UserId)){
                    switch (userActiveData[i]?.UserRole) {
                        case "admin": role = 5; break;
                        case "corporate": role = 4; break;
                        case "corporate-admin": role = 3; break;
                        case "palcare": role = 3; break;
                        case "palcare-admin": role = 1; break;
                        default: role = 6; break;
                    }
                    pAccess = getAccessSettings(parseInt(role));
                    groupIds = getGroupIdListByGuidList(userActiveData[i]);
                    payload.push({Action: "migrate", DisplayName: userActiveData[i]?.DisplayName,
                        UserEmail: userActiveData[i]?.UserEmail ? userActiveData[i]?.UserEmail : "", UserRole: parseInt(role), UserId: userActiveData[i]?.UserId,
                        Migrated: true, CampusGuid: basicData[0]?.campus_guid, PortalAccess: pAccess,
                        GroupIds: groupIds, Username: userActiveData[i]?.UserName, CampusId: campusId, ClientId: dData.sessionId})
                }
            }
            ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0]){
                    formSuccess("All users migrated.", "confirmModalSubmit", "loadConfirmModalSubmit", "confirmModalConfirmClose");
                    setCount(0);
                    setCount2(0);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(0, ppRules.end);
                    actionAppearance(0, ppRules.end, ["migrateBtn"]);
                }
                else{
                    formFail(`Error during migration, check user table.`, "confirmModalSubmit", "loadConfirmModalSubmit");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateGroupPerformSubmit(){
        let payload = [];
        if (addRights){
            handleSubmitActions("confirmModalSubmit", "loadConfirmModalSubmit");
            for (let i=0; i < groupActiveData.length; i++){
                if (groupIdList.includes(groupActiveData[i]?.GroupId)){
                    payload.push({campus_id: campusId, group_name: groupActiveData[i]?.GroupName,
                        capcode: groupActiveData[i]?.CapCode, group_guid: groupActiveData[i]?.GroupId})
                }
            }
            ApiRequester({reqEndpoint: "mytransaction", TransType: "group_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("Groups migrated successfully.", "confirmModalSubmit", "loadConfirmModalSubmit", "confirmModalConfirmClose");
                    setCount(0);
                    setCount2(0);
                    document.getElementById("uCheckHead2").checked = false;
                    checkAll(0, ppRules.end);
                    actionAppearance(0, ppRules.end, ["migrateGroupBtn"]);
                }
                else{
                    formFail(`Error during migration, check group table.`, "confirmModalSubmit", "loadConfirmModalSubmit");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateAFRPerformSubmit(){
        let payload = [], oldPayload;
        if (addRights){
            handleSubmitActions("confirmModalSubmit", "loadConfirmModalSubmit");
            payload = [{campus_id: campusId, afr_entities: afrIdList.concat(afrOriginIdList)}];
            oldPayload = [{campusId: campusId, afr_entities: afrOriginIdList}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "afrSave", Payload: payload,
                                OldPayload: oldPayload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("AFR devices migrated successfully.", "confirmModalSubmit", "loadConfirmModalSubmit", "confirmModalConfirmClose");
                    setCount(0);
                    setCount2(0);
                    document.getElementById("uCheckHead3").checked = false;
                    checkAll(0, afrDataSet.length);
                    actionAppearance(0, afrDataSet.length, ["migrateAFRBtn"]);
                }
                else{
                    formFail(`Error during migration, check afr table.`, "confirmModalSubmit", "loadConfirmModalSubmit");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateHPPerformSubmit(){
        let payload = [], oldPayload;
        if (addRights){
            handleSubmitActions("confirmModalSubmit", "loadConfirmModalSubmit");
            // handleSubmitActions("migrateHPSubmit", "loadMigrateHPSubmit");
            payload = [{campus_id: campusId, priority_entities: hpIdList.concat(hpOriginIdList)}];
            oldPayload = [{campusId: campusId, priority_entities: hpOriginIdList}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "prioritySave", Payload: payload,
                                OldPayload: oldPayload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("High priority alert types migrated successfully.", "confirmModalSubmit", "loadConfirmModalSubmit", "confirmModalConfirmClose");
                    setCount(0);
                    setCount2(0);
                    document.getElementById("uCheckHead4").checked = false;
                    checkAll(0, hpDataSet.length);
                    actionAppearance(0, hpDataSet.length, ["migrateHPBtn"]);
                }
                else{
                    formFail(`Error during migration, check high priority table.`, "confirmModalSubmit", "loadConfirmModalSubmit");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateReasonPerformSubmit(){
        let payload = [];
        if (addRights){
            handleSubmitActions("confirmModalSubmit", "loadConfirmModalSubmit");
            for (let i=0; i < reasonDataSet.length; i++){
                if (reasonIdList.includes(reasonDataSet[i].reason_code_id.toString())){
                    payload.push({
                        reason_code_description: reasonDataSet[i].reason_code_description,
                        pal_reason_code_id: reasonDataSet[i].pal_reason_code_id,
                        reason_code_id: reasonDataSet[i].reason_code_id,
                        campus_id: campusId
                    });
                }
            }
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_reason_codes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "cloud_reason_code_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("Reason codes migrated successfully.", "confirmModalSubmit", "loadConfirmModalSubmit", "confirmModalConfirmClose");
                    setCount(0);
                    setCount2(0);
                    document.getElementById("uCheckHead5").checked = false;
                    actionAppearance(0, reasonDataSet.length, ["migrateReasonBtn"]);
                    checkAll(0, reasonDataSet.length);
                }
                else{
                    formFail(`Error during migration, check reason code table.`, "confirmModalSubmit", "loadConfirmModalSubmit");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateAlertPerformSubmit(){
        let payload = [];
        if (addRights){
            handleSubmitActions("confirmModalSubmit", "loadConfirmModalSubmit");
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_reason_codes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "cloud_reason_code_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("Alert settings migrated successfully.", "confirmModalSubmit", "loadConfirmModalSubmit", "confirmModalConfirmClose");
                    setCount(0);
                    setCount2(0);
                }
                else{
                    formFail(`Error during migration, check alert settings.`, "confirmModalSubmit", "loadConfirmModalSubmit");
                    setCount(0);
                    setCount2(0);
                }
            });
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_reason_codes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "cloud_reason_code_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("Custom settings migrated successfully.", "confirmModalSubmit", "loadConfirmModalSubmit", "confirmModalConfirmClose");
                    setCount(0);
                    setCount2(0);
                }
                else{
                    formFail(`Error during migration, check custom settings.`, "confirmModalSubmit", "loadConfirmModalSubmit");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function handleFileUpload(){
        let file, filereader, filedatareader;
        if (editRights){
            file = document.getElementById('file-upload').files[0];
            if (file){
                setNewFileName(() => file?.name ? file.name : "");
                setNewFileExt(() => file?.name ? file.name.split(".")[1] : "");
                setFileValid(false);
                filedatareader = new FileReader();
                filedatareader.onload = function(event) {
                    let csvData = event?.target?.result;
                    let parsedData = csvData ? Papa.parse(csvData, {header: true}).data : [];
                    setNewFileData(() => parsedData);
                }
                filedatareader.readAsText(file);
                filereader = new FileReader();
                filereader.onload = function(event) {
                    let csvData = event.target.result;
                    setNewFile(() => Papa.parse(csvData).data[0][1]);
                }
                filereader.readAsDataURL(file);
            }
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function uploadSubmit(){
        if (editRights){
            handleSubmitActions("uploadSubmit", "loadUploadSubmit");
            let payload = {upload_file: newFile, upload_areas: addAreas, upload_residents: addResidents,
                            upload_devices: addDevices};
            ApiRequester({reqEndpoint: "uploadprocess", Payload: payload, User: dData.user,
                                CampusId: campusId, Process: "init"}).then(data => {
                if (data && data !== []){
                    toast.info("File upload in process, awaiting results");
                    setUploadKey(() => data);
                    setUploadTimer(() => 120);
                }
                else{
                    formFail(`Error during file upload: ${data[1]}`, "uploadSubmit", "loadUploadSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function uploadPrecheck(){
        let error = false;
        if (newFileData.length <= 0){
            toast.error("File empty");
            error = true;
        }
        if (!error){
            for (let i=0; i < newFileData.length; i++){
                if (newFileData[i]?.room !== undefined && newFileData[i].room !== ""){
                    if (newFileData[i]?.areatype === undefined || newFileData[i]?.areatype === ""){
                        toast.error(`Missing areatype for row ${i+2}`);
                        error = true;
                        break;
                    }else if (!["other", "access", "mc", "al", "il", "snf", "common"].includes(newFileData[i]?.areatype?.toLowerCase())){
                        toast.error(`Invalid areatype for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.room)){
                        toast.error(`Erroneous character in room for row ${i+2}`);
                        error = true;
                        break;
                    }
                }else{
                    if ([newFileData[i]?.areatype === undefined || newFileData[i]?.areatype === "",
                         newFileData[i]?.lastname === undefined || newFileData[i]?.lastname === "",
                         newFileData[i]?.firstname === undefined || newFileData[i]?.firstname === "",
                         newFileData[i]?.serial === undefined || newFileData[i]?.serial === "",
                         newFileData[i]?.deviceuse === undefined || newFileData[i]?.deviceuse === ""].includes(false)){
                        toast.error(`Missing room for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.firstname !== undefined && newFileData[i]?.firstname !== ""){
                    if (newFileData[i]?.lastname === undefined || newFileData[i]?.lastname === ""){
                        toast.error(`Missing lastname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.firstname)){
                        toast.error(`Erroneous character in firstname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.lastname)){
                        toast.error(`Erroneous character in lastname for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.lastname !== undefined && newFileData[i]?.lastname !== ""){
                    if (newFileData[i]?.firstname === undefined || newFileData[i]?.firstname === ""){
                        toast.error(`Missing firstname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.lastname)){
                        toast.error(`Erroneous character in lastname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.firstname)){
                        toast.error(`Erroneous character in firstname for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.deviceuse !== undefined && newFileData[i]?.deviceuse !== ""){
                    if (newFileData[i]?.serial === undefined || newFileData[i]?.serial === ""){
                        toast.error(`Missing serial for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.serial !== undefined && newFileData[i]?.serial !== ""){
                    if (newFileData[i]?.deviceuse === undefined || newFileData[i]?.deviceuse === ""){
                        toast.error(`Missing deviceuse for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
            }
        }
        if (!error){
            toast.info("File good, ready for upload.");
            setFileValid(true);
        }
    }

    function addNoteSubmit(){
        let payload, note;
        handleSubmitActions("addNoteSubmit", "loadAddNoteSubmit");
        note = document.getElementById("note").value;
        payload = [{campus_id: dData.acnt, note: note, modified_by: dData?.user}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_campus_notes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "note_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                setNoteIdList([]);
                formSuccess(`Note added successfully.`, "addNoteSubmit", "loadAddNoteSubmit", "cancelAddNoteBtn", "addNoteForm");
                setCount(0);
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addNoteSubmit", "loadAddNoteSubmit");
            }
        });
    }

    function editNoteModal(editIndex){
        let dataIndex, modal;
        if (editRights){
            dataIndex = parseInt(editIndex);
            setNotesStoredIndex(dataIndex);
            document.getElementById("noteE").value = noteData[dataIndex]?.note;
            document.getElementById("noteE").style.height = "125px";
            formCheck("editNoteForm", "editNoteSubmit");
            modal = new Modal(document.getElementById("editnotemodal"), {});
            modal.show();
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function editNoteSubmit(){
        let payload, note, noteId;
        handleSubmitActions("editNoteSubmit", "loadEditNoteSubmit");
        noteId = noteData[notesStoredIndex].note_id
        note = document.getElementById("noteE").value;
        payload = [{note_id: noteId, campus_id: dData.acnt, note: note, modified_by: dData?.user}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_campus_notes", Payload: payload, urlType: "POST",
                            User: dData.user, PKey: "note_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                setNoteIdList([]);
                formSuccess(`Note updated successfully.`, "editNoteSubmit", "loadEditNoteSubmit", "cancelEditNoteBtn", "editNoteForm");
                setCount(0);
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addNoteSubmit", "loadAddNoteSubmit");
            }
        });
    }

    function deleteNotesPerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        if (editRights){
            handleSubmitActions(submitBtn, loadBtn);
            payload = [];
            for (let i=0; i < noteDContent.length; i++){
                payload.push({note_id: noteDContent[i][0], modified_by: dData.user});
            }
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_campus_notes", Payload: payload, urlType: "DELETE",
                                PKey: "note_id", User: dData.user, Condition: "primary", CampusId: dData.acnt}).then(data => {
                if (data[0]){
                    setCount(0);
                    setNoteIdList([]);
                    formSuccess("Notes deleted.", submitBtn, loadBtn, closeId);
                    document.getElementById("uCheckHead6").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["noteDeleteBtn"]);
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const userHeaders = (<TableHeaders checkbox={addRights} headerData={userHeaderData}
                                       checkAll={() => checkAll(ppRules.start, ppRules.end, userIdList, setUserIdList, "uCheckHead", "uCheckUser")}
                                       writeAccess={addRights} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter}
                                       modal={false} checkboxlist={userIdList}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["migrateBtn"], "uCheckUser")} />);

    const groupHeaders = (<TableHeaders checkbox={addRights} headerData={groupHeaderData} checkboxHeaderId={"uCheckHead2"}
                                        checkAll={() => checkAll(groupPPRules.start, groupPPRules.end, groupIdList, setGroupIdList, "uCheckHead2", "uCheckGroup")}
                                        writeAccess={addRights} sortOrder={groupSorter.sortOrder}
                                        sortColumn={groupSorter.sortColumn} sorter={groupSorter} setSorter={setGroupSorter}
                                        modal={false} checkboxlist={groupIdList}
                                        actionAppearance={() => actionAppearance(groupPPRules.start, groupPPRules.end, ["migrateGroupBtn"], "uCheckGroup")} />);
    const afrHeaders = (<TableHeaders checkbox={addRights} headerData={afrHeaderData} checkboxHeaderId={"uCheckHead3"}
                                      checkAll={() => checkAll(0, afrDataSet.length, afrIdList, setAFRIdList, "uCheckHead3", "uCheckAFR")}
                                      writeAccess={addRights} sortOrder={afrSorter.sortOrder}
                                      sortColumn={afrSorter.sortColumn} sorter={afrSorter} setSorter={setAFRSorter}
                                      modal={false} checkboxlist={afrIdList}
                                      actionAppearance={() => actionAppearance(0, afrDataSet.length, ["migrateAFRBtn"], "uCheckAFR")} />);
    const hpHeaders = (<TableHeaders checkbox={addRights} headerData={hpHeaderData} checkboxHeaderId={"uCheckHead4"}
                                     checkAll={() => checkAll(0, hpDataSet.length, hpIdList, setHPIdList, "uCheckHead4", "uCheckHP")}
                                     writeAccess={addRights} sortOrder={hpSorter.sortOrder}
                                     sortColumn={hpSorter.sortColumn} sorter={hpSorter} setSorter={setHPSorter}
                                     modal={false} checkboxlist={hpIdList}
                                     actionAppearance={() => actionAppearance(0, hpDataSet.length, ["migrateHPBtn"], "uCheckHP")} />);
    const reasonHeaders = (<TableHeaders checkbox={addRights} headerData={reasonHeaderData} checkboxHeaderId={"uCheckHead5"}
                                     checkAll={() => checkAll(0, reasonDataSet.length, reasonIdList, setReasonIdList, "uCheckHead5", "uCheckReason")}
                                     writeAccess={addRights} sortOrder={reasonSorter.sortOrder}
                                     sortColumn={reasonSorter.sortColumn} sorter={reasonSorter} setSorter={setReasonSorter}
                                     modal={false} checkboxlist={reasonIdList}
                                     actionAppearance={() => actionAppearance(0, reasonDataSet.length, ["migrateReasonBtn"], "uCheckReason")} />);

    const noteTableHeaders = (<TableHeaders checkbox={addRights} headerData={noteHeaderData} checkboxHeaderId={"uCheckHead6"}
                                       checkAll={() => checkAll(notesPPRules.start, notesPPRules.end, noteIdList, setNoteIdList, "uCheckHead6", "uCheckNote")}
                                       writeAccess={addRights} sortOrder={noteSorter.sortOrder}
                                       sortColumn={noteSorter.sortColumn} sorter={noteSorter} setSorter={setNoteSorter}
                                       modal={editRights} checkboxlist={noteIdList}
                                       actionAppearance={() => actionAppearance(notesPPRules.start, notesPPRules.end, ["noteDeleteBtn"], "uCheckNote")} />);


    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div><Button text={"Upload Masterfile"} type={"button"} class={"btn btn-primary mx-3 mt-1"}
                    onClick={() => {
                        setNewFile("");
                        setNewFileName("");
                        setNewFileExt("");
                        setUploadKey("");
                        showModal("uploadmodal");
                    }} />
                <Link to={`/logs/campus/${campusId}`}>
                    <Button text={"View Audit Logs"} type={"button"} class={"btn btn-primary mt-1 mx-3"} />
                </Link>
            </div>
            <div>
                {dData.corp.toString() === corporateId.toString()
                    ?   dData.myCorp.toString() === corporateId.toString()
                        ? <></>
                        : <Button text={"Impersonating"} type={"button"} class={"btn btn-success ms-2 mt-1"} icon={true}
                                  iconClass={"fe fe-eye me-2"} disabled={true} />
                    :   <Button text={"Impersonate"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                icon={true} iconClass={"fe fe-eye me-2"}
                                onClick={() => {
                                    document.getElementById("userrole").value = dData.my_role_weight;
                                    showModal("impersonatemodal");
                                }} />
                }
            </div>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Campus Profile"} Title={campusName} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <div className={"col"}>
                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                            <li key={"set-basic"} className={"nav-item"}>
                                <Button text={"Basic Info"}
                                        class={tabItem === "basic" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("basic")}/>
                            </li>
                            <li key={"set-notes"} className={"nav-item"}>
                                <Button text={"Notes"}
                                        class={tabItem === "notes" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("notes")}/>
                            </li>
                            <li key={"set-server"} className={"nav-item"}>
                                <Button text={"Server Info"}
                                        class={tabItem === "server" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("server")}/>
                            </li>
                            <li key={"set-migrate"} className={"nav-item"}>
                                <Button text={"Migrating"}
                                        class={tabItem === "migrate" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("migrate")}/>
                            </li>
                        </ul>
                    </div>
                    <div className={tabItem === "init" ? "d-block" : "d-none"}>
                        <p>System settings loading. . .</p>
                    </div>
                    <div className={tabItem === "basic" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "550px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                       onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                                <form className={"d-inline align-top form-control-plaintext was-validated"}
                                      id={"basic-form"}>
                                    <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                        <div className={"d-flex"}>
                                            <p className={"mb-1"} style={{minWidth: "125px"}}>Id:</p>
                                            <p className={"ms-4 mb-1"} id={"id-static"}>{campusId}</p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Guid:</p>
                                            <p className={"ms-4"} id={"guid-static"}>{campusGuid}</p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Name:</p>
                                            <p className={"ms-4"} id={"name-static"}>{campusName}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   required={true}
                                                   style={{height: "30px", width: "225px"}} id={"name-edit"}
                                                   defaultValue={campusName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Corporation:</p>
                                            <Link to={`/profiles/corporate/${corporateId}`}>
                                                <p className={"ms-4"} id={"corporate-static"}>{corporateName}</p>
                                            </Link>
                                            <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                    onChange={() => {
                                                        formCheck("basic-form", "save-basic-btn");
                                                    }}
                                                    style={{height: "30px", width: "225px"}} id={"corporate-edit"}
                                                    defaultValue={corporateId}>
                                                {corporateOptions}
                                            </select>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p className={"mb-1"} style={{minWidth: "125px"}}>Street Address:</p>
                                            <p className={"ms-4 mb-1"} id={"street-static"}>{streetAddr}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm mb-1"}
                                                   style={{height: "30px", width: "225px"}} id={"street-edit"}
                                                   defaultValue={streetAddr} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p className={"mb-1"} style={{minWidth: "125px"}}>Country:</p>
                                            <p className={"ms-4 mb-1"} id={"country-static"}>{country}</p>
                                            <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                    onChange={(e) => {
                                                        formCheck("basic-form", "save-basic-btn");
                                                        setCountryOptionsFunction(e?.target?.value?.toString())
                                                    }}
                                                    style={{height: "30px", width: "225px"}} id={"country-edit"}
                                                    defaultValue={country}>
                                                {countryOptions}
                                            </select>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p className={"mb-1"} style={{minWidth: "125px"}}>State:</p>
                                            <p className={"ms-4 mb-1"} id={"state-static"}>{state}</p>
                                            <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                    onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                    style={{height: "30px", width: "225px"}} id={"state-edit"}
                                                    defaultValue={state}>
                                                {stateOptions}
                                            </select>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p className={"mb-1"} style={{minWidth: "125px"}}>City:</p>
                                            <p className={"ms-4 mb-1"} id={"city-static"}>{city}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm mb-1"}
                                                   style={{height: "30px", width: "225px"}} id={"city-edit"}
                                                   defaultValue={city} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Zip Code:</p>
                                            <p className={"ms-4"} id={"zipcode-static"}>{zipcode}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   style={{height: "30px", width: "225px"}} id={"zipcode-edit"}
                                                   defaultValue={zipcode} pattern={zipReg}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Timezone:</p>
                                            <p className={"ms-4"} id={"timezone-static"}>{timezone}</p>
                                            <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                    onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                    style={{height: "30px", width: "225px"}} id={"timezone-edit"}
                                                    defaultValue={timezoneId}>
                                                {timezoneOptions}
                                            </select>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Email:</p>
                                            <p className={"ms-4"} id={"email-static"}>{email}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   style={{height: "30px", width: "225px"}} id={"email-edit"}
                                                   defaultValue={email}
                                                   pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Phone #:</p>
                                            <p className={"ms-4"} id={"phone-static"}>{formatPhone(phone)}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   style={{height: "30px", width: "225px"}} id={"phone-edit"}
                                                   defaultValue={phone} pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Lat/Long:</p>
                                            <p className={"ms-4"}
                                               id={"latlong-static"}>{latitude ? latitude : "?"} / {longitude ? longitude : "?"}</p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Service Plan Date:</p>
                                            <p className={"ms-4"}
                                               id={"service-static"}>{convertIsoToLocal(servicePlan)}</p>
                                        </div>
                                    </div>
                                </form>
                                {pageLoaded && editRights &&
                                    <>
                                        <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                                disabled={!editRights} onClick={() => {
                                            if (editRights) {
                                                basicActionHandling()
                                            }
                                        }}/>
                                        <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"}
                                                id={"save-basic-btn"}
                                                disabled={!editRights} onClick={() => {
                                            if (editRights) {
                                                basicSubmit()
                                            }
                                        }}/>
                                        <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"}
                                                disabled={true}
                                                spinner={true} text={""} id={"load-basic-btn"}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "notes" ? "d-block" : "d-none"}>
                        <div className={"card"}>
                            <div className={"card-body collapse collapse-content show"} id={"notes-card"}>
                                <div className={"d-flex justify-content-between pb-3"}>
                                    <div>

                                    </div>
                                    <div>
                                        {editRights && <Button text={"Delete"} type={"button"}
                                                               class={"btn btn-danger me-2 hide"}
                                                               id={"noteDeleteBtn"} icon={true}
                                                               iconClass={"fe fe-trash-2 me-2"}
                                                               onClick={() => callActionModal(notesPPRules.start, notesPPRules.end, noteData,
                                                                   ["note_id", "display_name"], ["note_id", ""], "",
                                                                   setNoteDContent, "deletenotesmodal", "deleteBulkModalContent",
                                                                   "Are you sure you wish to delete the following records? ", "uCheckNote")}/>}
                                        {noteCSVButton}
                                        {addRights && <Button text={"Add Note"} type={"button"}
                                                               class={"btn btn-primary ms-2 mt-1"}
                                                               icon={true} iconClass={"fe fe-plus me-2"}
                                                               onClick={() => {
                                                                   document.getElementById("note").value = "";
                                                                   document.getElementById("note").style.height = "125px";
                                                                   formCheck("addNoteForm", "addNoteSubmit");
                                                                   showModal("addnotemodal");
                                                               }}/>}
                                    </div>
                                </div>
                                <table className={"table table-sm table-white table-hover"}>
                                    <thead className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                                    {noteTableHeaders}
                                    </thead>
                                    <tbody id={"tBody"}>
                                    {noteTableRows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "server" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"} style={{minWidth: "80px"}}>Server
                                        Status's</h3>
                                    <div className={"d-inline"} style={{display: "inline-block"}} id={"db-header-data"}>
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}></h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"devices-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("db-card-btn", "db-card");
                                           document.getElementById("db-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"db-card"}>
                                <table className={"table table-sm table-responsive-sm table-hover"}>
                                    <thead className={"table-header"}>
                                    <tr>
                                        <td className={"text-center"}>Server</td>
                                        <td className={"text-center"}>Process Count</td>
                                        <td className={"text-center"}>Aggregate Count</td>
                                        <td className={"text-center"}>Eman Count</td>
                                        <td className={"text-center"}>Maint. Count</td>
                                        <td className={"text-center"}>Other Count</td>
                                        <td className={"text-center"}>Alarm Time</td>
                                        <td className={"text-center"}>Alarm Parsed</td>
                                        <td className={"text-center"}>Last Updated</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {deviceData.map((device) => {
                                        if (corporateId.toString() === dData.corp.toString()) {
                                            return (<tr>
                                                <td><Link
                                                    to={`/profiles/server/${device?.palcare_device_id}`}>{device?.description}</Link>
                                                </td>
                                                <td className={"text-center"}>{device?.count_process_queue}</td>
                                                <td className={"text-center"}>{device?.count_aggregate_repeater_queue}</td>
                                                <td className={"text-center"}>{device?.count_eman_event_queue}</td>
                                                <td className={"text-center"}>{device?.count_maint_queue}</td>
                                                <td className={"text-center"}>{device?.count_other_queue}</td>
                                                <td className={"text-center"}>{device?.recent_alarm_input_time}</td>
                                                <td className={"text-center"}>
                                                    <MaterialIcon className={"material-icons as-btn-2x me-2"}
                                                                  onClick={() => {
                                                                      toast.info(device?.recent_alarm_input_raw);
                                                                      navigator?.clipboard?.writeText(device?.recent_alarm_input_raw);
                                                                  }}
                                                                  icon={"raw_on"}
                                                                  title={device?.recent_alarm_input_raw}/>
                                                    <MaterialIcon className={"material-icons as-btn-2x"}
                                                                  onClick={() => {
                                                                      toast.info(device?.recent_alarm_input_parsed);
                                                                      navigator?.clipboard?.writeText(device?.recent_alarm_input_parsed);
                                                                  }}
                                                                  icon={"data_object"}
                                                                  title={device?.recent_alarm_input_parsed}/>
                                                </td>
                                                <td className={"text-center"}>{device?.modify_time}</td>
                                            </tr>);
                                        } else {
                                            return <tr title={"Impersonate this campus for server linking."}>
                                                <td>{device?.description}</td>
                                                <td className={"text-center"}>{device?.count_process_queue}</td>
                                                <td className={"text-center"}>{device?.count_aggregate_repeater_queue}</td>
                                                <td className={"text-center"}>{device?.count_eman_event_queue}</td>
                                                <td className={"text-center"}>{device?.count_maint_queue}</td>
                                                <td className={"text-center"}>{device?.count_other_queue}</td>
                                                <td className={"text-center"}>{device?.recent_alarm_input_time}</td>
                                                <td className={"text-center"}>
                                                    <MaterialIcon className={"material-icons as-btn-2x me-2"}
                                                                  onClick={() => toast.info(device?.recent_alarm_input_raw)}
                                                                  icon={"raw_on"}
                                                                  title={device?.recent_alarm_input_raw}/>
                                                    <MaterialIcon className={"material-icons as-btn-2x"}
                                                                  onClick={() => toast.info(device?.recent_alarm_input_parsed)}
                                                                  icon={"data_object"}
                                                                  title={device?.recent_alarm_input_parsed}/>
                                                </td>
                                                <td className={"text-center"}>{device?.modify_time}</td>
                                            </tr>
                                        }
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "migrate" ? "d-block" : "d-none"}>
                        {groupActiveData.length === 0 && userActiveData.length === 0 && afrData.length === 0
                            && <div className={"ms-3"}>
                                <p>No assets to migrate.</p>
                            </div>
                        }
                        {groupActiveData.length > 0
                            ? <>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>2.0 Groups</h3>
                                            <div className={"d-inline"} style={{display: "inline-block"}}
                                                 id={"group-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>{groupActiveData?.length} Groups
                                                    available to migrate</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"group-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("group-card-btn", "group-card");
                                                   document.getElementById("group-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content hide"} id={"group-card"}
                                         style={{maxHeight: "500px", overflowY: "auto"}}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>

                                            </div>
                                            <div style={{minHeight: "55px"}}>
                                                {addRights && <Button text={"Migrate"} type={"button"}
                                                                      class={"btn btn-primary me-2 hide mb-3"}
                                                                      id={"migrateGroupBtn"} icon={true}
                                                                      iconClass={"fe fe-upload-cloud me-2"}
                                                                      onClick={() => confirmModalHandler({
                                                                          start_index: groupPPRules.start,
                                                                          end_index: groupPPRules.end,
                                                                          table_data: groupActiveData,
                                                                          display_item: "GroupName",
                                                                          record_item: ["GroupId", ""],
                                                                          content_setter: setDContent2,
                                                                          checkbox_id: "uCheckGroup",
                                                                          body_text: "The following groups will be migrated to 4.0. This cannot be undone, do you wish to proceed?",
                                                                          stored_content: "uContent2",
                                                                          rights: addRights,
                                                                          title: "Group Confirmation",
                                                                          submit_func: migrateGroupPerformSubmit
                                                                      })}/>}
                                            </div>
                                        </div>
                                        <div>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                                {groupHeaders}
                                                </thead>
                                                <tbody>
                                                {groupRows}
                                                </tbody>
                                            </table>
                                            <div className={"text-center"}>
                                                <Pagination>
                                                    <PaginationItems setPage={setGroupPage} page={groupPage}
                                                                     beginDot={groupPPRules.beginDot}
                                                                     endDot={groupPPRules.endDot}
                                                                     pageMin={groupPPRules.pageMin}
                                                                     pageMax={groupPPRules.pageMax}
                                                                     max={groupPPRules.max}/>
                                                </Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <></>}
                        {userActiveData.length > 0
                            ? <>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>2.0 Users</h3>
                                            <div className={"d-inline"} style={{display: "inline-block"}}
                                                 id={"user-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                    {userActiveData?.length} Users available to migrate
                                                </h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"user-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("user-card-btn", "user-card");
                                                   document.getElementById("user-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content hide"} id={"user-card"}
                                         style={{maxHeight: "500px", overflowY: "auto"}}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>

                                            </div>
                                            <div style={{minHeight: "55px"}}>
                                                {addRights && <Button text={"Migrate"} type={"button"}
                                                                      class={"btn btn-primary me-2 hide mb-3"}
                                                                      id={"migrateBtn"} icon={true}
                                                                      iconClass={"fe fe-upload-cloud me-2"}
                                                                      onClick={() => confirmModalHandler({
                                                                          start_index: ppRules.start,
                                                                          end_index: ppRules.end,
                                                                          table_data: userActiveData,
                                                                          display_item: "UserName",
                                                                          record_item: ["UserId", ""],
                                                                          content_setter: setDContent,
                                                                          checkbox_id: "uCheckUser",
                                                                          body_text: "The following users will be migrated to 4.0. This cannot be undone, do you wish to proceed?",
                                                                          stored_content: "uContent",
                                                                          rights: addRights,
                                                                          title: "User Confirmation",
                                                                          submit_func: migratePerformSubmit
                                                                      })}/>}
                                            </div>
                                        </div>
                                        <div>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                                {userHeaders}
                                                </thead>
                                                <tbody>
                                                {userRows}
                                                </tbody>
                                            </table>
                                            <div className={"text-center"}>
                                                <Pagination>
                                                    <PaginationItems setPage={setPage} page={page}
                                                                     beginDot={ppRules.beginDot}
                                                                     endDot={ppRules.endDot} pageMin={ppRules.pageMin}
                                                                     pageMax={ppRules.pageMax}
                                                                     max={ppRules.max}/>
                                                </Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <></>}
                        {afrDataSet.length > 0
                            ? <>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>2.0 AFR Devices</h3>
                                            <div className={"d-inline"} style={{display: "inline-block"}}
                                                 id={"afr-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                    {afrDataSet?.length} Devices available to migrate
                                                </h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"afr-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("afr-card-btn", "afr-card");
                                                   document.getElementById("afr-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content hide"} id={"afr-card"}
                                         style={{maxHeight: "500px", overflowY: "auto"}}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>

                                            </div>
                                            <div style={{minHeight: "55px"}}>
                                                {addRights && <Button text={"Migrate"} type={"button"}
                                                                      class={"btn btn-primary me-2 hide mb-3"}
                                                                      id={"migrateAFRBtn"} icon={true}
                                                                      iconClass={"fe fe-upload-cloud me-2"}
                                                                      onClick={() => confirmModalHandler({
                                                                          start_index: 0,
                                                                          end_index: afrDataSet.length,
                                                                          table_data: afrDataSet,
                                                                          display_item: "device_type_name",
                                                                          record_item: ["device_type_id", ""],
                                                                          content_setter: setDContent3,
                                                                          checkbox_id: "uCheckAFR",
                                                                          body_text: "The following device types for Accept Finish Reasons will be migrated to 4.0. This cannot be undone, do you wish to proceed?",
                                                                          stored_content: "uContent3",
                                                                          rights: addRights,
                                                                          title: "AFR Device Confirmation",
                                                                          submit_func: migrateAFRPerformSubmit
                                                                      })}/>}
                                            </div>
                                        </div>
                                        <div>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                                {afrHeaders}
                                                </thead>
                                                <tbody>
                                                {afrRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <></>
                        }
                        {hpDataSet.length > 0
                            ? <>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>2.0 High Priority Alerts</h3>
                                            <div className={"d-inline"} style={{display: "inline-block"}}
                                                 id={"hp-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                    {hpDataSet?.length} Alert types available to migrate
                                                </h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"hp-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("hp-card-btn", "hp-card");
                                                   document.getElementById("hp-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content hide"} id={"hp-card"}
                                         style={{maxHeight: "500px", overflowY: "auto"}}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>

                                            </div>
                                            <div style={{minHeight: "55px"}}>
                                                {addRights && <Button text={"Migrate"} type={"button"}
                                                                      class={"btn btn-primary me-2 hide mb-3"}
                                                                      id={"migrateHPBtn"} icon={true}
                                                                      iconClass={"fe fe-upload-cloud me-2"}
                                                                      onClick={() => confirmModalHandler({
                                                                          start_index: 0,
                                                                          end_index: hpDataSet.length,
                                                                          table_data: hpDataSet,
                                                                          display_item: "event_type_name",
                                                                          record_item: ["event_type_id", ""],
                                                                          content_setter: setDContent4,
                                                                          checkbox_id: "uCheckHP",
                                                                          body_text: "The following high priority event types will be migrated to 4.0. This cannot be undone, do you wish to proceed?",
                                                                          stored_content: "uContent4",
                                                                          rights: addRights,
                                                                          title: "High Priority Alert Confirmation",
                                                                          submit_func: migrateHPPerformSubmit
                                                                      })}/>}
                                            </div>
                                        </div>
                                        <div>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                                {hpHeaders}
                                                </thead>
                                                <tbody>
                                                {hpRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <></>
                        }
                        {reasonDataSet.length > 0
                            ? <>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>2.0 Custom Reason Codes</h3>
                                            <div className={"d-inline"} style={{display: "inline-block"}}
                                                 id={"reason-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                    {reasonDataSet?.length} Reason codes available to migrate
                                                </h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-plus as-btn collapse-toggle p-2"}
                                               id={"reason-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("reason-card-btn", "reason-card");
                                                   document.getElementById("reason-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content hide"} id={"reason-card"}
                                         style={{maxHeight: "500px", overflowY: "auto"}}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>

                                            </div>
                                            <div style={{minHeight: "55px"}}>
                                                {addRights && <Button text={"Migrate"} type={"button"}
                                                                      class={"btn btn-primary me-2 hide mb-3"}
                                                                      id={"migrateReasonBtn"} icon={true}
                                                                      iconClass={"fe fe-upload-cloud me-2"}
                                                                      onClick={() => confirmModalHandler({
                                                                          start_index: 0,
                                                                          end_index: reasonDataSet.length,
                                                                          table_data: reasonDataSet,
                                                                          display_item: "reason_code_description",
                                                                          record_item: ["reason_code_id", ""],
                                                                          content_setter: setDContent5,
                                                                          checkbox_id: "uCheckReason",
                                                                          body_text: "The following reason codes will be migrated to 4.0. This cannot be undone, do you wish to proceed?",
                                                                          stored_content_id: "uContent5",
                                                                          rights: addRights,
                                                                          title: "Reason Code Confirmation",
                                                                          submit_func: migrateReasonPerformSubmit
                                                                      })}/>}
                                            </div>
                                        </div>
                                        <div>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                                {reasonHeaders}
                                                </thead>
                                                <tbody>
                                                {reasonRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <></>
                        }
                        {alertData.length > 0
                            ? <>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>2.0 Alert Settings</h3>
                                            <div className={"d-inline"} style={{display: "inline-block"}}
                                                 id={"alert-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                    2.0 Alert & Customization settings available to migrate
                                                </h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-plus as-btn collapse-toggle p-2"}
                                               id={"alert-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("alert-card-btn", "alert-card");
                                                   document.getElementById("alert-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content hide"} id={"alert-card"}
                                         style={{maxHeight: "500px", overflowY: "auto"}}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>

                                            </div>
                                            <div style={{minHeight: "55px"}}>
                                                {addRights && <Button text={"Migrate"} type={"button"}
                                                                      class={"btn btn-primary me-2 show mb-3"}
                                                                      id={"migrateAlertBtn"} icon={true}
                                                                      iconClass={"fe fe-upload-cloud me-2"}
                                                                      onClick={() => confirmModalHandler({
                                                                          title: "Alert Settings Confirmation",
                                                                          rights: addRights,
                                                                          body_text: "Are you sure you wish to migrate alert and customization settings from 2.0. This will overwrite any current settings for the campus.",
                                                                          submit_func: migrateAlertPerformSubmit
                                                                      })}/>}
                                            </div>
                                        </div>
                                        <div>
                                            <ul style={{listStyle: "none"}}>
                                                {alertData.map((a) => {
                                                    return (
                                                        <>
                                                            <li>Accept: <span
                                                                className={"fw-bold"}>{a.AcceptButtonEnabled.toString().toUpperCase()}</span>
                                                            </li>
                                                            <li>Finish: <span
                                                                className={"fw-bold"}>{a.FinishButtonEnabled.toString().toUpperCase()}</span>
                                                            </li>
                                                            <li>Reason: <span
                                                                className={"fw-bold"}>{a.ReasonCodeEnabled.toString().toUpperCase()}</span>
                                                            </li>
                                                            <li>Release: After <span
                                                                className={"fw-bold"}>{a.ReleaseTimeInterval}</span> Minutes
                                                            </li>
                                                            <li>Clear: After <span
                                                                className={"fw-bold"}>{a.ClearTimeInterval}</span> Minutes
                                                            </li>
                                                            <li>Finish: After <span
                                                                className={"fw-bold"}>{a.FinishTimeInterval}</span> Minutes
                                                            </li>
                                                            <li>Group Edit: <span
                                                                className={"fw-bold"}>{a.CaregiverChgGroupEnabled.toString().toUpperCase()}</span>
                                                            </li>
                                                            <li>Display Edit: <span
                                                                className={"fw-bold"}>{a.UserEditDisplayName.toString().toUpperCase()}</span>
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <></>
                        }
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"uploadmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Upload Master File CSV</h2>
                            <button type={"button"} className={"btn-close"} id={"uploadConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <div className={"d-inline-block align-bottom"}>
                                <input className={"d-none"} id={"file-upload"} type={"file"} accept={".csv"}
                                       onChange={() => handleFileUpload()}/>
                                <Button type={"button"} text={"Set File"}
                                        class={"btn btn-outline-primary me-4 d-inline"}
                                        onClick={() => {
                                            document.getElementById("file-upload").click();
                                        }}/>
                                <div className={"form-floating mb-3 d-inline-block"} style={{width: "250px"}}>
                                    <input type={"text"} id={"filename-item"} className={"form-control"}
                                           readOnly={true} value={newFileName}></input>
                                    <label htmlFor={"filename-item"}>Selected File</label>
                                </div>
                            </div>
                            <div className={"d-inline-block ms-4"}>
                                <div className={"form-check form-switch mt-1"}>
                                    <input className={"form-check-input"} type={"checkbox"} checked={addAreas}
                                           id={"upload_areas"} onChange={(e) => setAddAreas(e.target.checked)}/>
                                    <label className={"form-check-label text-dark"}
                                           htmlFor={"upload_areas"}>Add Areas</label>
                                </div>
                                <div className={"form-check form-switch mt-1"}>
                                    <input className={"form-check-input"} type={"checkbox"} checked={addResidents}
                                           id={"upload_residents"} onChange={(e) => setAddResidents(e.target.checked)}/>
                                    <label className={"form-check-label text-dark"}
                                           htmlFor={"upload_residents"}>Add Residents</label>
                                </div>
                                <div className={"form-check form-switch mt-1"}>
                                    <input className={"form-check-input"} type={"checkbox"} checked={addDevices}
                                           id={"upload_devices"} onChange={(e) => {
                                        setAddDevices(e.target.checked)
                                    }}/>
                                    <label className={"form-check-label text-dark"}
                                           htmlFor={"upload_devices"}>Add Devices</label>
                                </div>
                            </div>
                        </div>
                        <div className={"modal-footer d-flex justify-content-between"}>
                            <div>
                                <Button type={"button"} class={"btn btn-primary"} text={"Check File"}
                                        onClick={() => uploadPrecheck()}
                                        disabled={newFile === ""}/>
                            </div>
                            <div>
                                <button type={"button"} className={"btn btn-secondary mx-2"} style={{width: "75px"}}
                                        data-bs-dismiss={"modal"} id={"uploadCancel"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-2"} style={{width: "100px"}}
                                        disabled={true} spinner={true} text={"Uploading"} id={"loadUploadSubmit"}/>
                                <button type={"button"}
                                        className={newFile === "" || !fileValid ? "btn btn-danger ms-2" : "btn btn-primary ms-2"}
                                        style={{width: "100px"}} id={"uploadSubmit"}
                                        disabled={newFile === "" || !fileValid}
                                        onClick={() => uploadSubmit()}>Upload
                                </button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addnotemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticAddNoteBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticAddNoteBackdropLabel"}>
                                Add Note
                            </h2>
                            <button type={"button"} className={"btn-close"} id={"addNoteClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"addNoteForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"my-2 form-floating"}>
                                    <textarea className={"form-control pt-5"} id={"note"} required={true}
                                              onKeyUp={() => formCheck("addNoteForm", "addNoteSubmit")}>

                                    </textarea>
                                    <label htmlFor={"note"} className={"form-label"}>Note</label>
                                    <div className={"invalid-feedback"}>
                                        Note required
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddNoteBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddNoteSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addNoteSubmit"}
                                            onClick={() => addNoteSubmit()}>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editnotemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticEditNoteConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticEditNoteConfirmLabel"}>Edit Note</h2>
                            <button type={"button"} className={"btn-close"} id={"editNoteConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"editNoteForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"my-2 form-floating"}>
                                    <textarea className={"form-control pt-5"} id={"noteE"} required={true}>

                                    </textarea>
                                    <label htmlFor={"noteE"} className={"form-label"}>Note</label>
                                    <div className={"invalid-feedback"}>
                                        Note required
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelEditNoteBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadEditNoteSubmit"}/>
                                <button type={"button"} className={"btn btn-primary ms-3"}
                                        onClick={() => editNoteSubmit()}
                                        id={"editNoteSubmit"}>Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"impersonatemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Role to impersonate as?</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"impersonateForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating"}>
                                    <select className={"form-select"} required={true} id={"userrole"}
                                            onChange={(e) => {
                                                formCheck("impersonateForm", "impersonateSubmit");
                                            }}>
                                        <option value={dData.my_role_weight}>My Role</option>
                                        {roleOptions}
                                    </select>
                                    <label htmlFor={"userrole"}>Impersonating User Role</label>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelImpersonateBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadImpersonateSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} id={"impersonateSubmit"}
                                        onClick={() => {
                                            let rweight;
                                            handleSubmitActions("impersonateSubmit", "loadImpersonateSubmit");
                                            rweight = document.getElementById("userrole").value;
                                            setRoleInfo(parseInt(rweight), dData);
                                            reloadSessionCorp(corporateId, 0, dData.cognito, dData, campusId);
                                        }}>Impersonate
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ConfirmModal/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"dContent2"}/>
            <input type={"hidden"} id={"uContent"}/>
            <input type={"hidden"} id={"uContent2"}/>
            <input type={"hidden"} id={"uContent3"}/>
            <input type={"hidden"} id={"uContent4"}/>
            <input type={"hidden"} id={"uContent5"}/>
            <BulkDeleteModal
                modalName={"deletenotesmodal"}
                deletePerform={() => deleteNotesPerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", notesPPRules.start, notesPPRules.end)}
                start={notesPPRules.start} end={notesPPRules.end} header={"Delete Notes"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default CampusProfile;