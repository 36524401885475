import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {Suspense, useEffect, useState} from "react";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {ToastContainer} from "react-toastify";
import {iAmHigherAuthority} from "../libs";

const EVM = (props) => {
    let dData, bData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    //const [count, setCount] = useState(0);
    const [srcMap, setSrcMap] = useState(<></>);
    //const [campusId, setCampusId] = useState(dData.acnt);
    const [dashId, setDashId] = useState("4947f716-452d-4ad3-99b0-162a555bcbaf?directory_alias=palcare-insights");

    useEffect(() => {
        if (dashId){
            setSrcMap(() => (<iframe style={{width: "100%", height: "1200px", maxHeight: "1000px"}}
                                     src={`https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/160611363433/dashboards/${dashId}`}></iframe>))
        }
    }, [dashId]);

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
        </div>
    );

    return (iAmHigherAuthority(99, dData?.role_weight)) ? (
        <>
            <Navigation />
            <div className={"main-content"}>
                <Header preTitle={"Pal Analytics"} Title={"Analytics Insights"} content={headerContent} />
                <div className={"container-fluid mt-4"}>
                    <div>
                        {srcMap}
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default EVM;