import {Link, useNavigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    formCheck,
    formCheckWithElement,
    formClear,
    formFail,
    formSuccess,
    areaLearninBehaviorToggle,
    handleSubmitActions,
    hideModal,
    showModal,
    sortFunc,
    swapModal,
    toggleCollapse,
    iAmHigherAuthority,
    setAreaFilters, formatDateIso, getToday, HHMMtoLocalTime, convertSectoHHMM, isBannerPresent, versionSort
} from "../../libs";
import {Chart, registerables} from "chart.js";
import TableBody from "../component-assets/TableBody";
import annotationPlugin from "chartjs-plugin-annotation";
import TableHeaders from "../component-assets/TableHeaders";

const AreaProfile = (props) => {
    let dData, bData, startDate, endDate, eventHeaderData, noteHeaderData;

    const navigate = useNavigate();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    eventHeaderData = [
        // {label: "Event Time", key: "event_datetime_local", align: "center", sorts: false,
        //     link: {enabled: true, linkto: "/profiles/event", linkkey: "event_id", type: "datetime"}},
        {label: "Event Time", key: "event_datetime_local", align: "center", sorts: false, type: "datetime"},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false},
        {label: "Device", key: "description", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
        {label: "Resident", key: "resident_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Reason", key: "reason_code_description", align: "left", sorts: false},
    ];

    noteHeaderData = [
        {label: "Timestamp", key: "create_time", align: "center", type: "datetime-utc", sorts: false},
        {label: "Created By", key: "display_name", align: "center", sorts: false},
        {label: "Note", key: "note", align: "left", sorts: false},
    ]

    // Permission Variables
    const [editRights, setEditRights] = useState(iAmHigherAuthority(20, dData.role_weight));
    const [devEditRights, setDevEditRights] = useState(iAmHigherAuthority(10, dData.role_weight))
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(20, dData.role_weight));
    // Main Page Variables
    const [areaId, setAreaId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [repCount, setRepCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tabItem, setTabItem] = useState("basic");
    const [storedIndex, setStoredIndex] = useState(0);
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [areaName, setAreaName] = useState("");
    const [maxOccupancy, setMaxOccupancy] = useState("");
    const [residents, setResidents] = useState("");
    const [areaType, setAreaType] = useState("");
    const [areaTypeId, setAreaTypeId] = useState("");
    const [areaTypeData, setAreaTypeData] = useState([]);
    const [areaTypeOptions, setAreaTypeOptions] = useState([]);
    const [areaGroups, setAreaGroups] = useState([]);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [areaTypeLoaded, setAreaTypeLoaded] = useState(false);
    // Device Information Variables
    const [areaDeviceData, setAreaDeviceData] = useState([]);
    const [deviceData, setDeviceData] = useState([])
    const [devices, setDevices] = useState([])
    const [deviceToRemove, setDeviceToRemove] = useState("");
    const [assignedArea, setAssignedArea] = useState("");
    // Device Learn in Variables
    const [listening, setListening] = useState(false); // Flag indicating listening started, stopped
    const [listenEnabled, setListenEnabled] = useState(false); // Flag if listen feature ready or not
    const [listenCount, setListenCount] = useState(60);  // Counter for fetching and toggle listen off
    const [listenData, setListenData] = useState([]);  // Data response of devices heard
    const [listenList, setListenList] = useState([]); // Drop down options for heard devices serial numbers
    const [deviceCatData, setDeviceCatData] = useState([]);  // Device category data used for drop down in learn in
    const [deviceCatOptions, setDeviceCatOptions] = useState([]);  // Device category drop down options for learn in
    const [deviceTypeData, setDeviceTypeData] = useState([]); // Data used for device type drop down in learn in
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]); // Device type drop down options for learn in
    const [deviceCatId, setDeviceCatId] = useState(0);  // Device Category set in learn in
    const [deviceTypeId, setDeviceTypeId] = useState([]);  // Device Type set in learn in
    const [modelId, setModelId] = useState(0);  // Model selected in list
    const [modelOptions, setModelOptions] = useState([]);
    const [msgClass, setMsgClass] = useState(0);
    const [productCode, setProductCode] = useState(0);
    const [marketId, setMarketId] = useState(0);
    const [allProductCodes, setAllProductCodes] = useState([]);
    const [showDeviceList, setShowDeviceList] = useState(false); // Toggles showing drop down serial or not and required validation
    const [listenDevice, setListenDevice] = useState("");
    const [deviceTypeName, setDeviceTypeName] = useState("");
    const [listenPCode, setListenPCode] = useState(0);
    const [useOptions, setUseOptions] = useState([]);
    const [useId, setUseId] = useState(0);
    const [showAlarmBehaviors, setShowAlarmBehaviors] = useState(false);
    const [deviceImg, setDeviceImg] = useState("");
    // Wellness Variables
    const [areaWellData, setAreaWellData] = useState([]);
    const [careData, setCareData] = useState([]);
    const [roundsData, setRoundsData] = useState([]);
    const [careflow, setCareflow] = useState([]);
    const [rounds, setRounds] = useState([]);
    // Reporting Variables
    const [weekData, setWeekData] = useState({});
    const [hourData, setHourData] = useState({});
    const [hourDataLoaded, setHourDataLoaded] = useState(false);
    const [weekDataLoaded, setWeekDataLoaded] = useState(false);
    const [reasonData, setReasonData] = useState({});
    const [reasonRows, setReasonRows] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventTableRows, setEventTableRows] = useState([]);
    const [hourTimer, setHourTimer] = useState(100);
    const [weekTimer, setWeekTimer] = useState(100);
    const [noteTableRows, setNoteTableRows] = useState([]);
    const [noteTableLength, setNoteTableLength] = useState(0);

    startDate = formatDateIso(getToday(-28));
    endDate = formatDateIso(getToday());

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {area_id: areaId, campus_id: campusId},
                          procedure: "getareaprofile", reqType: "stored"}).then(data => {
                setBasicData(data);
            });
            ApiRequester({reqEndpoint: "myfetch",
                          payload: {campus_id: campusId, care_types: "1,5", care_event_types: "1"},
                          procedure: "getcareflowwindows", reqType: "stored"}).then(data => {
                setCareData(data);
            });
            ApiRequester({reqEndpoint: "myfetch",
                          payload: {campus_id: campusId, window_types: "2"},
                          procedure: "getwellnesswindows", reqType: "stored"}).then(data => {
                setRoundsData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {area_id: areaId, campus_id: campusId},
                          procedure: "getareadevices", reqType: "stored"}).then(data => {
                setAreaDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_area_type",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setAreaTypeData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getareadevicecategories", reqType: "stored"}).then(data => {
                setDeviceCatData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getinovonicsdevicetypes", reqType: "stored"}).then(data => {
                setDeviceTypeData(data);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![basicLoaded, areaTypeLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded, areaTypeLoaded]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setRepCount(repCount => repCount - 1);
        }, 1000);
        if (repCount <= 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardhourchart",
                                campus_id: campusId, area_filter: areaId.toString()}).then(data => {
                setHourData(data);
                setHourDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardweekchart",
                                campus_id: campusId, area_filter: areaId.toString()}).then(data => {
                setWeekData(data);
                setWeekDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "eventReasonSummary",
                        campus_id: campusId, start_date: startDate, end_date: endDate,
                        device_category_filter: "", area_type_filter: "", reason_filter: "", user_filter: "",
                        event_type_filter: "", resident_filter: "", area_filter: areaId.toString()}).then(data => {
                setReasonData(() => data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId, area_id: areaId.toString()},
                                procedure: "getlimitedevents", reqType: "stored"}).then(data => {
                setEventData(data);
            });
            setRepCount(repCount => 1200);
        }
        return () => clearInterval(interval);
    }, [repCount]);

    useEffect(() => {
        let resItems = []
        if (basicData.length > 0){
            setAreaName(() => basicData[0]?.area_name ? basicData[0].area_name : "");
            setMaxOccupancy(() => basicData[0]?.max_occupancy ? basicData[0].max_occupancy : "");
            setAreaType(() => basicData[0]?.area_type_description ? basicData[0].area_type_description : "");
            setAreaTypeId(() => basicData[0]?.area_type_id ? basicData[0].area_type_id : "");
            setAreaGroups(() => basicData[0]?.groups ? basicData[0].groups : []);
            for (let i=0; i < basicData[0]?.residents?.length; i++){
                if (i > 0){
                    resItems.push(<br key={`break${i}`}/>);
                    resItems.push(<Link key={`resident${i}`} to={`/profiles/resident/${basicData[0].residents[i].resident_id}`}>{basicData[0].residents[i].resident_name}</Link>);
                }else{
                    resItems.push(<Link key={`resident${i}`} to={`/profiles/resident/${basicData[0].residents[i].resident_id}`}>{basicData[0].residents[i].resident_name}</Link>);
                }
            }
            setResidents(() => resItems);
            setBasicLoaded(true);
        }
    }, [basicData]);

    useEffect(() => {
        let tmpAflows = [], tmpDflows = [], allFlows;
        if (pageLoaded && careData?.length > 0){
            for (let i=0; i < careData?.length; i++){
                if (careData[i].escalation_type_id === 1){
                    tmpDflows.push(<>
                        <div key={`cfd-1-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                            {careData[i].careflow_description}
                        </div>
                        <div key={`cft-1-${i}`} className={"d-inline-block align-top ms-1"}>
                            {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Default)
                        </div>
                    </>);
                }else if (careData[i].escalation_type_id === 5){
                    for (let i3=0; i3 < areaGroups?.length; i3++){
                        if (careData[i]?.references.split(",").includes(areaGroups[i3]?.area_group_id?.toString())){
                            tmpAflows.push(<>
                                <div key={`cfd-5-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                    {careData[i].careflow_description}
                                </div>
                                <div key={`cft-5-${i}`} className={"d-inline-block align-top ms-1"}>
                                    {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Area)
                                </div>
                            </>);
                        }
                    }
                }
            }
            allFlows = tmpAflows.concat(tmpDflows);
            setCareflow(allFlows.map((item, i) => {return <div key={`cf-item-${i}`}>{item}<br/></div>}));
        }
    }, [careData, pageLoaded]);

    useEffect(() => {
        let tmpWindows = [];
        if (pageLoaded && roundsData?.length > 0){
            for (let i=0; i < roundsData?.length; i++){
                for (let i3=0; i3 < areaGroups?.length; i3++){
                    if (roundsData[i]?.area_group_id_list.split(",").includes(areaGroups[i3]?.area_group_id?.toString())){
                        tmpWindows.push(<>
                            <div key={`crd-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                {roundsData[i].description}
                            </div>
                            <div key={`crt-${i}`} className={"d-inline-block align-top ms-1"}>
                                {HHMMtoLocalTime(convertSectoHHMM(roundsData[i].window_start))} - {HHMMtoLocalTime(convertSectoHHMM(roundsData[i].window_end))}
                            </div>
                        </>);
                    }
                }
            }
            setRounds(tmpWindows.map((item, i) => {return <div key={`cr-item-${i}`}>{item}<br/></div>}));
        }
    }, [roundsData, pageLoaded]);

    useEffect(() => {
        let tmp = [];
        for (let i = 0; i < areaDeviceData?.length; i++){
            tmp.push(
                <li key={`device-name-${i}`} className={"mt-2"}>
                    {devEditRights ?
                    <i className={"fe fe-x-square red-hover fs-1 me-3"} style={{verticalAlign: "sub"}} title={"Detach device"}
                       onClick={() => {
                           setDeviceToRemove(() => areaDeviceData[i]?.device_id);
                           showModal("deviceconfirmmodal")
                       }}/> : <></>}
                    <Link to={`/profiles/inovonics/${areaDeviceData[i].device_id}`}>{areaDeviceData[i].device_name} ({areaDeviceData[i].manufacture_uuid})</Link>
                </li>
            );
        }
        setDevices(() => tmp);
    }, [areaDeviceData, devEditRights]);

    useEffect(() => {
        // setEventTableRows(<TableBody start={0} end={eventData.length} tableData={eventData} checkbox={false}
        //                     writeAccess={true} unk={"a-"} modalType={"add"} modalLabel={"Add Note"}
        //                     actionAppearance={() => {}} parentComponent={"Resident"}
        //                     tableHeaders={eventHeaderData} modal={true} editModal={addNoteModal}
        //                     dData={dData} checkboxlist={[]} editModalBadge={"notes"}
        //                     setCheckboxId={() => {}} checkidkey={""} />);
        setEventTableRows(<TableBody start={0} end={eventData.length} tableData={eventData} checkbox={false}
                            writeAccess={true} unk={"a-"} modalType={"add"} modalLabel={"Add Note"}
                            actionAppearance={() => {}} parentComponent={"Resident"}
                            tableHeaders={eventHeaderData} modal={false} editModal={() => {}}
                            dData={dData} checkboxlist={[]} editModalBadge={"notes"}
                            setCheckboxId={() => {}} checkidkey={""} />);
    }, [eventData]);

    useEffect(() => {
        if (deviceCatData.length > 0){
            let tmpOptions = [];
            for (let i=0; i < deviceCatData.length; i++){
                tmpOptions.push(
                    <option key={`device-category-option-${deviceCatData[i].device_category_id}`}
                            value={deviceCatData[i].device_category_id}>{deviceCatData[i].device_category_short_name}
                    </option>
                );
            }
            setDeviceCatOptions(() => tmpOptions);
        }
    }, [deviceCatData]);

    useEffect(() => {
        let i, tmp = [];
        deviceTypeData.sort((a, b) => sortFunc(a, b, "device_type_name", "asc"));
        for (i = 0; i < deviceTypeData.length; i++){
            if ((deviceTypeData[i]?.device_category_id.toString() === deviceCatId.toString()) &&
                (iAmHigherAuthority(80, dData.role_weight) || !deviceTypeData[i]?.is_third_party)){
                tmp.push(
                    <option key={`device-use-option-${deviceTypeData[i]?.inovonics_type_id}`}
                                 value={deviceTypeData[i]?.inovonics_type_id}>{deviceTypeData[i]?.device_type_name}
                    </option>
                );
            }
        }
        setDeviceTypeOptions(() => tmp);
    }, [deviceTypeData, deviceCatId]);

    useEffect(() => {
        let tmp = [], models = [], tmp2 = [], uses = [], tmp3 = [], imgstr = "";
        for (let i=0; i < deviceTypeData.length; i++){
            if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                uses = deviceTypeData[i]?.uses ? deviceTypeData[i].uses : [];
                imgstr = deviceTypeData[i]?.image_url ? deviceTypeData[i].image_url : "";
                break
            }
        }
        for (let i2=0; i2 < models.length; i2++){
            if (i2 === 0){
                setModelId(models[i2].model_id);
                setMsgClass(models[i2].message_class);
                setProductCode(models[i2].product_type_id);
                setMarketId(models[i2].market_id);
            }
            tmp2.push(models[i2].product_type_id);
            tmp.push(
                <option key={`device-model-option-${i2}`}
                        value={models[i2]?.model_id}>{models[i2]?.model_name}
                </option>
            );
        }
        for (let i3=0; i3 < uses.length; i3++){
            if (i3 === 0){
                setUseId(uses[i3].inovonics_device_use_id);
                setShowAlarmBehaviors(uses[i3].option_number !== 0);
            }
            if (uses[i3].option_number !== 0){
                tmp3.push(
                    <div className={"form-check form-switch mt-1"} id={`use-option-${uses[i3].option_number}-container`}>
                        <input className={"form-check-input"} id={`use-option-${uses[i3].option_number}`} type={"radio"}
                               name={"alarmBehavior"} value={uses[i3].inovonics_device_use_id} defaultChecked={i3 === 0}
                               onChange={(e) => {setUseId(parseInt(e.target.value))}} />
                        <label className={"form-check-label me-4 mt-1 text-dark"}
                               htmlFor={`use-option-${uses[i3].option_number}`} id={`use-option-label-${uses[i3].option_number}`}>
                            {uses[i3].option_text}
                        </label>
                    </div>
                );
            }
        }
        setAllProductCodes(tmp2);
        setModelOptions(() => tmp);
        setUseOptions(() => tmp3);
        setDeviceImg(imgstr);
    }, [deviceTypeData, deviceTypeId]);

    useEffect(() => {
        let models = [];
        for (let i=0; i < deviceTypeData.length; i++){
            if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                break
            }
        }
        for (let i2=0; i2 < models.length; i2++){
            if (models[i2].model_id === modelId){
                setMsgClass(models[i2].message_class);
                setProductCode(models[i2].product_type_id);
                setMarketId(models[i2].market_id);
            }
        }
        setListenEnabled(modelId !== 0);
    }, [modelId]);

    useEffect(() => {
        if (areaTypeData.length > 0){
            let tmpOptions = [];
            for (let i=0; i < areaTypeData.length; i++){
                tmpOptions.push(
                    <option key={`area-type-option-${areaTypeData[i].area_type_id}`}
                            value={areaTypeData[i].area_type_id}>
                        {areaTypeData[i].area_type_description}
                    </option>
                );
            }
            setAreaTypeOptions(() => tmpOptions);
            setAreaTypeLoaded(true);
        }
    }, [areaTypeData]);

    useEffect(() => {
        let tmp = [];
        let reasons = reasonData?.reasons ? reasonData.reasons : [];
        for (let i=0; i < reasons?.length; i++){
            tmp.push(
                <tr key={`summaryRow-reasons-${i}`}>
                    <td className={"ps-1"}>{reasons[i].reason_name}</td>
                    <td className={"ps-1 text-center"}>{reasons[i].count}</td>
                    <td className={"ps-1 text-center"}>{reasons[i].percent}</td>
                    <td className={"ps-1 text-center"}>{reasons[i]?.avg_response_time}</td>
                </tr>
            );
        }
        setReasonRows(() => tmp);
    }, [reasonData]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [listening, listenDevice]);

    useEffect(() => {
        let interval;
        if (checkListenValid()){
            interval = setInterval(() => {
                setListenCount(listenCount  => listenCount  + 1);
            }, 1000);
            if (listenCount < 60){
                if (listenCount % 4 === 0){
                    ApiRequester({reqEndpoint: "getitems", Table: "LearnIn", PKey: "ClientId",
                        PVal: dData.sessionId, CampusId: campusId}).then(data => {
                            setListenData(data);
                    });
                }
            }
            if (listenCount === 60){
                setListening(false);
                toast.info("Capture timeout, restart capture if device not in list.");
            }
        }
        return () => clearInterval(interval);
    }, [listenCount]);

    useEffect(() => {
        let tmpList = [];
        for (let i=0; i < listenData.length; i++){
            tmpList.push(
                <option key={`listen-${listenData[i]?.trx_id}`} value={[listenData[i]?.trx_id, listenData[i]?.pType]}>
                    {listenData[i]?.name} ({listenData[i]?.trx_id})
                </option>
            );
        }
        setListenList(tmpList);
        if (listenDevice === "" && listenData.length > 0){
            setListenDevice(() => listenData[0]?.trx_id);
            setListenPCode(() => listenData[0]?.pType);
            toast.success("Devices found.");
        }
    }, [listenData]);

    useEffect(() => {
        if (hourData !== undefined && hourDataLoaded){
            if (document.getElementById("perhour") !== null){
                if (Chart.getChart("perhour") !== undefined){
                    const curChart = Chart.getChart("perhour");
                    curChart.data.labels = hourData?.labels ? hourData.labels : [];
                    curChart.data.datasets[0].data = hourData?.events ? hourData.events : [];
                    curChart.data.datasets[1].data = hourData?.responses ? hourData.responses : [];
                    curChart.options.plugins.annotation.annotations.line1.value = hourData?.avgValue ? hourData.avgValue : 0
                    curChart.options.plugins.annotation.annotations.line2.value = hourData?.avgResp ? hourData.avgResp : 0;
                    curChart.resize();
                    curChart.update();
                }
                else{
                    new Chart('perhour', {
                        options: {
                            responsive: true,
                            plugins: {
                                annotation: {
                                    drawTime: 'afterDatasetsDraw',
                                    annotations: {
                                        line1: {
                                            type: 'line',
                                            borderDash: [8, 4],
                                            borderWidth: 4,
                                            scaleID: "a",
                                            borderColor: "#0081C778",
                                            value: hourData?.avgValue ? hourData.avgValue : 0,
                                            label: {
                                                display: false,
                                                backgroundColor: "#0081C7",
                                                borderColor: "#000000",
                                                borderWidth: 2,
                                                content: `Alarm Average: ${hourData?.avgValue ? hourData.avgValue : 0}`,
                                            },
                                            enter({element}, event) {
                                                element.label.options.display = true;
                                                return true;
                                            },
                                            leave({element}, event) {
                                                element.label.options.display = false;
                                                return true;
                                            }
                                        },
                                        line2: {
                                            type: 'line',
                                            borderDash: [8, 4],
                                            borderWidth: 4,
                                            scaleID: "b",
                                            borderColor: "#BE4D2578",
                                            value: hourData?.avgResp ? hourData.avgResp : 0,
                                            label: {
                                                display: false,
                                                backgroundColor: "#BE4D25",
                                                borderColor: "#000000",
                                                borderWidth: 2,
                                                content: `Response Time Average: ${hourData?.avgResp ? hourData.avgResp : 0}`,
                                            },
                                            enter({element}, event) {
                                                element.label.options.display = true;
                                                return true;
                                            },
                                            leave({element}, event) {
                                                element.label.options.display = false;
                                                return true;
                                            }
                                        }
                                    }
                                },
                                legend: {
                                    display: true
                                },
                            },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: "Time of the Day"
                                    },
                                    grid: {
                                        display: false
                                    },
                                },
                                a: {
                                    id: "a",
                                    position: "left",
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: "Total Alarms"
                                    },
                                    suggestedMax: 10,
                                    suggestedMin: 0,
                                    ticks: {
                                        callback: function (value) {
                                            return value
                                        }
                                    }
                                },
                                b: {
                                    id: "b",
                                    position: "right",
                                    title: {
                                        display: true,
                                        text: "Average Response Time"
                                    },
                                    grid: {
                                        display: false
                                    },
                                    suggestedMax: 10,
                                    suggestedMin: 0,
                                    ticks: {
                                        callback: function (value) {
                                            return value
                                        }
                                    }
                                }
                            }
                        },
                        data: {
                            labels: hourData?.labels?.length > 0 ? hourData.labels : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                                    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                            datasets: [{
                                type: "bar",
                                label: 'Total Alarms',
                                data: hourData?.events ? hourData.events : [],
                                backgroundColor: ["#0081C7"],
                                hoverBackgroundColor: ["#0081C778"],
                                yAxisID: "a",
                                order: 2
                            },{
                                type: "line",
                                label: "Average Response Time",
                                data: hourData?.responses ? hourData.responses : [],
                                backgroundColor: ["#BE4D25"],
                                hoverBackgroundColor: ["#BE4D2578"],
                                borderColor: ["#BE4D2578"],
                                fill: false,
                                yAxisID: "b",
                                order: 1
                            }]
                        }
                    });
                }
            }else{
                setHourTimer((hourTimer) => hourTimer - 1);
            }
        }
    }, [hourData, hourDataLoaded, hourTimer]);

    useEffect(() => {
        if (weekData !== undefined && weekDataLoaded){
            if (document.getElementById("perweek") !== null){
                if (Chart.getChart("perweek") !== undefined){
                    const curChart = Chart.getChart("perweek");
                    curChart.data.labels = weekData?.labels ? weekData.labels : [];
                    curChart.data.datasets[0].data = weekData?.events ? weekData.events : [];
                    curChart.resize();
                    curChart.update();
                }
                else{
                    new Chart('perweek', {
                        options: {
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: true
                                },
                            },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: "Weeks"
                                    },
                                    grid: {
                                        display: false
                                    },
                                },
                                a: {
                                    id: "a",
                                    position: "left",
                                    title: {
                                        display: true,
                                        text: "Total Alarms"
                                    },
                                    grid: {
                                        display: false
                                    },
                                    suggestedMax: 10,
                                    suggestedMin: 0,
                                    ticks: {
                                        callback: function (value) {
                                            return value
                                        }
                                    }
                                }
                            }
                        },
                        data: {
                            labels: weekData?.labels?.length > 0 ? weekData.labels : [],
                            datasets: [{
                                type: "line",
                                label: "Total Alarms",
                                data: weekData?.events?.length > 0 ? weekData.events : [],
                                backgroundColor: ["#0081C7"],
                                hoverBackgroundColor: ["#0081C778"],
                                borderColor: ["#0081C778"],
                                fill: false,
                                yAxisID: "a",
                                order: 1
                            }]
                        }
                    });
                }
            }else{
                setWeekTimer((weekTimer) => weekTimer - 1);
            }
        }
    }, [weekData, weekDataLoaded, weekTimer]);

    function checkListenValid(){
        return [listening, deviceCatId !== 0, deviceTypeId !== 0].every((item) => item === true);
    }

    function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("name-edit").classList.toggle("d-none");
        document.getElementById("area-type-static").classList.toggle("d-none");
        document.getElementById("area-type-edit").classList.toggle("d-none");
        document.getElementById("occupancy-static").classList.toggle("d-none");
        document.getElementById("occupancy-edit").classList.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("name-edit").value = areaName;
            document.getElementById("area-type-edit").value = areaTypeId;
            document.getElementById("occupancy-edit").value = maxOccupancy;
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let name, typeId, occupancy, payload, oldPayload;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            name = document.getElementById("name-edit").value;
            typeId = document.getElementById("area-type-edit").value;
            occupancy = document.getElementById("occupancy-edit").value;
            payload = [{area_id: parseInt(areaId), area_name: name, area_type_id: typeId, max_occupancy: occupancy,
                        modified_by: dData.user}];
            oldPayload = [{area_id: parseInt(areaId), area_name: areaName, area_type_id: areaTypeId,
                           max_occupancy: maxOccupancy}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_areas", PKey: "area_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", Payload: payload, OldPayload: oldPayload,
                                User: dData.user, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`, "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function addNoteModal(rowIndex){
        let dataIndex;
        dataIndex = parseInt(rowIndex);
        setStoredIndex(dataIndex);
        document.getElementById("noteE").value = "";
        document.getElementById("noteE").style.height = "125px";
        setNoteTableLength(eventData[dataIndex].notes.length);
        setNoteTableRows(<TableBody start={0} end={eventData[dataIndex].notes.length}
                                    tableData={eventData[dataIndex].notes} checkbox={false}
                                     writeAccess={false} unk={"n-"} actionAppearance={() => {}}
                                     tableHeaders={noteHeaderData} modal={false}
                                     parentComponent={"Event"} dData={dData} checkboxlist={[]}
                                     setCheckboxId={() => {}} checkidkey={"note_id"}
                                     tzOffset={parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0)}/>);
        formCheck("addNoteForm", "addNoteSubmit");
        showModal("addnotemodal");
    }

    function noteSubmit(){
        let payload, eventId, note;
        handleSubmitActions("addNoteSubmit", "loadAddNoteSubmit");
        eventId = eventData[storedIndex]?.event_id;
        note = document.getElementById("noteE").value;
        payload = [{event_id: eventId, campus_id: dData.acnt, note: note, modified_by: dData?.user}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_event_notes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "note_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                formSuccess(`Note added successfully.`, "addNoteSubmit", "loadAddNoteSubmit", "cancelAddNoteBtn", "addNoteForm");
                setRepCount(0);
                document.getElementById("submitBtn").click()
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addNoteSubmit", "loadAddNoteSubmit");
            }
        });
    }

    function removeDevice(deviceId){
        let payload
        if (devEditRights){
            handleSubmitActions("deviceConfirmSubmit", "loadDeviceConfirmSubmit");
            payload = [{device_id: deviceId, is_active: false, area_id: null, modified_by: dData.user}]
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "device_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    setCount(0);
                    formSuccess("Device has been removed", "deviceConfirmSubmit", "loadDeviceConfirmSubmit", "deviceCancelBtn");
                }
                else{
                    formFail("Failed to remove device", "deviceConfirmSubmit", "loadDeviceConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deviceExists(serialNum){
        for (let i=0; i < deviceData?.length; i++){
            if (serialNum.toString() === deviceData[i]?.manufacture_uuid.toString()) {
                return deviceData[i];
            }
        }
        return false;
    }

    function getModelId(){
        let models = [], ret = 0;
        for (let i=0; i < deviceTypeData.length; i++){
            if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                break;
            }
        }
        for (let i2=0; i2 < models.length; i2++){
            if (listenPCode === models[i2].product_type_id){
                ret = models[i2].model_id;
            }
        }
        return ret;
    }

    function pendantSubmit(initial){
        let description, msg, payload, serialNum, dRec, nid, mId, oldPayload;
        if (devEditRights){
            if (showDeviceList){
                serialNum = listenDevice;
            }else{
                if (!serialNum || serialNum === ""){
                    serialNum = document.getElementById("serialNum").value;
                }
                if (serialNum && !isNaN(serialNum)){
                    serialNum = parseInt(serialNum);
                }
            }
            dRec = deviceExists(serialNum);
            if (initial){
                if (dRec && dRec?.is_active){
                    setAssignedArea(dRec?.area_name);
                    hideModal("pendantmodal");
                    showModal("pendantconfirmmodal");
                    return;
                }else{
                    handleSubmitActions("pendantSubmit", "loadPendantSubmit");
                }
            }else{
                handleSubmitActions("pendantConfirmSubmit", "loadPendantConfirmSubmit");
            }

            if (listening){
                setListening(false);
                setListenCount(60);
            }

            description = document.getElementById("description").value;
            mId = modelId;
            if (!iAmHigherAuthority(80, dData.role_weight)){
                mId = getModelId(listenPCode);
            }

            if (dRec){
                // Edit device and add to area
                payload = [{device_id: dRec.device_id, campus_id: campusId, is_active: true, area_id: parseInt(areaId),
                            device_use_id: parseInt(useId), device_name: deviceTypeName, manufacture_model_id: parseInt(mId),
                            descriptions: description, is_area_device: true, is_resident: false, resident_id: 0,
                            device_type_id: deviceTypeId, modified_by: dData.user}];
                oldPayload = [{device_id: dRec.device_id, campus_id: dRec?.campus_id, is_active: dRec?.is_active,
                               area_id: dRec?.area_id, device_use_id: dRec?.device_use_id,
                               device_name: dRec?.device_name, descriptions: dRec?.descriptions,
                               manufacture_model_id: dRec?.manufacture_model_id, device_type_id: dRec?.device_type_id}];
                if (["135", "131"].includes(useId.toString())){
                    if (["135"].includes(useId.toString())){
                        nid = document.getElementById("nid")?.value;
                        if (!nid){
                            nid = 0;
                        }
                    }else{
                        nid = -1;
                    }
                    payload[0]["network_id"] = parseInt(nid);
                    ApiRequester({reqEndpoint: "mytransaction", TransType: "system_device_edit", Payload: payload,
                                CampusId: campusId, User: dData.user, OldPayload: oldPayload,
                                ClientId: dData.sessionId}).then(data => {
                        if (data[0]) {
                            msg = `${deviceTypeName} has been added to ${areaName}`;
                            if (!initial){
                                handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                                handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                                swapModal("pendantConfirmClose", "editmodal");
                                document.getElementById("pendantForm").reset();
                                toast.success(msg);
                                setCount(0);
                                setListenEnabled(false);
                            }else{
                                formSuccess(msg, "pendantSubmit", "loadPendantSubmit",
                                "cancelPendantBtn", "pendantForm");
                                setCount(0);
                            }
                            setShowDeviceList(false);
                        } else {
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                    });
                }else{
                    ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", Payload: payload, OldPayload: oldPayload,
                                User: dData.user, CampusId: campusId}).then(data => {
                        if (data && data[0] === true){
                            msg = `${deviceTypeName} has been added to ${areaName}`;
                            if (!initial){
                                handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                                hideModal("pendantconfirmmodal");
                                document.getElementById("pendantForm").reset();
                                toast.success(msg);
                                setCount(0);
                                setListenEnabled(false);
                            }else{
                                formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                                setCount(0);
                            }
                            setShowDeviceList(false);
                        }
                        else{
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                    });
                }
            }else{
                // Add device and add to area
                payload = [{campus_id: campusId, manufacture_id: 1, manufacture_uuid: serialNum.toString(), device_name: deviceTypeName,
                            device_use_id: parseInt(useId), is_active: true, is_alerting_now: false, is_server: false,
                            is_area_device: true, is_resident: false, area_id: parseInt(areaId), manufacture_model_id: parseInt(mId),
                            escalation_enabled: true, descriptions: description, modified_by: dData.user, device_type_id: deviceTypeId}];
                if (["135", "131"].includes(useId.toString())){
                    if (["135"].includes(useId.toString())){
                        nid = document.getElementById("nid")?.value;
                        if (!nid){
                            nid = 0;
                        }
                    }else{
                        nid = -1;
                    }
                    payload[0]["network_id"] = parseInt(nid);
                    ApiRequester({reqEndpoint: "mytransaction", TransType: "system_device_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                        if (data[0]) {
                            formSuccess(`${deviceTypeName} has been added to ${areaName}`, "pendantSubmit", "loadPendantSubmit",
                                "cancelPendantBtn", "pendantForm");
                            setCount(0);
                            setShowDeviceList(false);
                        } else {
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                    });
                }else{
                    ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "PUT",
                                    CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                        if (data && data[0] === true){
                            formSuccess(`${deviceTypeName} has been added to ${areaName}`, "pendantSubmit", "loadPendantSubmit",
                                 "cancelPendantBtn", "pendantForm");
                            setCount(0);
                            setShowDeviceList(false);
                        }
                        else{
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                    });
                }
            }
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function publishListen(){
        let sendPtype, modelStuff;
        if (dData.serverVersion === "" || versionSort(dData.serverVersion, "4.04.14") >= 0){
            sendPtype = iAmHigherAuthority(80, dData.role_weight) ? [productCode] : allProductCodes;
            modelStuff = iAmHigherAuthority(80, dData.role_weight) ? `Specific ${productCode}` : "All codes";
        }else{
            sendPtype = productCode;
            modelStuff = productCode;
        }
        ApiRequester({reqEndpoint: "mqtt", Operation: "learnin", ClientId: dData.sessionId,
                            ProductType: sendPtype, CampusId: dData?.acnt, MsgClass: msgClass}).then(data => {
            if (data[0]){
                toast.info(`Listening for ${deviceTypeName} devices, Model Code: ${modelStuff}`);
                setListenCount(0);
            }
            else{
                toast.error("Error with auto listening, try again, " +
                    "if persist contact your customer success for assistance.");
                setListening(false);
                setShowDeviceList(false);
            }
        });
    }

    const eventTableHeaders = <TableHeaders checkbox={false} headerData={eventHeaderData} checkAll={() => {}}
                                            writeAccess={true} sortOrder={""}
                                            checkboxHeaderId={"uCheckHead"} sortColumn={""} sorter={{}}
                                            setSorter={() => {}} modal={false} modal_name={"Notes"} checkboxlist={[]}
                                            actionAppearance={() => {}}/>
    const notesTableHeaders = <TableHeaders checkbox={false} headerData={noteHeaderData}
                                            checkAll={() => {}} writeAccess={editRights} sortOrder={"asc"}
                                            sortColumn={"create_time"} sorter={{}}
                                            setSorter={() => {}} modal={false} checkboxlist={[]}
                                            actionAppearance={() => {}}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Area Profile"} Title={areaName} content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"col"}>
                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                            <li key={"set-basic"} className={"nav-item"}>
                                <Button text={"Basic Info"} class={tabItem === "basic" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("basic")}/>
                            </li>
                            <li key={"set-devices"} className={"nav-item"}>
                                <Button text={"Devices"} class={tabItem === "devices" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("devices")}/>
                            </li>
                            <li key={"set-reporting"} className={"nav-item"}>
                                <Button text={"Reporting"}
                                        class={tabItem === "reporting" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("reporting")}/>
                            </li>
                            <li key={"set-wellness"} className={"nav-item"}>
                                <Button text={"Wellness"}
                                        class={tabItem === "wellness" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("wellness")}/>
                            </li>
                        </ul>
                    </div>
                    <div className={tabItem === "basic" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "550px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                       onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                                <form className={"d-inline align-top form-control-plaintext was-validated"}
                                      id={"basic-form"}>
                                    <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "75px"}}>Name:</p>
                                            <p className={"ms-4"} id={"name-static"}>{areaName}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   required={true}
                                                   style={{height: "30px", width: "125px"}} id={"name-edit"}
                                                   defaultValue={areaName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        </div>
                                        <div className={"mt-2 d-flex"}>
                                            <p style={{minWidth: "75px"}}>Type:</p>
                                            <p className={"ms-4"} id={"area-type-static"}>{areaType}</p>
                                            <select className={"ms-4 d-none form-select form-select-sm"}
                                                    onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                    style={{height: "30px", width: "256px"}} id={"area-type-edit"}
                                                    defaultValue={areaTypeId}>
                                                {areaTypeOptions}
                                            </select>
                                        </div>
                                        <div className={"mt-2 d-flex"}>
                                            <p style={{minWidth: "75px"}}>Capacity:</p>
                                            <p className={"ms-4"} id={"occupancy-static"}>{maxOccupancy}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   type={"number"}
                                                   style={{height: "30px", width: "256px"}} id={"occupancy-edit"}
                                                   defaultValue={maxOccupancy} step={1} max={100} min={0}
                                                   onChange={() => formCheck("basic-form", "save-basic-btn")}/>
                                        </div>
                                        <div className={"mt-2 d-flex"}>
                                            <ul className={"ps-0 d-inline-block align-top mb-0"}
                                                style={{listStyle: "none"}}>
                                                <li><p style={{minWidth: "75px"}} className={"me-4"}>Residents:</p></li>
                                            </ul>
                                            <ul className={"ps-0 d-inline-block mb-0"} style={{listStyle: "none"}}>
                                                <li>{residents}</li>
                                            </ul>
                                        </div>
                                        <div className={"mt-2 d-flex"}>
                                            <ul className={"ps-0 d-inline-block align-top mb-0"}
                                                style={{listStyle: "none"}}>
                                                <li><p style={{minWidth: "75px"}} className={"me-4"}>Groups:</p></li>
                                            </ul>
                                            <ul className={"ps-0 d-inline-block mb-0"} style={{listStyle: "none"}}>
                                                {areaGroups.map((group, i) => {
                                                    return <li
                                                        key={`area-group-name-item-${i}`}>{group?.area_group_name}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                                {pageLoaded && editRights &&
                                    <>
                                        <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                                disabled={!editRights} onClick={() => {
                                            if (editRights) {
                                                basicActionHandling();
                                            }
                                        }}/>
                                        <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"}
                                                id={"save-basic-btn"}
                                                disabled={!editRights} onClick={() => {
                                            if (editRights) {
                                                basicSubmit();
                                            }
                                        }}/>
                                        <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"}
                                                disabled={true}
                                                spinner={true} text={""} id={"load-basic-btn"}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "devices" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}
                                        style={{minWidth: "80px"}}>Devices</h3>
                                    <div className={"d-none"} style={{display: "inline-block"}}
                                         id={"devices-header-data"}>
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}>{devices.length} {devices?.length === 1 ? "Device" : "Devices"}</h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"devices-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("devices-card-btn", "devices-card");
                                           document.getElementById("devices-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"devices-card"}>
                                <ul className={"d-inline-block"} style={{listStyle: "none"}}>
                                    {devices}
                                </ul>
                                {devEditRights &&
                                    <Button text={"Attach"} class={"btn btn-primary float-end me-3"}
                                            id={"device-modal-btn"}
                                            disabled={!devEditRights}
                                            onClick={() => {
                                                if (devEditRights) {
                                                    setDeviceCatId(0);
                                                    setDeviceTypeId(0);
                                                    setModelId(0);
                                                    setListening(false);
                                                    setShowDeviceList(false);
                                                    setListenDevice("");
                                                    setListenData([]);
                                                    setListenList([]);
                                                    formCheck("pendantForm", "pendantSubmit");
                                                    showModal("pendantmodal");
                                                }
                                            }}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "reporting" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"} style={{minWidth: "80px"}}>
                                        Reporting
                                    </h3>
                                    <div className={"d-none"} style={{display: "inline-block"}}
                                         id={"reporting-header-data"}>
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}>{!isNaN(hourData?.avgValue) ? hourData.avgValue : "?"} Alert/Week</h3>
                                        <h3 className={"mx-4 my-0 d-inline-block text-success"}>{!isNaN(weekData?.avgValue) ? weekData.avgValue : "?"} Avg/Resp</h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"reporting-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("reporting-card-btn", "reporting-card")
                                           document.getElementById("reporting-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"reporting-card"}>
                                <div className={"row"}>
                                    <Link to={"/reports/alerts/run"} onClick={() => {
                                        setAreaFilters(dData, areaId, areaName);
                                    }}>
                                        <Button text={"Run Detailed Report"} class={"btn btn-primary mb-4 float-end"}
                                                icon={true} iconClass={"fe fe-arrow-right-circle float-end ms-3"}/>
                                    </Link>
                                </div>
                                <div className={"row"}>
                                    <div className={"chart-container w-50 text-end align-top d-inline-block"}>
                                        {hourDataLoaded
                                            ?
                                            (<div className={"chart-container"}>
                                                <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                        id={"perhour"}></canvas>
                                            </div>)
                                            :
                                            (<div className={"text-center"}>
                                                <Button text={""} class={"border-0 bg-transparent btn"}
                                                        spinner={true}></Button>
                                            </div>)
                                        }
                                    </div>
                                    <div className={"chart-container w-50 text-center align-top d-inline-block"}>
                                        {weekDataLoaded
                                            ?
                                            (<div className={"chart-container"}>
                                                <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                        id={"perweek"}></canvas>
                                            </div>)
                                            :
                                            (<div className={"text-center"}>
                                                <Button text={""} class={"border-0 bg-transparent btn"}
                                                        spinner={true}></Button>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div className={"row mt-5"}>
                                    <div className={"w-50 px-4"}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead>
                                            <tr>
                                                <th className={"table table-header w-auto py-1"} colSpan={"4"}>
                                                    Event Reason Breakdown
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Reason
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Total
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    % Total Volume
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Average Response (min)
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"ps-1 fw-bold"}>Total Events</td>
                                                <td className={"ps-1 fw-bold text-center"}>{reasonData?.count ? reasonData.count : 0}</td>
                                                <td className={"ps-1 text-right"}></td>
                                                <td className={"ps-1 fw-bold text-center"}>{reasonData?.avg_response_time ? reasonData.avg_response_time : 0.0}</td>
                                            </tr>
                                            {reasonRows}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"w-50"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover d-sm-table"}>
                                            <thead className={"sticky-table-head"} style={{top: 0, zIndex: 1}}>
                                            {eventTableHeaders}
                                            </thead>
                                            <tbody>
                                            {eventTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "wellness" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}
                                        style={{minWidth: "80px"}}>Wellness</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"wellness-card-btn"}
                                       onClick={() => toggleCollapse("wellness-card-btn", "wellness-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"wellness-card"}>
                                <div className={"ms-4"}>
                                    <div className={"d-block"}>
                                        <div className={"d-inline-block align-top me-4"}
                                             style={{minWidth: "125px"}}>Careflow Plan:
                                        </div>
                                        <div className={"d-inline-block"}>{careflow ? careflow : "No Careflow"}</div>
                                    </div>
                                    <div className={"d-block mt-3"}>
                                        <div className={"d-inline-block align-top me-4"}
                                             style={{minWidth: "125px"}}>Rounds:
                                        </div>
                                        <div
                                            className={"d-inline-block"}>{rounds && rounds?.length > 0 ? rounds : "No Rounds Needed"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceBackdropLabel"}>Add Device</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"pendantForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3 d-inline-block me-2"} style={{width: "68%"}}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_category"}
                                            onChange={(e) => {
                                                setDeviceCatId(parseInt(e.target.value));
                                                setModelId(0);
                                                setDeviceTypeId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {deviceCatOptions}
                                    </select>
                                    <label htmlFor={"device_category"}>Device Category</label>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3 d-inline-block me-2"} style={{width: "68%"}}>
                                        <select className={"form-select"} value={undefined}
                                                required={true} id={"device_type_id"}
                                                onChange={(e) => {
                                                    setDeviceTypeId(parseInt(e.target.value));
                                                    setDeviceTypeName(e.target[e.target.selectedIndex].innerText);
                                                    areaLearninBehaviorToggle(e.target.value);
                                                    formCheck("pendantForm", "pendantSubmit");
                                                }}>
                                            <option value={""}></option>
                                            {deviceTypeOptions}
                                        </select>
                                        <label htmlFor={"device_type_id"}>Device Type</label>
                                        <div className={deviceImg ? "select-img-div" : "select-img-div-nohover"}>
                                            {deviceImg && <img
                                                src={`${process.env.PUBLIC_URL}/static/image/${deviceImg}`}
                                                alt={""}
                                                className={"mx-auto"}
                                                style={{
                                                    width: "inherit",
                                                    height: "inherit",
                                                    padding: "0 10px 6px 0"
                                                }}/>}
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block ms-auto"} style={{width: "30%"}}>
                                        <select className={"form-select"} id={"modelId"} value={modelId}
                                                disabled={!iAmHigherAuthority(80, dData.role_weight)}
                                                onChange={(e) => {
                                                    setModelId(parseInt(e.target.value));
                                                    formCheck("pendantForm", "pendantSubmit");
                                                }}>
                                            {modelOptions}
                                        </select>
                                        <label htmlFor={"modelId"}>Model</label>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"description"}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"description"}>Description (optional)</label>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={`form-floating mb-3 w-75 ${showDeviceList && "d-none"}`}
                                         id={"serialToggle"}>
                                        <input type={"text"} className={"form-control"} id={"serialNum"}
                                               required={!showDeviceList}
                                               onKeyUp={(e) => formCheckWithElement("pendantForm", "pendantSubmit", e, areaDeviceData)}
                                               pattern={"^[0-9]+$"}/>
                                        <label htmlFor={"serialNum"}>Serial Number</label>
                                    </div>
                                    <div className={`form-floating mb-3 w-75 ${!showDeviceList && "d-none"}`}
                                         id={"serial2Toggle"}>
                                        <select className={"form-select"} id={"serialNum2"} required={showDeviceList}
                                                onClick={(e) => {
                                                    let tmp = e.target.value.split(",");
                                                    setListenDevice(tmp[0]);
                                                    setListenPCode(parseInt(tmp[1]));
                                                }}>
                                            {listenList}
                                        </select>
                                        <label htmlFor={"serialNum2"}>Serial Numbers</label>
                                    </div>
                                    <Button text={"Auto Listen"} id={"listen-btn-1"}
                                            class={`btn ${listenEnabled ? "btn-primary" : "btn-danger"} m-auto ${showDeviceList && "d-none"}`}
                                            disabled={!listenEnabled} onClick={() => {
                                        setListening(true);
                                        setShowDeviceList(true);
                                        publishListen()
                                    }}/>
                                    <Button text={"Stop Listen"} id={"listen-btn-2"}
                                            class={`btn ${listening ? "btn-success" : "btn-warning"} m-auto ${!showDeviceList && "d-none"}`}
                                            disabled={!listenEnabled} onClick={() => {
                                        setListening(false);
                                        setShowDeviceList(false);
                                    }}/>
                                </div>
                                <div id={"ncsettings"} className={"d-none"}>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"number"} className={"form-control"} id={"nid"}
                                               required={false} min={0} max={31} step={1} onChange={() => {
                                            formCheck("pendantForm", "pendantSubmit");
                                        }}/>
                                        <label htmlFor={"serialNum"}>Network ID</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter a valid network id for coordinator. Valid 0 - 31
                                        </div>
                                    </div>
                                </div>
                                <div className={`list-group-item ${!showAlarmBehaviors && 'd-none'}`}
                                     id={"behavior-heading-container"}>
                                    <h4 className={"mb-4 text-center"}>Alarm Behavior</h4>
                                    {useOptions}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPendantBtn"}
                                            data-bs-dismiss={"modal"}
                                            onClick={() => {
                                                formClear("pendantForm", "pendantSubmit")
                                            }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPendantSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantSubmit"}
                                            onClick={() => pendantSubmit(true)}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticPendantConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticPendantConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Device is already linked to {assignedArea} Do you wish to re-assign this device?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"pendantConfirmCancelBtn"}
                                    onClick={() => swapModal("pendantConfirmClose", "pendantmodal")}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadPendantConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantConfirmSubmit"}
                                    onClick={() => pendantSubmit(false)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deviceconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"deviceConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to permanently detach this device from {areaName}?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"deviceCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadDeviceConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deviceConfirmSubmit"}
                                    onClick={() => removeDevice(deviceToRemove)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addnotemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticAddNoteBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticAddNoteBackdropLabel"}>Add
                                Note</h2>
                            <button type={"button"} className={"btn-close"} id={"addNoteClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"addNoteForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"my-2 form-floating"}>
                                    <textarea className={"form-control pt-5"} id={"noteE"} required={true}
                                              onKeyUp={() => formCheck("addNoteForm", "addNoteSubmit")}>

                                    </textarea>
                                    <label htmlFor={"noteE"} className={"form-label"}>Note</label>
                                    <div className={"invalid-feedback"}>
                                        Note required
                                    </div>
                                </div>
                                {noteTableLength > 0
                                    ? <>
                                        <hr/>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                                            {notesTableHeaders}
                                            </thead>
                                            <tbody id={"tBody"}>
                                            {noteTableRows}
                                            </tbody>
                                        </table>
                                    </>
                                    : <></>
                                }
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddNoteBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddNoteSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addNoteSubmit"}
                                            onClick={() => noteSubmit()}>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default AreaProfile;