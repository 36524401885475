import Button from "./Button";
import {reloadSessionCorp, setRoleInfo} from "../../libs";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Link} from "react-router-dom";
import MaterialIcon from "material-icons-react";

const ImpersonateBanner = (props) => {

    let dData, bData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    function stopImpersonate(){
        setRoleInfo(dData.my_role_weight, dData);
        reloadSessionCorp(dData.myCorp, -99, dData.cognito, dData, dData.myAcnt);
    }

    return (
        <div style={{height: "55px", backgroundColor: "#fb4a4a", boxShadow: "0 1px 4px"}}
             className={"text-center pt-3"}>
            <Link to={`/profiles/campus/${dData.acnt}`}>
                <MaterialIcon size={"small"} className={"material-icons align-middle icon-hover-white"}
                              icon={"business"} title={"Campus profile"}/>
            </Link>
            <h4 className={"d-inline-block mb-0 mx-3 text-light"}>Currently viewing <span
                className={"fw-bold text-light"}>{dData.campusData[dData.acnt]?.name}</span></h4>
            <Button text={"Stop Impersonating"} class={"d-inline-block py-1 ms-3 btn btn-secondary"}
                    onClick={() => stopImpersonate()}/>
        </div>
    );
};

export default ImpersonateBanner;