import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import UserManagement from "./UserManagement";
import UserGroups from "./UserGroups";
import LegacyGroups from "./LegacyGroups";
import LegacyUserManagement from "./LegacyUserManagement";
import SessionReload from "./component-assets/SessionReload";
import {compareRole, iAmHigherAuthority} from "../libs";

const Users = (props) => {
    let pageContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData.migrated){
        if (paramVars?.dType === "groups"){
            pageContent = <UserGroups />
        }else if (paramVars?.dType === "usermanagement"){
            pageContent = <UserManagement />
        }else{
            pageContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }else{
        if (paramVars?.dType === "groups"){
            pageContent = <LegacyGroups />;
        }else if (paramVars?.dType === "usermanagement"){
            pageContent = <LegacyUserManagement />
        }else{
            pageContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }

    return (compareRole("caregiver", dData?.userrole) || iAmHigherAuthority(99, dData?.role_weight)) ? (
        <>
            <Navigation />
            {dData?.migrated && <SessionReload />}
            {pageContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Users;