import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import ResidentProfile from "./profile-templates/Resident";
import AreaProfile from "./profile-templates/Area";
import {iAmHigherAuthority} from "../libs";
import ServerProfile from "./profile-templates/Server";
import CorporateProfile from "./profile-templates/Corporation";
import CampusProfile from "./profile-templates/Campus";
import InovonicsDevice from "./profile-templates/InovonicsDevice";
import SessionReload from "./component-assets/SessionReload";
import AccutechDevice from "./profile-templates/AccutechDevice";
import CampusUploadPreview from "./CampusUploadPreview";
import UserProfile from "./profile-templates/User";
import Event from "./profile-templates/Event";

const Profiles = (props) => {
    let pageContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (paramVars.dType === "resident" && iAmHigherAuthority(10, dData?.role_weight)){
        pageContent = <ResidentProfile id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "area" && iAmHigherAuthority(10, dData?.role_weight)){
        pageContent = <AreaProfile id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "inovonics" && iAmHigherAuthority(10, dData?.role_weight)){
        pageContent = <InovonicsDevice id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "accutech" && iAmHigherAuthority(10, dData?.role_weight)){
        pageContent = <AccutechDevice id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "user" && iAmHigherAuthority(10, dData?.role_weight)){
        pageContent = <UserProfile id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "server" && iAmHigherAuthority(99, dData?.role_weight)){
        pageContent = <ServerProfile id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "corporate" && iAmHigherAuthority(99, dData?.role_weight)){
        pageContent = <CorporateProfile id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "event" && iAmHigherAuthority(99, dData?.role_weight)){
        pageContent = <Event id={paramVars?.eType} />;
    }
    else if (paramVars.dType === "campus" && iAmHigherAuthority(99, dData?.role_weight)){
        if (paramVars?.kType !== undefined){
            pageContent = <CampusUploadPreview campus_id={paramVars?.eType} id={paramVars?.kType} />;
        }else{
            pageContent = <CampusProfile id={paramVars?.eType} />;
        }
    }
    else{
        pageContent = (
            <div>
                <h3>Page not found. 404</h3>
            </div>
        );
    }

    return iAmHigherAuthority(10, dData?.role_weight) ? (
        <>
            <Navigation />
            {dData?.migrated && <SessionReload />}
            {pageContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Profiles;