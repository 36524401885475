import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {ToastContainer} from "react-toastify";
import {campusToGuid, getUTCTimestamp, handleSubmitActions, formFail, formSuccess} from "../../libs";

const LegacyAppSystemSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [saveBtn, setSaveBtn] = useState(false);
    const [item1, setItem1] = useState(props?.AlertTone);
    const [item2, setItem2] = useState(props?.CampusTimeZone);
    const [item3, setItem3] = useState(props?.LogOutTimeInterval);

    useEffect(() => {
        let newVal, newPos, span, e;
        span = document.getElementById("rangeSpan1");
        e = document.getElementById("range1")
        newVal = Number((e.value - e.min) * 100 / (e.max - e.min));
        newPos = 10 - (newVal * .175);
        span.style.left = `calc(${newVal}% + (${newPos}px))`
    }, [item3])

    function performSave(){
        let payload, timestamp;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        timestamp = getUTCTimestamp();
        payload = {AlertTone: item1, CampusTimeZone: parseInt(item2), LogOutTimeInterval: parseInt(item3), CampusId: campusToGuid(dData, dData?.acnt),
                    ModifiedBy: dData.username, DateModified: timestamp}
        ApiRequester({reqEndpoint: "save", Tbl: "CampusConfig", PKey: "CampusId", Payload: [payload], CampusId: campusToGuid(dData, dData?.acnt)}).then(data => {
            if(data[0]){
                setSaveBtn(false);
                props?.setGlobalConfigData(prevVal => ({
                    ...prevVal, AlertTone: item1, CampusTimeZone: parseInt(item2),
                    LogOutTimeInterval: parseInt(item3)
                }));
                formSuccess("Saved app settings", "editSubmit", "loadEditSubmit");
            }
            else{
                formFail("Error encountered, save failed", "editSubmit", "loadEditSubmit");
            }
        })
    }

    return (
        <>
            <div className={"card"}>
                <div className={"card-header"}>
                    <h4 className={"card-header-title"}>App Settings</h4>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    {saveBtn && <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary"} onClick={() => performSave()} />}
                </div>
                <div className={"card-body"}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center mb-4"}>
                                <div className={"col"}>
                                    <label htmlFor={"tz"}>Campus Timezone</label>
                                    <select id={"tz"} className={"form-select"} value={item2} onChange={(e) => {
                                        setItem2(e.target.value);
                                        setSaveBtn(true);
                                    }}>
                                        <option value={0} disabled={true}>Select Timezone</option>
                                        <option value={-4}>Atlantic Standard Time (GMT -04:00)</option>
                                        <option value={-5}>Eastern Standard Time (GMT -05:00)</option>
                                        <option value={-6}>Central Standard Time (GMT -06:00)</option>
                                        <option value={-7}>Mountain Standard Time (GMT -07:00)</option>
                                        <option value={-8}>Pacific Standard Time (GMT -08:00)</option>
                                        <option value={-9}>Alaska Standard Time (GMT -09:00)</option>
                                        <option value={-10}>Hawaii-Aleutian Standard Time (GMT -10:00)</option>
                                    </select>
                                </div>
                            </div>
                            <div className={"row align-items-center mb-4"}>
                                <div className={"col"}>
                                    <label htmlFor={"tone"}>Alert Tone</label>
                                    <select id={"tone"} className={"form-select"} value={item1} onChange={(e) => {
                                        setItem1(e.target.value)
                                        setSaveBtn(true);
                                    }}>
                                        <option value={""} disabled={true}>Select Alert Tone</option>
                                        <option value={"Default"}>Default (iOS default)</option>
                                        <option value={"Alarm"}>Alarm</option>
                                        <option value={"Siren"}>Siren</option>
                                        <option value={"Tones"}>Tones</option>
                                        <option value={"Beeps"}>Beeps</option>
                                        <option value={"Slide"}>Slide</option>
                                        <option value={"Klaxon"}>Klaxon</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Automatic Logout Timer</h4>
                                    <p className={"text-muted"}>Timeout length for idle user to logout automatically.</p>
                                    <input className={"form-control form-text range-input"} max={30} min={1} type={"number"} placeholder={item3} onBlur={(e) => {
                                        if (e.target.value >= e.target.min && e.target.value <= e.target.max){
                                            setItem3(parseInt(e.target.value));
                                            setSaveBtn(true);
                                        }
                                        e.target.value = "";
                                    }}/>
                                    <div className={"range-container"}>
                                        <span id={"rangeSpan1"}>{item3} Days</span>
                                        <input id={"range1"} className={"form-range"} type={"range"} min={1} step={1}
                                               max={30} value={item3} onChange={(e) => {
                                                   setItem3(parseInt(e.target.value));
                                                   setSaveBtn(true);
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default LegacyAppSystemSettings;