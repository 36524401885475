import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useState, useEffect, Suspense} from "react";
import ApiRequester from "./ApiRequester";
import {Chart, registerables} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import TableHeaders from "./component-assets/TableHeaders";
import Button from "./component-assets/Button";
import {formSuccess, handleSubmitActions, showModal, getToday, formatDateIso, iAmHigherAuthority, sortFunc, checkAll
} from "../libs";
import TableBody from "./component-assets/TableBody";
import MaterialIcon from "material-icons-react";
import {ToastContainer} from "react-toastify";

const MainDash = () => {

    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    let dData, resHeaders, careHeaders, areaHeaders, startDate, endDate, areaTypeHeaders;
    let colorlist = ["#2C7BE5", "#392CE5", "#05B2FF", "#A6C5F7", "#8989FF", "#D2DDEC", "#E52C7B", "#962CE5", "#E52CD8",
        "#E52C7B", "#E5392C", "#09BA00", "#0CFF00", "#8AFF85", "#AEFF00", "#F2FF00", "#A7B000"];

    if (ReactSession.get("PAL") !== undefined){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }


    resHeaders = [
        {label: "Resident Name", key: "resident_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id", sorts: false}},
        {label: "Total Alarms", key: "total_events", align: "center", sorts: false},
        {label: "Alarms 1-7 Days", key: "week1", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Alarms 7-14 Days", key: "week2", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Alarms 14-21 Days", key: "week3", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Alarms 21-28 Days", key: "week4", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false}
    ];

    careHeaders = [
        {label: "User Name", key: "display_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/user", linkkey: "user_id"}},
        {label: "Total Alarms", key: "total_events", align: "center", sorts: false},
        {label: "Alarms 0-7 Days", key: "week1", align: "center", sorts: false,
            highlight: {enabled: false,
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Alarms 7-14 Days", key: "week2", align: "center", sorts: false,
            highlight: {enabled: false,
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Alarms 14-21 Days", key: "week3", align: "center", sorts: false,
            highlight: {enabled: false,
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Alarms 21-28 Days", key: "week4", align: "center", sorts: false,
            highlight: {enabled: false,
                        useIcon: true, weighted: false, compareKey: "total_events",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false}
    ];

    areaHeaders = [
        {label: "Area Type", key: "area_type_name", sorts: false},
        {label: "Total Alarms", key: "total_events", align: "center", sorts: false},
        {label: "Avg Resp Time 0-7 Days", key: "week1", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "response_time",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Avg Resp Time 7-14 Days", key: "week2", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "response_time",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Avg Resp Time 14-21 Days", key: "week3", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "response_time",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Avg Resp Time 21-28 Days", key: "week4", align: "center", sorts: false,
            highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
                        useIcon: true, weighted: false, compareKey: "response_time",
                        warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
        {label: "Avg Response Time (min)", key: "response_time", align: "center", sorts: false}
    ];

    areaTypeHeaders = [
        {label: "Area Type", key: "area_type_name", align: "left"},
        {label: "Description", key: "area_type_description", align: "left"},
    ];

    startDate = formatDateIso(getToday(-28));
    endDate = formatDateIso(getToday());

    // Data variables
    const [resData, setResData] = useState([]);
    const [careData, setCareData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [artChartData, setARTChartData] = useState([]);
    const [statData, setStatData] = useState([])
    // Table Variables
    const [resRows, setResRows] = useState([]);
    const [careRows, setCareRows] = useState([]);
    const [areaRows, setAreaRows] = useState([]);
    // General page variables
    const [resLoaded, setResLoaded] = useState(false);
    const [areaLoaded, setAreaLoaded] = useState(false);
    const [careLoaded, setCareLoaded] = useState(false);
    const [statLoaded, setStatLoaded] = useState(false);
    const [artChartLoaded, setARTChartLoaded] = useState(false);
    const [reasonLoaded, setReasonLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    // Filter page variables
    const [areaTypeOriginData, setAreaTypeOriginData] = useState([]);
    const [areaTypeTableRows, setAreaTypeTableRows] = useState([]);
    const [areaTypeDataCnt, setAreaTypeDataCnt] = useState(0);
    const [areaTypeIdList, setAreaTypeIdList] = useState([]);
    const [areaTypeSearch, setAreaTypeSearch] = useState("");
    const [areaTypeSorter, setAreaTypeSorter] = useState({
        sortColumn: "area_type_name",
        sortOrder: "asc"
    });

    const [areaTypeFilterList, setAreaTypeFilterList] = useState([]);

    const [tabItem, setTabItem] = useState("areaType");
    const [tabCat, setTabCat] = useState("areas");
    const [filterCount,setFilterCount] = useState(0);


    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardresidentsummary",
                                start_date: startDate, end_date: endDate, active_filter: true,
                                campus_id: campusId, area_type_filter: areaTypeFilterList.join(","),
                                device_category_filter: "1,2,15,16"}).then(data => {
                setResData(data);
                setResLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardcaregiversummary",
                                start_date: startDate, end_date: endDate,
                                campus_id: campusId, area_type_filter: areaTypeFilterList.join(","),
                                device_category_filter: "1,2,15,16"}).then(data => {
                setCareData(data);
                setCareLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardareasummary",
                                start_date: startDate, end_date: endDate,
                                campus_id: campusId, area_type_filter: areaTypeFilterList.join(","),
                                device_category_filter: "1,2,15,16"}).then(data => {
                setAreaData(data);
                setAreaLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardstats",
                                start_date: startDate, end_date: endDate,
                                campus_id: campusId, area_type_filter: areaTypeFilterList.join(","),
                                device_category_filter: "1,2,15,16"}).then(data => {
                setStatData(data?.length > 0 ? data[0] : []);
                setStatLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardreasons",
                                start_date: startDate, end_date: endDate,
                                campus_id: campusId, area_type_filter: areaTypeFilterList.join(","),
                                device_category_filter: "1,2,15,16"}).then(data => {
                setReasonData(data);
                setReasonLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardhourchart",
                                start_date: startDate, end_date: endDate,
                                campus_id: campusId, area_type_filter: areaTypeFilterList.join(","),
                                device_category_filter: "1,2,15,16"}).then(data => {
                setARTChartData(data);
                setARTChartLoaded(true);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setFilterCount(filterCount => filterCount + 1);
        }, 1000);
        if (filterCount <= 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "getportalfilters",
                                campus_id: campusId, area_type_filter: "1"}).then(data => {
                setAreaTypeOriginData(() => data?.area_types?.length > 0 ? data.area_types : []);
            });
            setFilterCount(filterCount => 600);
        }
        return () => clearInterval(interval);
    }, [filterCount]);

    useEffect(() => {
        let i, tmp = [], input;
        input = areaTypeSearch?.toString()?.toLowerCase();
        for (i = 0; i < areaTypeOriginData?.length; i++){
            if ((areaTypeOriginData[i]?.area_type_name && areaTypeOriginData[i]?.area_type_name?.toLowerCase()?.search(input) !== -1) ||
                (areaTypeOriginData[i]?.area_type_description && areaTypeOriginData[i]?.area_type_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(areaTypeOriginData[i]);
            }
        }
        setAreaTypeDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, areaTypeSorter.sortColumn, areaTypeSorter.sortOrder));
        setAreaTypeTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                        actionAppearance={() => {}} tableHeaders={areaTypeHeaders} checkboxId={"uCheckAreaType"}
                                        parentComponent={"EventReports"} dData={dData} checkboxlist={areaTypeIdList}
                                        modal={false} editModal={() => {}} unk={"area-type-x-"}
                                        setCheckboxId={(value) => {setAreaTypeIdList(value)}} checkidkey={"area_type_id"} />);
    }, [areaTypeOriginData, areaTypeSorter, areaTypeIdList, areaTypeSearch]);

    useEffect(() => {
        if (reasonData !== undefined && reasonLoaded && reasonData.values?.length > 0){
            const bcolor = [];
            for (let ix=0; ix <= reasonData?.values?.length; ix++) {
                bcolor.push(colorlist[ix]);
            }
            if (Chart.getChart("perreason") !== undefined){
                let curChart3 = Chart.getChart("perreason");
                curChart3.data.datasets[0].data = reasonData?.values ? reasonData.values : [];
                curChart3.data.labels = reasonData?.labels ? reasonData.labels : [];
                curChart3.data.datasets[0].backgroundColor = bcolor;
                curChart3.update();
            }
            else{
                new Chart('perreason', {
                    type: 'pie',
                    options: {
                        responsive: false,
                        cutout: "0%",
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue}%`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: reasonData?.labels ? reasonData.labels : [],
                        datasets: [{
                            data: reasonData?.values ? reasonData.values : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
        }
    }, [reasonData]);

    useEffect(() => {
        if (artChartData !== undefined && artChartLoaded){
            if (Chart.getChart("perhour") !== undefined){
                const curChart = Chart.getChart("perhour");
                curChart.data.labels = artChartData?.labels ? artChartData.labels : [];
                curChart.data.datasets[0].data = artChartData?.events ? artChartData.events : [];
                curChart.data.datasets[1].data = artChartData?.responses ? artChartData.responses : [];
                curChart.options.plugins.annotation.annotations.line1.value = artChartData?.avgValue ? artChartData.avgValue : 0;
                curChart.options.plugins.annotation.annotations.line2.value = artChartData?.avgResp ? artChartData.avgResp : 0;
                curChart.resize();
                curChart.update();
            }
            else{
                new Chart('perhour', {
                    options: {
                        responsive: true,
                        plugins: {
                            annotation: {
                                drawTime: 'afterDatasetsDraw',
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "a",
                                        borderColor: "#0081C778",
                                        value: artChartData?.avgValue ? artChartData.avgValue : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#0081C7",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Alarm Average: ${artChartData?.avgValue ? artChartData.avgValue : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    },
                                    line2: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "b",
                                        borderColor: "#BE4D2578",
                                        value: artChartData?.avgResp ? artChartData.avgResp : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#BE4D25",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Response Time Average: ${artChartData?.avgResp ? artChartData.avgResp : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    }
                                }
                            },
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Time of the Day",
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            b: {
                                id: "b",
                                position: "right",
                                title: {
                                    display: true,
                                    text: "Average Response Time (min)"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: artChartData?.labels?.length > 0
                            ? artChartData.labels
                            : ["1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00",
                                "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00",
                                "20:00", "21:00", "22:00", "23:00", "24:00"],
                        datasets: [{
                            type: "bar",
                            label: 'Total Alarms',
                            data: artChartData?.events ? artChartData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            yAxisID: "a",
                            order: 2
                        },{
                            type: "line",
                            label: "Average Response Time",
                            data: artChartData?.responses ? artChartData.responses : [],
                            backgroundColor: ["#BE4D25"],
                            hoverBackgroundColor: ["#BE4D2578"],
                            borderColor: ["#BE4D2578"],
                            fill: false,
                            yAxisID: "b",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [artChartData]);

    useEffect(() => {
        if (resData !== undefined){
            setResRows(() => {
                return (<TableBody start={0} end={resData?.records?.length ? resData.records.length : 0}
                                   tableData={resData?.records ? resData.records : []}
                                   checkbox={false} writeAccess={false}
                                   actionAppearance={() => {}} tableHeaders={resHeaders} checkboxId={""}
                                   parentComponent={"MainDash"} dData={dData} checkboxlist={[]}
                                   modal={false} editModal={() => {}} unk={""} setCheckboxId={() => {}} checkidkey={""} />)
            })
        }
    }, [resData]);

    useEffect(() => {
        if (careData !== undefined){
            setCareRows(() => {
                return (<TableBody start={0} end={careData?.records?.length ? careData.records.length : 0}
                                   tableData={careData?.records ? careData.records : []}
                                   checkbox={false} writeAccess={false}
                                   actionAppearance={() => {}} tableHeaders={careHeaders} checkboxId={""}
                                   parentComponent={"MainDash"} dData={dData} checkboxlist={[]}
                                   modal={false} editModal={() => {}} unk={""} setCheckboxId={() => {}} checkidkey={""} />);
            });
        }
    }, [careData]);

    useEffect(() => {
        if (areaData !== undefined){
            setAreaRows(() => {
                return (<TableBody start={0} end={areaData?.records?.length ? areaData.records.length : 0}
                                   tableData={areaData?.records ? areaData.records : []}
                                   checkbox={false} writeAccess={false}
                                   actionAppearance={() => {}} tableHeaders={areaHeaders} checkboxId={""}
                                   parentComponent={"MainDash"} dData={dData} checkboxlist={[]}
                                   modal={false} editModal={() => {}} unk={""} setCheckboxId={() => {}} checkidkey={""} />);
            });
        }
    }, [areaData]);

    function clearFilters(){
        // Function used in filter modal to clear all toggled checkboxes across all tabs.
        setAreaTypeSearch("");
        setAreaTypeIdList([]);
        document.getElementById("uCheckHead2").checked = false;
    }

    function showFilterModal(){
        // Function that preps and populates the filter modal with the checks when called based on stored session.
        setAreaTypeSearch("");
        setAreaTypeIdList(areaTypeFilterList);
        document.getElementById("uCheckHead2").checked = areaTypeFilterList.length === areaTypeOriginData.length;
        showModal("filtermodal2");
    }

    function filterSubmit2(){
        // Function used to set filters to vars on submit from filter modal.
        handleSubmitActions("filterSubmit2", "loadFilterSubmit2");
        setAreaTypeFilterList(areaTypeIdList);
        setCount(() => 0);
        formSuccess("Filters saved", "filterSubmit2", "loadFilterSubmit2", "cancelFilterBtn2");
    }

    function filtersOn(){
        return [areaTypeFilterList].some(filterArray => filterArray.length > 0);
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                <button type={"button"} className={"btn btn-secondary mx-2 mb-2"} style={{height: "46.5px"}}
                        onClick={() => {
                            showFilterModal();
                        }}>
                    <i className={"fe fe-filter fs-3"}/>
                    {filtersOn() && <div className={"filter-caret"}></div>}
                </button>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => {
                setCount(item);
                setFilterCount(item);
            }} pageAction={() => {
            }}/>
        </div>
    );

    const resTableHead = <TableHeaders checkbox={false} headerData={resHeaders} sorts={false} sortOrder={{}}
                                       sortColumn={{}} sorter={{}} setSorter={() => {}} />

    const careTableHead = <TableHeaders checkbox={false} headerData={careHeaders} sorts={false} sortOrder={{}}
                                       sortColumn={{}} sorter={{}} setSorter={() => {}} />

    const areaTableHead = <TableHeaders checkbox={false} headerData={areaHeaders} sorts={false} sortOrder={{}}
                                       sortColumn={{}} sorter={{}} setSorter={() => {}} />

    const areaTypeTableHeaders = <TableHeaders checkbox={true} headerData={areaTypeHeaders} checkboxlist={areaTypeIdList}
                                               checkAll={() => checkAll(0, areaTypeDataCnt ? areaTypeDataCnt : 0, areaTypeIdList, setAreaTypeIdList, "uCheckHead2", "uCheckAreaType")}
                                               writeAccess={true} sortOrder={areaTypeSorter.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                               sortColumn={areaTypeSorter.sortColumn} sorter={areaTypeSorter} setSorter={setAreaTypeSorter}
                                               actionAppearance={() => {}} />

    return (
        <div className={"main-content"}>
            <Header preTitle={"Dashboard"} Title={"Main"} content={headerContent} />
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-lg-4 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Avg Daily Alarms</h6>
                                        {statLoaded
                                            ?
                                            <span className={"h2 mb-0"}>{statData?.total_events ? statData.total_events : 0}</span>
                                            :
                                            <Button type={"button"} text={""} class={"border-0 bg-transparent btn"} spinner={true} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-4 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Avg Response Rate</h6>
                                        {statLoaded
                                            ?
                                            <span className={"h2 mb-0"}>{statData?.response_time ? statData.response_time : 0.0}</span>
                                            :
                                            <Button type={"button"} text={""} class={"border-0 bg-transparent btn"} spinner={true} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-4 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Alarms Over 20 Mins</h6>
                                        {statLoaded
                                            ?
                                            <span className={"h2 mb-0"}>{statData?.excessive_response ? statData.excessive_response : 0.0}</span>
                                            :
                                            <Button type={"button"} text={""} class={"border-0 bg-transparent btn"} spinner={true} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-xl-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Top 10 Resident Review</h4>
                            </div>
                            <div className={"card-body"} style={{overflowX: "auto"}}>
                                {resLoaded
                                    ?
                                    (<>
                                        <table className={"table table-sm table-hover w-100"}>
                                            <thead>
                                                {resTableHead}
                                            </thead>
                                            <tbody>
                                                {resRows}
                                                {resData?.totals
                                                    ?
                                                    resData.totals.map((record) => {
                                                        return (
                                                            <tr key={"resident-total-row"}>
                                                                <td className={"px-1 text-start fw-bold border-light border-top-2"}>Totals</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.total_events ? record.total_events.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week1 ? record.week1.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week2 ? record.week2.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week3 ? record.week3.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week4 ? record.week4.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.response_time ? record.response_time.toLocaleString() : "0"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                                }
                                            </tbody>
                                        </table>
                                        <div>
                                            <div className={"d-inline-block me-3"}>
                                                <MaterialIcon size={"small"}
                                                              className={"material-icons ps-2 md-18 text-warning align-text-bottom d-inline-block"}
                                                              icon={"radio_button_checked"}/>
                                                <h3 className={"d-inline-block ms-2 mb-1"}>High</h3>
                                                <h5 className={"d-block ms-2 text-center fst-italic"}>*More than 30</h5>
                                            </div>
                                            <div className={"d-inline-block me-3"}>
                                                <MaterialIcon size={"small"}
                                                              className={"material-icons ps-2 md-18 text-risk align-text-bottom d-inline-block"}
                                                              icon={"radio_button_checked"}/>
                                                <h3 className={"d-inline-block ms-2 mb-1"}>Very High</h3>
                                                <h5 className={"d-block ms-2 text-center fst-italic"}>*More than 50</h5>
                                            </div>
                                            <div className={"d-inline-block me-3"}>
                                                <MaterialIcon size={"small"}
                                                              className={"material-icons ps-2 md-18 text-danger align-text-bottom d-inline-block"}
                                                              icon={"radio_button_checked"}/>
                                                <h3 className={"d-inline-block ms-2 mb-1"}>Extremely High</h3>
                                                <h5 className={"d-block ms-2 text-center fst-italic"}>*More than 70</h5>
                                            </div>
                                        </div>
                                    </>)
                                    :
                                    (<div className={"text-center"}>
                                        <Button text={""} class={"border-0 bg-transparent btn"} spinner={true}></Button>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Top 10 Caregiver Review</h4>
                            </div>
                            <div className={"card-body"} style={{overflowX: "auto"}}>
                                {careLoaded
                                    ?
                                    (<>
                                        <table className={"table table-sm table-hover w-100"}>
                                            <thead>
                                                {careTableHead}
                                            </thead>
                                            <tbody>
                                                {careRows}
                                                {careData?.totals
                                                    ?
                                                    careData.totals.map((record) => {
                                                        return (
                                                            <tr key={"caregiver-total-row"}>
                                                                <td className={"px-1 text-start fw-bold border-light border-top-2"}>Totals</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.total_events ? record.total_events.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week1 ? record.week1.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week2 ? record.week2.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week3 ? record.week3.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week4 ? record.week4.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.response_time ? record.response_time.toLocaleString() : "0"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                                }
                                            </tbody>
                                        </table>
                                    </>)
                                    :
                                    (<div className={"text-center"}>
                                        <Button text={""} class={"border-0 bg-transparent btn"} spinner={true}></Button>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-xl-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Response Times by Areas</h4>
                            </div>
                            <div className={"card-body"} style={{overflowX: "auto"}}>
                                {areaLoaded
                                    ?
                                    (<>
                                        <table className={"table table-sm table-hover w-100"}>
                                            <thead>
                                                {areaTableHead}
                                            </thead>
                                            <tbody>
                                                {areaRows}
                                                {areaData?.totals
                                                    ?
                                                    areaData.totals?.map((record) => {
                                                        return (
                                                            <tr key={"area-total-row"}>
                                                                <td className={"px-1 text-start fw-bold border-light border-top-2"}>Totals</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.total_events ? record.total_events.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week1 ? record.week1.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week2 ? record.week2.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week3 ? record.week3.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.week4 ? record.week4.toLocaleString() : "0"}</td>
                                                                <td className={"px-1 text-center fw-bold border-light border-top-2"}>{record?.response_time ? record.response_time.toLocaleString() : "0"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                                }
                                            </tbody>
                                        </table>
                                    </>)
                                    :
                                    (<div className={"text-center"}>
                                        <Button text={""} class={"border-0 bg-transparent btn"} spinner={true}></Button>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Alarms by Reason</h4>
                            </div>
                            <div className={"card-body text-center"}>
                                <div className={"card-body text-center"}>
                                    {!reasonLoaded ? (
                                        <div className={"text-center"}>
                                            <Button text={""} class={"border-0 bg-transparent btn"} spinner={true} />
                                        </div>
                                    ) : reasonData?.values?.length > 0 ? (
                                        <div className={"chart-container"}>
                                            <canvas className={"chart-canvas d-inline-block"} style={{ width: "400px", height: "400px" }} id={"perreason"} />
                                        </div>
                                    ) : (
                                        <div className={"text-center"}>
                                            <h4>Reasons not currently being used</h4>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"} style={{width: "calc(100%)", marginLeft: 0}}>
                    <div className={"card"}>
                        <div className={"card-header"}>
                            <h4 className={"card-header-title"}>Total Alarms and Response Time (min) by Hour</h4>
                        </div>
                        <div className={"card-body"}>
                            {artChartLoaded
                                ?
                                (<div className={"chart-container"}>
                                    <canvas className={"chart-canvas p-1"} style={{maxHeight: "450px"}} id={"perhour"}> </canvas>
                                </div>)
                                :
                                (<div className={"text-center"}>
                                    <Button text={""} class={"border-0 bg-transparent btn"} spinner={true}></Button>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
                <div className={"footer-spacer"}>

                </div>
            </div>
            <div className={"modal fade"} id={"filtermodal2"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Filter Settings</h2>
                            <Button text={"Clear All"} class={"btn ms-3 pb-0 hover-text-white"} onClick={() => clearFilters()} />
                            <button type={"button"} className={"btn-close"} id={"filterClose2"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"filterForm2"}>
                            <div className={"modal-body"}>
                                <div className={"row align-items-center"} style={{borderBottom: "dotted 1px #b7b7b7"}}>
                                    <div className={"col"}>
                                        <ul className={"nav nav-tabs nav-overflow header-tabs mb-0"}>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Areas"} class={tabCat === "areas" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("areas");
                                                            setTabItem("areaType");
                                                        }} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={"mt-2"}>
                                    <div>
                                        {tabCat === "areas"
                                            ?   <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                    <li className={"nav-item"}>
                                                        <Button text={"Area Types"} class={tabItem === "areaType" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("areaType")} />
                                                    </li>
                                                </ul>
                                            :   <></>
                                        }
                                    </div>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"areaTypeSearch"} className={"form-control search-input"}
                                               value={areaTypeSearch}
                                               onChange={(e) => setAreaTypeSearch(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <table className={"table table-sm table-white table-hover"}
                                           style={{maxHeight: "400px", overflowY: "auto", display: "block"}}>
                                        <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                            {areaTypeTableHeaders}
                                        </thead>
                                        <tbody>
                                            {areaTypeTableRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelFilterBtn2"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadFilterSubmit2"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"filterSubmit2"} onClick={() => filterSubmit2()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
            <Footer left={<h4 className={"mt-2 me-auto"}>* Stats based on 28 days, including only pendant & push/pull cord alerts {"<"} 60 minutes</h4>} />
        </div>
    );
};

export default MainDash;