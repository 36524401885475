import Button from "./Button";
import {columnSorter} from "../../libs";
import {toast} from "react-toastify";

const TableHeaders = (props) => {
    let returnData = [];

    if (props?.checkbox){
        returnData.push(
            <th className={"table table-header py-0 border-top-0"} style={{width: "70px"}} key={"header-box"}>
                <div className={"form-check"} onClick={() => props?.writeAccess ? {} : toast.warn("Edit privileges are disabled.")}
                     title={props?.writeAccess ? "" : "Not authorized to make changes"}>
                    {props?.writeAccess
                        ? <input className={"form-check-input table-checkbox"} defaultChecked={false} type={"checkbox"}
                                 id={props?.checkboxHeaderId ? props.checkboxHeaderId : "uCheckHead"}
                           onChange={() => {
                               props?.checkAll();
                               props?.actionAppearance();
                           }} />
                        : <input className={"form-check-input table-checkbox"} type={"checkbox"} id={props?.checkboxHeaderId ? props.checkboxHeaderId : "uCheckHead"}
                           disabled={true} />}
                    <label className={"form-check-label"} htmlFor={props?.checkboxHeaderId ? props.checkboxHeaderId : "uCheckHead"}> </label>
                </div>
            </th>
        )
    }
    if (props?.modal){
      returnData.push(
            <th className={"table table-header py-0 border-top-0 text-start fw-normal text-center"} style={{width: "70px"}} key={"header-edit-box"}>
                {props?.modal_name ? props.modal_name : ""}
            </th>
        )
    }
    if (props?.actionIcon){
      returnData.push(
            <th className={"table table-header p-0 border-top-0"} style={{width: "50px"}} key={"header-action-box"}>
                {props?.action_name ? props.action_name : ""}
            </th>
        )
    }
    for (let i=0; i<props?.headerData?.length; i++){
        if (props?.headerData[i]?.sorts === false){
            returnData.push(
                <th className={`table table-header w-auto py-0 border-top-0 px-1`} key={`header-${props?.checkboxHeaderId}-${i}`}
                    style={props?.headerData[i]?.borderLeft ? {borderLeftColor: "#0081C7", borderLeftWidth: "6px"} : {}}>
                    <Button text={props.headerData[i].label} class={"btn btn-header"} id={`${props?.checkboxHeaderId}-${i}`}
                            style={{cursor: "unset", pointerEvents: "none"}}/>
                </th>
            )
        }else{
            returnData.push(
                <th className={`table table-header w-auto py-0 border-top-0 px-1`} key={`header-${props?.checkboxHeaderId}-${i}`}
                    style={props?.headerData[i]?.borderLeft ? {borderLeftColor: "#0081C7", borderLeftWidth: "6px"} : {}}>
                    <Button text={props.headerData[i].label} class={"btn btn-header"} icon={props?.sortColumn === props.headerData[i].key}
                            iconClass={props?.sortOrder === "desc" ? "fe fe-chevron-down" : "fe fe-chevron-up"} id={`${props?.checkboxHeaderId}-${i}`}
                            onClick={() => columnSorter(props.headerData[i].key, props?.sorter, props?.setSorter)} />
                </th>
            )
        }
    }

    return (
        <tr>
            {returnData}
        </tr>
    )
};

export default TableHeaders;