import Button from "./component-assets/Button";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {iAmHigherAuthority} from "../libs";
import logo from "../img/PCLC.svg";
import ImpersonateBanner from "./component-assets/ImpersonateBanner";
import ShowMeBanner from "./component-assets/ShowMeBanner";
import UpdateHeaderBanner from "./component-assets/UpdateHeaderBanner";
import BetaBanner from "./component-assets/BetaBanner";


const Header = (props) => {

    let buttonItem, content, msgItem, bData, dData, banner, spacer;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (props.Refresh === true){
        buttonItem = (<div>
            <Button text={"Refresh"} type={"button"} class={"btn px-4 btn-toolbar btn-primary me-4"} onClick={() => props.setCount(0)}/>
        </div>);
    }
    else{
        buttonItem = (<div className={{"display": "none"}}></div>);
    }
    if (props.msg !== ""){
        msgItem = (<div style={{"display": "inline-block", "marginRight": "10px"}}>{props.msg}</div>);
    }
    else{
        msgItem = (<div className={{"display": "none"}}></div>);
    }

    if (props?.content){
        content = props.content;
    }else{
        content = (
            <div className={"col-auto"} style={{"display": "flex"}}>
                {buttonItem}
                {msgItem}
                <div style={{"display": "inline-block"}}>{props.element}</div>
            </div>
        );
    }

    if (dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString()){
        banner = <ImpersonateBanner />;
        spacer = "header-spacerX";
    }else if ((false) || process.env.REACT_APP_BETA === true){
        banner = <BetaBanner />
        spacer = "header-spacerX";
    }else if ((false) || (dData?.myVersion && dData?.myVersion !== "" && dData?.myVersion < process.env.REACT_APP_VERSION)){
        banner = <ShowMeBanner />;
        spacer = "header-spacerX";
    }else if ((false) || (iAmHigherAuthority(10, dData.role_weight) && dData?.serverVersion &&
              dData?.serverVersion !== "" && dData?.serverVersion < dData?.newestVersion)){
        banner = <UpdateHeaderBanner />;
        spacer = "header-spacerX";
    }else{
        banner = <></>;
        spacer = "header-spacer";
    }

    return (
        <>
            <div className={"header"}>
                {banner}
                <div className={"container-fluid"}>
                    <div className={"header-body"}>
                        <div className={"d-flex justify-content-between"}>
                            <div>
                                <h6 className={"header-pretitle"}>{props.preTitle}</h6>
                                <h1 className={"header-title mt-3"}>{props.Title}</h1>
                            </div>
                            {props?.fullscreen && <div style={{"width": "15rem"}}>
                                <img src={logo} alt={"Palcare Logo Dark Varient"} />
                            </div>
                            }
                            {content}
                        </div>
                        {props?.Navigation ? props.Navigation : ""}
                    </div>
                </div>
            </div>
            <div className={spacer}></div>
        </>
    );
};

Header.defaultProps = {
    preTitle: "",
    Title: "",
    msg: "",
    element: <div></div>
};

export default Header;