import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "../component-assets/Button";
import {
    checkAll,
    formCheck,
    formClear,
    formFail,
    formSuccess,
    handleSubmitActions,
    showModal,
    sortFunc
} from "../../libs";
import TableBody from "../component-assets/TableBody";
import ApiRequester from "../ApiRequester";
import TableHeaders from "../component-assets/TableHeaders";
import {ToastContainer} from "react-toastify";

const BatterySettings = (props) => {

    let dData, deviceHeaderData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    deviceHeaderData = [
        {label: "Name", key: "device_name", align: "left",
            link: {enabled: true, type: " ", linkto: "/profiles/inovonics", linkkey: "device_id",
                    inmodal: true, modalKey: "wiz4-2CancelBtn"}},
        {label: "Category", key: "device_category", align: "left"},
        {label: "Description", key: "descriptions", align: "left"},
        {label: "TX ID", key: "manufacture_uuid"},
        {label: "Connected To", key: "entity_name", align: "left",
            link: {enabled: true, type: "conditional", inmodal: true, modalKey: "wiz4-2CancelBtn", conditionKey: "is_area_device",
                condition: {"1": {linkto: "/profiles/area", linkkey: "area_id"},
                            "0": {linkto: "/profiles/resident", linkkey: "resident_id"}}}}
    ];

    const envData = props?.data ? props.data : [];

    // Main Needed data for page
    const [loadState, setLoadState] = useState("battery");
    const [environContent, setEnvironContent] = useState(<></>);
    const [editRecords, setEditRecords] = useState([]);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [controlId, setControlId] = useState(0);
    const [rowIndex, setRowIndex] = useState(0);
    const [deleteId, setDeleteId] = useState(0);

    // Device picker table variables
    const [deviceData, setDeviceData] = useState(props?.deviceData ? props.deviceData : []);
    const [deviceIdList, setDeviceIdList] = useState([]);
    const [oldDeviceIdList, setOldDeviceIdList] = useState([]);
    const [deviceDataCnt, setDeviceDataCnt] = useState(0);
    const [deviceTableRows, setDeviceTableRows] = useState([]);
    const [search, setSearch] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "device_name",
        sortOrder: "asc"
    });

    useEffect(() => {
        let i, tmp = [], input;
        input = search?.toString()?.toLowerCase();
        for (i = 0; i < deviceData?.length; i++){
            if ((deviceData[i]?.device_name && deviceData[i].device_name?.toLowerCase()?.search(input) !== -1) ||
                (deviceData[i]?.entity_name && deviceData[i].entity_name?.toLowerCase()?.search(input) !== -1) ||
                (deviceData[i]?.descriptions && deviceData[i].descriptions?.toLowerCase()?.search(input) !== -1)){
                tmp.push(deviceData[i]);
            }
        }
        setDeviceDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        setDeviceTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={deviceHeaderData} checkboxId={"uCheckDev"}
                                   parentComponent={"EnvironmentalSettings"} dData={deviceData} checkboxlist={deviceIdList}
                                   modal={false} editModal={() => {}} unk={"x1-"}
                                   setCheckboxId={(value) => {setDeviceIdList(value)}} checkidkey={"device_id"} />);
    }, [deviceData, sorter, deviceIdList, search]);

    function performAddSave(){
        let payload, lowenv, highenv, alarmOn, testInterval, description;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        description = document.getElementById("add-description").value;
        lowenv = parseInt(document.getElementById("add-lowbattery").value);
        highenv = parseInt(document.getElementById("add-highbattery").value);
        alarmOn = document.getElementById("add-alarm").checked ? 1 : 0;
        testInterval = parseInt(document.getElementById("add-interval").value) * 60;
        payload = [{
            description: description, low_alarm: lowenv, high_alarm: highenv, environmental_alarm_type_id: 4,
            alarm_on: alarmOn, campus_id: campusId, environmental_type_id: 2, test_after: testInterval,
            device_ids: deviceIdList.length > 0 ? deviceIdList.join(",") : "", modified_by: dData.user
        }];
        ApiRequester({reqEndpoint: "mysave",  Tbl: "core_environmental_monitoring_control", Payload: payload, urlType: "PUT",
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                props.setCount(0);
                formSuccess("Battery control setting added.", "addSubmit", "loadAddSubmit",
                    "cancelAddBtn", "addForm");
            }
            else{
                formFail("Error encountered, save failed.", "addSubmit", "loadAddSubmit");
            }
        });
    }

    function performSave(){
        let payload = [], testInterval, oldPayload = [];
        handleSubmitActions("editSubmit", "loadEditSubmit");
        for (let i=0; i < editRecords.length; i++){
            testInterval = document.getElementById(`interval${editRecords[i]}`).value;
            testInterval = testInterval * 60
            payload.push(
                {
                    control_id: envData[parseInt(editRecords[i])].control_id,
                    description: document.getElementById(`description${editRecords[i]}`).value,
                    low_alarm: parseInt(document.getElementById(`lowbattery${editRecords[i]}`).value),
                    high_alarm: parseInt(document.getElementById(`highbattery${editRecords[i]}`).value),
                    alarm_on: document.getElementById(`alarm${editRecords[i]}`).checked ? 1 : 0,
                    campus_id: campusId,
                    test_after: testInterval,
                    modified_by: dData.user
                }
            );
            oldPayload.push({
                controlId: envData[parseInt(editRecords[i])].control_id,
                descriptions: envData[parseInt(editRecords[i])].description,
                low_alarm: envData[parseInt(editRecords[i])].low_alarm,
                high_alarm: envData[parseInt(editRecords[i])].high_alarm,
                alarm_on: envData[parseInt(editRecords[i])].alarm_on,
                campus_id: envData[parseInt(editRecords[i])].campus_id,
                test_after: envData[parseInt(editRecords[i])].test_after,
            });
        }
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_environmental_monitoring_control", Payload: payload, urlType: "POST",
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId, OldPayload: oldPayload,
                            PKey: "control_id", Condition: "primary"}).then(data => {
            if (data && data[0]){
                props.setCount(0);
                formSuccess("Battery control settings updated.", "editSubmit", "loadEditSubmit");
                setEditRecords(() => []);
                document.getElementById("editSubmit").classList.add("d-none");
            }
            else{
                formFail("Error encountered, save failed.", "editSubmit", "loadEditSubmit");
            }
        });
    }

    function deleteSubmit(){
        let payload;
        handleSubmitActions("deleteConfirmSubmit", "loadDeleteConfirmSubmit");
        payload = [{control_id: deleteId, modified_by: dData.user}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_environmental_monitoring_control", Payload: payload, urlType: "DELETE",
                            PKey: "control_id", User: dData.user, Condition: "primary", CampusId: dData.acnt}).then(data => {
            if (data && data[0]){
                formSuccess("Battery control setting has been deleted.", "deleteConfirmSubmit",
                    "loadDeleteConfirmSubmit", "deleteConfirmCancelBtn");
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, remove failed.", "deleteConfirmSubmit", "loadDeleteConfirmSubmit");
            }
        });
    }

    function performDeviceSave(){
        let payload, oldPayload;
        handleSubmitActions("deviceSubmit", "loadDeviceSubmit");
        payload = [{control_id: controlId, device_ids: deviceIdList.length > 0 ? deviceIdList.join(",") : "", modified_by: dData.user}];
        oldPayload = [{control_id: controlId, device_ids: oldDeviceIdList.length > 0 ? oldDeviceIdList.join(",") : ""}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_environmental_monitoring_control", Payload: payload, urlType: "POST",
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId, OldPayload: oldPayload,
                            PKey: "control_id", Condition: "primary"}).then(data => {
            if (data[0] === true){
                props.setCount(0);
                formSuccess("Attached devices have been updated.", "deviceSubmit", "loadDeviceSubmit",
                    "cancelDeviceBtn");
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "deviceSubmit", "loadDeviceSubmit");
            }
        });
    }

    function createSettings(){
        let payload;
        handleSubmitActions("createSubmit", "loadCreateSubmit");
        payload = [
            {description: "Global Settings", low_alarm: 2.4, environmental_alarm_type_id: 1,
                alarm_on: 1, campus_id: campusId, environmental_type_id: 3, test_after: 60*60, device_ids: "", modified_by: dData.user},
        ];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_environmental_monitoring_control", Payload: payload, urlType: "PUT",
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId, PKey: "control_id", Condition: "primary"}).then(data => {
            if (data[0] === true){
                props.setCount(0);
                formSuccess("Default battery global created.", "createSubmit", "loadCreateSubmit",
                    "cancelDeviceBtn");
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "createSubmit", "loadCreateSubmit");
            }
        });
    }

    const deviceTableHead = <TableHeaders checkbox={true} headerData={deviceHeaderData} checkboxHeaderId={"uCheckHead"}
                                 checkAll={() => checkAll(0, deviceDataCnt ? deviceDataCnt : 0, deviceIdList,
                                     setDeviceIdList, "uCheckHead", "uCheckDev")} writeAccess={true}
                                 sortOrder={sorter.sortOrder} sortColumn={sorter.sortColumn} sorter={sorter}
                                 setSorter={setSorter} actionAppearance={() => {}} />

    const deviceAddTableHead = <TableHeaders checkbox={true} headerData={deviceHeaderData} checkboxHeaderId={"uCheckHead2"}
                                 checkAll={() => checkAll(0, deviceDataCnt ? deviceDataCnt : 0, deviceIdList,
                                     setDeviceIdList, "uCheckHead2", "uCheckDev")} writeAccess={true}
                                 sortOrder={sorter.sortOrder} sortColumn={sorter.sortColumn} sorter={sorter}
                                 setSorter={setSorter} actionAppearance={() => {}} />

    return (
        <>
            <div className={"card"}>
                <div className={"card-header d-flex justify-content-between"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"}>Battery Settings</h4>
                    </div>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                            text={"Loading"} id={"loadEditSubmit"}/>
                    <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary d-none"}
                            onClick={() => performSave()}/>
                    {envData.length === 0 && <>
                        <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                            text={"Loading"} id={"loadCreateSubmit"}/>
                        <Button id={"createSubmit"} text={"Create Settings"} class={"btn btn-primary"}
                                onClick={() => createSettings()}/>
                    </>}
                </div>
                <div className={`card-body ${envData.length === 0 ? "d-none" : ""}`}>
                    <div className={"list-group list-group-flush my-n3"} id={"env-display"}>
                        <div className={"list-group-item"}>
                            {envData.length > 0 && <Button text={"Add"} icon={true} class={"btn btn-primary mb-3 float-end"}
                                    style={{height: "40.5px"}} iconClass={"fe fe-plus me-2"} onClick={() => {
                                formClear("addForm", "addSubmit");
                                setDeviceIdList(() => []);
                                formCheck("addForm", "addSubmit");
                                showModal("addmodal");
                            }}/>}
                            <form id={"editForm"} className={"was-validated"}>
                                <table className={"table"}>
                                    <thead>
                                    <tr>
                                        <th className={"text-center"} scope={"col"}>Remove</th>
                                        <th className={"text-center"} scope={"col"}>Devices</th>
                                        <th className={"text-center"} scope={"col"}>Description</th>
                                        <th className={"text-center"} scope={"col"}>Low Battery</th>
                                        <th className={"text-center"} scope={"col"}>Alarm On?</th>
                                        <th className={"text-center"} scope={"col"}>Report Interval (min)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {envData.map((envRec, i) => {
                                        let canDelete = false, description;
                                        if (envRec?.environmental_alarm_type_id === 4) {
                                            canDelete = true;
                                            description = envRec?.description;
                                        } else {
                                            if (envRec?.environmental_alarm_type_id === 3) {
                                                description = "Unoccupied Areas";
                                            } else if (envRec?.environmental_alarm_type_id === 2) {
                                                description = "Occupied Areas";
                                            } else {
                                                description = "Global Setting";
                                            }
                                        }
                                        return (
                                            <tr key={`edit-table-row-${i}`}>
                                                <td className={"text-center"}>
                                                    {canDelete
                                                        ? <div className={"d-inline-block"}>
                                                            <i className={"fe fe-trash-2 as-btn red-hover"}
                                                               onClick={() => {
                                                                   setDeleteId(() => envRec.control_id);
                                                                   showModal("deleteconfirmmodal");
                                                               }}></i>
                                                        </div>
                                                        : <></>
                                                    }
                                                </td>
                                                <td className={"text-center"}>
                                                    {canDelete
                                                        ? <i className={"fe fe-settings as-btn"} onClick={() => {
                                                            setControlId(() => envRec?.control_id);
                                                            setDeviceIdList(() => envRec?.device_ids.split(","));
                                                            setOldDeviceIdList(() => envRec?.device_ids.split(","));
                                                            setRowIndex(() => i);
                                                            showModal("devicemodal");
                                                        }}/>
                                                        :   <></>
                                                    }
                                                </td>
                                                <td className={"text-start"}>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"text"} className={"form-control"}
                                                               id={`description${i}`} readOnly={!canDelete}
                                                               style={{minWidth: "150px"}}
                                                               required={true} defaultValue={description}
                                                               onKeyUp={() => {
                                                                   if (!editRecords.includes(i)){
                                                                       setEditRecords((prev) => {return [...prev, i]});
                                                                   }
                                                                   document.getElementById("editSubmit").classList.remove("d-none");
                                                                   formCheck("editForm", "editSubmit");
                                                               }}/>
                                                        <label htmlFor={`description${i}`}>Description</label>
                                                        <div className={"invalid-feedback"}>
                                                            Description required.
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{width: "200px"}}>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"number"} className={"form-control"}
                                                               id={`lowbattery${i}`} style={{minWidth: "125px"}}
                                                               min={2} max={4} step={.1} required={true}
                                                               defaultValue={envRec?.low_alarm}
                                                               title={"Threshold to trigger on the low end of battery"}
                                                               onChange={() => {
                                                                   if (!editRecords.includes(i)){
                                                                       setEditRecords((prev) => {return [...prev, i]});
                                                                   }
                                                                   document.getElementById("editSubmit").classList.remove("d-none");
                                                                   formCheck("editForm", "editSubmit")
                                                               }} />
                                                        <label htmlFor={`lowbattery${i}`}>Low Battery</label>
                                                        <div className={"invalid-feedback"}>
                                                            Valid range from 2.0 to 4.0
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div>
                                                        <input style={{height: "1.25rem", width: "1.25rem"}}
                                                               id={`alarm${i}`} defaultChecked={envRec?.alarm_on}
                                                               className={"form-check-input mx-3"} type={"checkbox"}
                                                               onChange={() => {
                                                                   if (!editRecords.includes(i)){
                                                                       setEditRecords((prev) => {return [...prev, i]});
                                                                   }
                                                                   document.getElementById("editSubmit").classList.remove("d-none");
                                                                   formCheck("editForm", "editSubmit");
                                                               }}/>
                                                    </div>
                                                </td>
                                                <td style={{width: "250px"}}>
                                                    <div className={"form-floating mb-3"}>
                                                        <input type={"number"} className={"form-control"}
                                                               id={`interval${i}`} style={{minWidth: "125px"}}
                                                               min={30} max={1800} step={1} required={true}
                                                               defaultValue={envRec?.test_after && envRec.test_after >=60 ? envRec.test_after / 60 : 60}
                                                               title={"Interval at which devices on this control will report battery."}
                                                               onChange={(e) => {
                                                                   if (!editRecords.includes(i)){
                                                                       setEditRecords((prev) => {return [...prev, i]});
                                                                   }
                                                                   document.getElementById("editSubmit").classList.remove("d-none");
                                                                   formCheck("editForm", "editSubmit");
                                                               }}/>
                                                        <label htmlFor={`interval${i}`}>Reporting Interval (Min)</label>
                                                        <div className={"invalid-feedback"}>
                                                            Frequency of reporting battery levels from all associated
                                                            devices for analysis. 30 minute minimum.
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>New Battery Setting</h2>
                            <button type={"button"} className={"btn-close"} id={"addClose"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"add-description"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")} required={true}
                                           pattern={"[a-zA-Z0-9 _\\-\\/]+"}/>
                                    <label htmlFor={"add-description"}>Description</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid description. Characters, numbers, and - _ / characters allowed.
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                        <input type={"number"} className={"form-control"} id={"add-lowbattery"}
                                               required={true} step={.1} min={2} max={4} defaultValue={2.4}
                                               onChange={() => formCheck("addForm", "addSubmit")}
                                               onBlur={(e) => {
                                                   if (!e.target.checkValidity()) {
                                                       e.target.stepUp();
                                                   }
                                                   formCheck("addForm", "addSubmit");
                                               }}/>
                                        <label htmlFor={"add-lowbattery"}>Low Battery</label>
                                        <div className={"invalid-feedback"}>
                                            Minimum battery level before alarm creation, 2.0 to 4.0 acceptable range.
                                        </div>
                                    </div>
                                </div>
                                <div className={"list-group-item py-3"}>
                                    <div>
                                        <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Generate
                                            Alarms</h3>
                                        <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                            <input name={"add-alarm"} id={"add-alarm"} defaultChecked={true}
                                                   className={"form-check-input"} type={"checkbox"} onChange={(e) => {
                                                formCheck("addForm", "addSubmit");
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                    <input type={"number"} className={"form-control"} id={"add-interval"}
                                           required={true} step={1} min={30} max={1800} defaultValue={60}
                                           onChange={() => formCheck("addForm", "addSubmit")}
                                           onBlur={(e) => {
                                               if (!e.target.checkValidity()) {
                                                   e.target.stepUp();
                                               }
                                               formCheck("addForm", "addSubmit");
                                           }}/>
                                    <label htmlFor={"add-interval"}>Battery Reporting Frequency (min)</label>
                                    <div className={"invalid-feedback"}>
                                        Frequency in minutes at which the associated devices report battery levels
                                        for analysis. 30 minute minimum.
                                    </div>
                                </div>
                                <hr/>
                                <div id={"adddevicesdiv"} className={"mt-1"}>
                                    <h3 className={"text-center"}>Devices</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search}
                                               onChange={(e) => setSearch(e.target.value)}/>
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {deviceAddTableHead}
                                            </thead>
                                            <tbody>
                                                {deviceTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addSubmit"}
                                            onClick={() => performAddSave()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"devicemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Edit Devices
                                Attached</h2>
                            <button type={"button"} className={"btn-close"} id={"groupClose"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"deviceForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div id={"devicediv"} className={"mt-1"}>
                                    <h3>Battery Supported Devices</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"searchE"} className={"form-control search-input"}
                                               value={search}
                                               onChange={(e) => setSearch(e.target.value)}/>
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {deviceTableHead}
                                            </thead>
                                            <tbody>
                                                {deviceTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelDeviceBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadDeviceSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"deviceSubmit"}
                                            onClick={() => performDeviceSave()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deleteconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Confirm Delete</h2>
                            <button type={"button"} className={"btn-close"} id={"deleteConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to delete this battery control setting?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"deleteConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadDeleteConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deleteConfirmSubmit"}
                                    onClick={() => deleteSubmit()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default BatterySettings;