import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {setACNT, sortFunc, iAmHigherAuthority, compareRole} from "../../libs";
import {isNumber} from "chart.js/helpers";

const CampusDropDown = (props) => {
    let dData, selectOptions = [], sortedData = [];

    if (ReactSession.get("PAL") !== undefined){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData?.acntList){
        for (let i=0; i<dData.acntList.length; i++){
            sortedData.push({CampusName: dData?.campusData[dData.acntList[i]]?.name, CampusId: dData.acntList[i], Valid: dData?.campusData[dData.acntList[i]]?.valid})
        }
        sortedData.sort((a, b) => sortFunc(a, b, "CampusName", "asc"))
    }

    if (!compareRole("admin", dData) || iAmHigherAuthority(99, dData?.role_weight)){
        if (dData?.acntList){
            for (let i=0; i<sortedData.length; i++){
                if (sortedData[i]?.Valid){
                    selectOptions.push(
                        <option key={sortedData[i]?.CampusId+"-option"} value={sortedData[i]?.CampusId}>{sortedData[i]?.CampusName}</option>
                    )
                }else{
                    selectOptions.push(
                        <option disabled={true} key={sortedData[i]?.CampusId+"-option"} value={sortedData[i]?.CampusId}>{sortedData[i]?.CampusName} <p style={{color: "red"}}>(Expired)</p></option>
                    )
                }
            }
        }else{
            selectOptions.push(
                <option disabled={true} key={dData.acnt+"-option"} value={dData.acnt}>{dData?.campus}</option>
            )
        }
        return (
            <div className={"form-floating mx-4"} style={{minWidth: "200px"}}>
                <select className={"form-select"} required={true} id={"campusDash"} value={props?.campusId}
                        onChange={(e) => {
                            if (isNumber(e.target.value)){
                                setACNT(dData, parseInt(e.target.value));
                                props?.setCampusId(parseInt(e.target.value));
                            }else{
                                setACNT(dData, e.target.value);
                                props?.setCampusId(e.target.value);
                            }
                            if (props?.from === "alert") {
                                props?.setCount(10);
                            }else {
                                props?.setCount(0);
                            }
                            props?.pageAction();
                        }}>
                    {selectOptions}
                </select>
                <label htmlFor={"campusDash"}>Campus</label>
            </div>
        )
    }else{
        return "";
    }
}

export default CampusDropDown;