import {Link} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import {toast, ToastContainer} from "react-toastify";
import {Modal} from 'bootstrap';
import {CSVLink} from "react-csv";
import Header from "./Header";
import Button from "./component-assets/Button";
import {
    paginateData, sortFunc, formFail, handleSubmitActions, actionAppearance, deleteModalSingle,
    bulkDeleteModal, checkAll, formCheck, formSuccess, showModal, iAmHigherAuthority, isBannerPresent
} from "../libs"
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Footer from "./Footer";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import SingleDeleteModal from "./component-assets/SingleDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";

const AreaGroups = (props) => {
    let dData, bData, headerData, csvHeaders, headerData2;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    headerData = [
        {label: "Group", key: "area_group_name", align: "left"},
        {label: "Description", key: "area_group_description", align: "left"},
        {label: "Group Type", key: "area_group_category_name", align: "left"},
        {label: "Areas", key: "area_name", align: "left", type: "ellipsis"},
    ];

    csvHeaders = [
        {label: "Group", key: "area_group_name"},
        {label: "Description", key: "area_group_description"},
        {label: "Group Type", key: "area_group_category_name"},
        {label: "Areas", key: "area_name"}
    ];

    const [areaLinkClose, setAreaLinkClose] = useState("cancelEditBtn");

    headerData2 = [
        {label: "Area Name", key: "area_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id",
                inmodal: true, modalKey: areaLinkClose}},
        {label: "Area Type", key: "area_type_description", align: "left"},
        {label: "Current Occupancy", key: "number_residents", align: "center"},
    ];

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [editRights, setEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    // Main Table Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [loadState, setLoadState] = useState("groups");
    const [count, setCount] = useState(0);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [search, setSearch] = useState("");
    const [campusId, setCampusId] = useState(dData.acnt);
    const [areaGroupIdList, setAreaGroupIdList] = useState([]);
    const [sorter, setSorter] = useState({
        sortColumn: "area_group_name",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // Area Picker Variables
    const [areaData, setAreaData] = useState([]);
    const [areaIdList, setAreaIdList] = useState([]);
    const [oldAreaIdList, setOldAreaIdList] = useState([]);
    const [areaDataCnt, setAreaDataCnt] = useState(0);
    const [areaTableRows, setAreaTableRows] = useState([]);
    const [storedIndex, setStoredIndex] = useState(0);
    const [search2, setSearch2] = useState("");
    const [sorter2, setSorter2] = useState({
        sortColumn: "area_name",
        sortOrder: "asc"
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "AreaGroups.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setAreaData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareagroupandareabycampusid", reqType: "stored"}).then(data => {
                setDataOrigin(data ? data : []);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData?.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={deleteRights}
                                writeAccess={editRights} actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={editModal} parentComponent={"Groups"} dData={dData}
                                modal={editRights} checkboxlist={areaGroupIdList} unk={"a-"}
                                setCheckboxId={(value) => {setAreaGroupIdList(value)}} checkidkey={"area_group_id"} />);
    }, [activeData, sorter, page, perPage, areaGroupIdList, areaData]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        let tmpGroups, input, pRules;
        tmpGroups = [];
        input = search.toString().toLowerCase();
        for (let i=0; i < dataOrigin?.length; i++){
            if ((dataOrigin[i]?.area_group_name && dataOrigin[i]?.area_group_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.area_group_description && dataOrigin[i]?.area_group_description?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.area_name && dataOrigin[i]?.area_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.area_group_category_name && dataOrigin[i]?.area_group_category_name?.toLowerCase()?.search(input) !== -1)){
                tmpGroups.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpGroups, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpGroups);
    }, [search, dataOrigin]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search2.toString().toLowerCase();
        for (i = 0; i < areaData.length; i++){
            if ((areaData[i]?.area_name && areaData[i]?.area_name?.toLowerCase()?.search(input) !== -1) ||
                (areaData[i]?.area_type_description && areaData[i]?.area_type_description?.toLowerCase()?.search(input) !== -1) ||
                (areaData[i]?.number_residents && areaData[i]?.number_residents?.toString()?.toLowerCase()?.search(input) !== -1)){
                tmp.push(areaData[i]);
            }
        }
        setAreaDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        setAreaTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                    actionAppearance={() => {}} tableHeaders={headerData2} checkboxId={"uCheckGroup"}
                                    parentComponent={"AreaGroups"} dData={dData} checkboxlist={areaIdList}
                                    modal={false} editModal={() => {}} unk={"x-"}
                                    setCheckboxId={(value) => {setAreaIdList(value)}} checkidkey={"area_id"} />);
    }, [areaData, areaIdList, sorter2, search2, activeData]);

    function editModal(groupIndex){
        let groupId, groupname, dataIndex, modal, delBtn, aIdList = [];
        if (editRights){
            dataIndex = parseInt(groupIndex);
            setSearch2("");
            setStoredIndex(dataIndex);
            setAreaLinkClose("cancelEditBtn");
            document.getElementById("lastIndex").value = dataIndex;
            groupname = document.getElementById("groupNameE");
            groupId = document.getElementById("groupIdE");
            delBtn = document.getElementById("deleteSingleBtn");
            document.getElementById("descriptionE").value = activeData[dataIndex]?.area_group_description;
            document.getElementById("uCheckHead2").checked = false;
            for (let i = 0; i < activeData[dataIndex]?.areas?.length; i++){
                aIdList.push(activeData[dataIndex]?.areas[i]?.area_id.toString());
            }
            setAreaIdList(aIdList);
            setOldAreaIdList(aIdList);
            document.getElementById("uCheckHead2").checked = aIdList && aIdList.length === areaData.length;
            groupname.value = activeData[dataIndex]?.area_group_name;
            groupId.value = activeData[dataIndex]?.area_group_id;
            delBtn.onclick = () => {
                setDContent([[groupId.value, ""]]);
                deleteModalSingle(groupname.value, groupname.value);
            };
            formCheck("editForm", "editSubmit");
            modal = new Modal(document.getElementById("editmodal"), {});
            modal.show();
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setAreaGroupIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function formAddSubmit(){
        // User add form submit function
        let groupname, payload, description;
        if (addRights){
            handleSubmitActions("addSubmit", "loadAddSubmit");
            groupname = document.getElementById("groupname").value;
            description = document.getElementById("description").value;
            payload = [{campus_id: campusId, area_group_name: groupname, area_group_description: description,
                        area_ids: areaIdList}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "area_group_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0] === true){
                    setAreaGroupIdList([]);
                    formSuccess("Group has been added.", "addSubmit", "loadAddSubmit", "cancelAddBtn", "addForm");
                    setCount(0);
                }
                else{
                    formFail(`Error during add, error code: ${data[1]}`, "addSubmit", "loadAddSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function formEditSubmit(){
        // User add form submit function
        let groupId, groupname, description, payload, oldGroupName, oldDescription, oldPayload;
        if (editRights){
            handleSubmitActions("editSubmit", "loadEditSubmit");
            groupId = document.getElementById("groupIdE").value;
            groupname = document.getElementById("groupNameE").value;
            description = document.getElementById("descriptionE").value;
            oldGroupName = activeData[storedIndex]?.area_group_name;
            oldDescription = activeData[storedIndex]?.area_group_description;
            payload = [{area_group_name: groupname, area_group_id: parseInt(groupId), area_group_description: description,
                        area_ids: areaIdList, campus_id: dData.acnt}];
            oldPayload = [{area_group_name: oldGroupName, area_group_id: parseInt(groupId),
                           area_group_description: oldDescription, area_ids: oldAreaIdList, campus_id: campusId}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "area_group_edit", Payload: payload,
                                CampusId: campusId, User: dData.user, OldPayload: oldPayload,
                                ClientId: dData.sessionId}).then(data => {
                    if (data[0]){
                        setCount(0);
                        setAreaGroupIdList([]);
                        formSuccess("Group updated.", "editSubmit", "loadEditSubmit", "cancelEditBtn");
                    }
                    else{
                        formFail(`Error during save, error code: ${data[1]}`, "editSubmit", "loadEditSubmit");
                    }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        if (deleteRights){
            payload = [];
            handleSubmitActions(submitBtn, loadBtn);
            for (let i=0; i < dContent?.length; i++){
                payload.push({area_group_id: parseInt(dContent[i][0])});
            }
            ApiRequester({reqEndpoint: "mytransaction", TransType: "area_group_delete", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("Groups have been deleted.", submitBtn, loadBtn, closeId);
                    setCount(0);
                    setAreaGroupIdList([]);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["deleteBtn"]);
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const tableHeaders = <TableHeaders checkbox={deleteRights} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end, areaGroupIdList, setAreaGroupIdList)}
                                       writeAccess={editRights} sortOrder={sorter.sortOrder} modal_name={"Edit"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} modal={editRights} checkboxlist={areaGroupIdList}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const areaTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={areaIdList}
                                         checkAll={() => checkAll(0, areaDataCnt ? areaDataCnt : 0, areaIdList, setAreaIdList, "uCheckHead2", "uCheckGroup")}
                                         writeAccess={editRights} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />
    // Must have separate header component per table so rendered header ID's do not conflict
    const areaAddTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={areaIdList}
                                         checkAll={() => checkAll(0, areaDataCnt ? areaDataCnt : 0, areaIdList, setAreaIdList, "uCheckHead3", "uCheckGroup")}
                                         writeAccess={editRights} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead3"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />

    const contentHeader = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData,
                                         "area_group_name", ["area_group_id", ""], "area_group_name", setDContent)} />
                }
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
                {addRights && <Button text={"New Group"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                     icon={true} iconClass={"fe fe-plus me-2"}
                                     onClick={() => {
                                         setAreaLinkClose("cancelAddBtn");
                                         setSearch2("");
                                         setAreaIdList([]);
                                         document.getElementById("uCheckHead3").checked = false;
                                         setStoredIndex(undefined);
                                         showModal("addmodal");
                                         formCheck("addForm", "addSubmit");}} />
                }
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Areas"} content={contentHeader} />
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Link to={"/community/areas"}>
                                <Button text={"Individuals"} class={loadState === "individuals" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("individuals")} />
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/community/areas/groups"}>
                                <Button text={"Groups"} class={loadState === "groups" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("groups")} />
                            </Link>
                        </li>
                    </ul>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer} />
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Group</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"groupname"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"[a-zA-Z0-9 _\\-]{0,100}"}/>
                                    <label htmlFor={"groupname"}>Group</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid group name.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"description"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           pattern={"[ -~]+"}/>
                                    <label htmlFor={"description"}>Description</label>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Areas</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {areaAddTableHead}
                                            </thead>
                                            <tbody>
                                                {areaTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true}
                                        text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"}
                                        onClick={() => formAddSubmit()} id={"addSubmit"}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Group</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <input type={"hidden"} id={"groupIdE"} />
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"groupNameE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           required={true} pattern={"[a-zA-Z0-9 _\\-]{0,100}"}/>
                                    <label htmlFor={"groupNameE"}>Group</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a group name!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"descriptionE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           pattern={"[ -~]+"}/>
                                    <label htmlFor={"descriptionE"}>Description</label>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Areas</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2E"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {areaTableHead}
                                            </thead>
                                            <tbody>
                                                {areaTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger me-3"}
                                                             id={"deleteSingleBtn"} icon={true}
                                                             iconClass={"fe fe-trash-2 me-2"}/>}
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} onClick={() => formEditSubmit()} id={"editSubmit"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <input className={"d-none"} type={"hidden"} id={"lastIndex"} />
            <SingleDeleteModal deletePerform={() => deletePerform("deleteSubmit", "loadDeleteSubmit",
                                                                "confirmClose", ppRules.start, ppRules.end)} header={"Delete Group"}/>
            <BulkDeleteModal deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit",
                                                                "bulkConfirmClose", ppRules.start, ppRules.end)}
                             start={ppRules.start} end={ppRules.end} header={"Delete Groups"} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default AreaGroups;