import Button from "./Button";
import {showModal} from "../../libs";

const UpdateHeaderBanner = (props) => {

    function scheduleSubmit(){

    }

    return (
        <>
            <div style={{height: "55px", backgroundColor: "#FBF84AFF", boxShadow: "0 1px 4px"}}
                 className={"text-center pt-3 d-flex justify-content-evenly"}>
                    <h4 className={"pt-2"}>
                        A new version of the palcare system is available. Please schedule a time to
                        update your local on site server.
                    </h4>
                    <div className={"d-inline-block"} style={{minWidth: "325px"}}>
                        <Button text={"Schedule Update"} class={"btn btn-secondary py-1 ms-3"}
                                style={{width: "150px", minWidth: "150px"}}
                                onClick={() => scheduleSubmit()} />
                        <Button text={"Preview Features"} class={"btn btn-secondary py-1 ms-3"}
                                style={{width: "150px", minWidth: "150px"}}
                                onClick={() => showModal("previewmodal")} />
                    </div>
            </div>
            <div id={"previewmodal"}>

            </div>
        </>
    );
};

export default UpdateHeaderBanner;