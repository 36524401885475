import {
    compareRole,
    convertIsoToLocal,
    convertIsoToLocalDate,
    formatDate,
    secToTime,
    convertIsoUTCToLocal,
    secToTimeUTC,
    isThreshold,
    highlightText,
    iAmHigherAuthority,
    closeModal,
    getBorderColor,
    durationToNow
} from "../../libs";
import {Link} from "react-router-dom";
import Button from "./Button";
import {toast} from "react-toastify";
import MaterialIcon from "material-icons-react";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";

const TableBody = (props) => {
    let returnData, tmp, writeCapable, curIndex, rowObj, addlWrite, tdStyle, dData, bData, internalIcon, badgeItem;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    returnData = [];
    for (let i=0; i < props?.tableData?.length; i++){
        curIndex = i.toString();
        if (props?.parentComponent === "UserManagement"){
            if (props?.dData?.migrated){
                writeCapable = (props?.writeAccess && (iAmHigherAuthority(props?.tableData[i]?.user_role_weight, props?.dData?.role_weight) ||
                props?.dData?.username === props?.tableData[i]?.username));
            }else{
                writeCapable = (props?.writeAccess && (!compareRole(props?.tableData[i]?.UserRole, props?.dData) ||
                props?.dData?.username === props?.tableData[i]?.UserName));
            }
        }else{
            writeCapable = props?.writeAccess;
        }
        if (props?.addlEditCheck){
            addlWrite = props?.tableData[i][props?.addlEditKey];
        }else{
            addlWrite = true;
        }
        tmp = [];
        if (i >= props?.start && i <= props?.end){
            if (props.checkbox){
                tmp.push(
                    <td key={`${props?.unk}tcheckbox${curIndex}`}>
                        <div className={"form-check"} onClick={() => props?.writeAccess ? {} : toast.warn("Edit privileges are disabled for your account.")}
                             title={props?.writeAccess ? "" : "Not authorized to make changes"}>
                            {writeCapable && addlWrite
                            ?
                            <input className={"form-check-input table-checkbox"} type={"checkbox"}
                                   name={props?.checkboxId ? props.checkboxId+curIndex : "uCheck"+curIndex}
                                   id={props?.checkboxId ? props.checkboxId+curIndex : "uCheck"+curIndex}
                                   value={props?.tableData[i][props?.checkidkey]}
                                   checked={props?.checkboxlist?.includes(props?.tableData[i][props?.checkidkey]?.toString())}
                                   onChange={(e) => {
                                       props?.actionAppearance();
                                       if (e.target.checked === true){
                                           if (!props?.checkboxlist.includes(e.target.value.toString())){
                                               props?.setCheckboxId((prev) => {
                                                   return [...prev, e.target.value.toString()]
                                               });
                                           }
                                       }else{
                                           if (props?.checkboxlist.includes(e.target.value.toString())) {
                                               props?.setCheckboxId((prev) => {
                                                   return prev.filter((item) => item !== e.target.value.toString());
                                               });
                                           }
                                       }
                                   }} style={{width: "20px", height: "20px"}} />
                            :
                            <input className={"form-check-input table-checkbox"} type={"checkbox"} value={props?.tableData[i][props?.checkidkey]}
                                   name={props?.checkboxId ? props.checkboxId+curIndex : "uCheck"+curIndex}
                                   id={props?.checkboxId ? props.checkboxId+curIndex : "uCheck"+curIndex}
                                   checked={props?.checkboxlist?.includes(props?.tableData[i][props?.checkidkey]?.toString())}
                                   style={{width: "20px", height: "20px"}} readOnly={true}/>
                            }
                            <label className={"form-check-label"} htmlFor={props?.checkboxId ? props.checkboxId+curIndex : "uCheck"+curIndex}> </label>
                        </div>
                    </td>
                );
            }
            if (props.modal){
                badgeItem = <></>;
                if (props?.editModalBadge && props?.tableData[i][props.editModalBadge]?.length > 0){
                    badgeItem = <span className={"position-relative translate-middle badge rounded-pill bg-danger"} style={{left: "10px"}}>{props?.tableData[i][props.editModalBadge]?.length}</span>
                }
                if (props?.modalType === "eye"){
                    if (props?.corpId?.toString() === props?.tableData[i]?.corporate_id?.toString()){
                        tmp.push(
                            <td key={`${props?.unk}edit-modal${i}`}>
                                <i className={"fe fe-eye as-btn-2x"} style={{color: "#6fdd5b", cursor: "unset"}}
                                   title={"Current corporation"} onClick={() => {}}></i>
                            </td>
                        );
                    }else{
                        if (props?.tableData[i]?.campus_id){
                            tmp.push(
                                <td key={`${props?.unk}edit-modal${i}`}>
                                    <i id={`edit-modal${i}`} className={"fe fe-eye as-btn-2x"} title={`Impersonate ${props?.tableData[i]?.corporate_name}`}
                                       onClick={() => props.iconAction(props?.tableData[i]?.corporate_id, i, props?.tableData[i]?.campus_id)}></i>
                                    <span id={`edit-modal-spinner${i}`} className={"spinner-border spinner-border-sm d-none"} role={"status"} aria-hidden={true} />
                                </td>
                            );
                        }
                        else if (props?.tableData[i]?.corporate_id){
                            tmp.push(
                                <td key={`${props?.unk}edit-modal${i}`}>
                                    <i id={`edit-modal${i}`} className={"fe fe-eye as-btn-2x"} title={`Impersonate ${props?.tableData[i]?.corporate_name}`}
                                       onClick={() => props.iconAction(props?.tableData[i]?.corporate_id, i)}></i>
                                    <span id={`edit-modal-spinner${i}`} className={"spinner-border spinner-border-sm d-none"} role={"status"} aria-hidden={true} />
                                </td>
                            );
                        }else{
                            tmp.push(
                                <td key={`${props?.unk}edit-modal${i}`}>
                                    <i id={`edit-modal${i}`} className={"fe fe-eye as-btn-2x disabled"} title={`No corporation exists, impersonation disabled.`}
                                       onClick={() => {}}></i>
                                    <span id={`edit-modal-spinner${i}`} className={"spinner-border spinner-border-sm d-none"} role={"status"} aria-hidden={true} />
                                </td>
                            );
                        }
                    }
                }else if (props?.modalType === "add"){
                    tmp.push(
                        <td key={`${props?.unk}edit-modal${i}`} className={"text-center"}>
                            {writeCapable && addlWrite
                                ? <i className={"fe fe-plus as-btn-2x"} title={props?.modalLabel ? props.modalLabel : ""}
                                     onClick={() => props?.editModal(i, props?.editModalHeaderData ? props?.editModalHeaderData : [])}></i>
                                : <></>}
                            {badgeItem}
                        </td>
                    );
                }else{
                    tmp.push(
                        <td key={`${props?.unk}edit-modal${i}`}>
                            {writeCapable && addlWrite ? <i className={"fe fe-edit as-btn-2x"} onClick={() => props?.editModal(i, props?.editModalHeaderData ? props?.editModalHeaderData : [])}></i> : <></>}
                            {badgeItem}
                        </td>
                    );
                }
            }
            if (props.actionIcon){
                tmp.push(
                    <td key={`${props?.unk}action-item${curIndex}`} className={"ps-0 pe-4 text-center"}>
                        <Button type={"button"} class={"d-none border-0 bg-transparent btn"} spinner={true} text={""} id={`load-icon-btn-${i}`} disabled={true} />
                        <i className={"fe fe-send as-btn-2x"} id={`icon-btn-${i}`} onClick={() => props?.iconAction(i)} title={"Send report now"}></i>
                    </td>
                );
            }
            for (let i2=0; i2<props?.tableHeaders?.length; i2++){
                rowObj = "";
                tdStyle = {}
                if (props?.tableHeaders[i2]?.border?.enabled){
                    if (props?.tableHeaders[i2]?.border?.borderType === "left"){
                        tdStyle = {borderLeftColor: getBorderColor(props?.tableData[i][props?.tableHeaders[i2]?.border?.borderColorKey]),
                                    borderLeftWidth: props?.tableHeaders[i2]?.border?.borderWidth};
                    }else{
                        tdStyle = {borderRightColor: getBorderColor(props?.tableData[i][props?.tableHeaders[i2]?.border?.borderColorKey]),
                                    borderRightWidth: props?.tableHeaders[i2]?.border?.borderWidth};
                    }
                }
                if (props?.tableHeaders[i2]?.wrap){
                    tdStyle["wordBreak"] = props?.tableHeaders[i2].wrap;
                }
                if (props?.tableData[i][props?.tableHeaders[i2].key] && typeof props?.tableData[i][props?.tableHeaders[i2].key] === "object" && props?.tableHeaders[i2]?.type === "obj"){
                    if (props?.tableHeaders[i2]?.link){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} style={tdStyle}>
                                <Link to={props?.tableHeaders[i2].linkto+`/${props?.tableData[i][props?.tableHeaders[i2].linkkey]}`} >
                                    <p className={"mb-0 fs-6"}>{props?.tableData[i][props?.tableHeaders[i2].key]?.length > 0 ? props?.tableData[i][props?.tableHeaders[i2].key][0] : ""}</p>
                                    <br/>
                                    <p className={"mb-0"}>{props?.tableData[i][props?.tableHeaders[i2].key]?.length > 1 ? props?.tableData[i][props?.tableHeaders[i2].key][1] : ""}
                                        {props?.tableData[i][props?.tableHeaders[i2].key]?.length > 2 ? props?.tableData[i][props?.tableHeaders[i2].key][2]?.toLocaleString() : ""}</p>
                                </Link>
                            </td>
                        );
                    }else{
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} style={tdStyle}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} onClick={!props?.modal ? () => props?.editModal(i) : () => {}}>
                                <p className={"mb-0 fs-6"}>{props?.tableData[i][props?.tableHeaders[i2].key]?.length > 0 ? props?.tableData[i][props?.tableHeaders[i2].key][0] : ""}</p>
                                <br/>
                                <p className={"mb-0"}>{props?.tableData[i][props?.tableHeaders[i2].key]?.length > 1 ? props?.tableData[i][props?.tableHeaders[i2].key][1] : ""}
                                    {props?.tableData[i][props?.tableHeaders[i2].key]?.length > 2 ? props?.tableData[i][props?.tableHeaders[i2].key][2] : ""}</p>
                            </td>
                        );
                    }
                }else if (props?.tableHeaders[i2]?.type === "obj" && !props?.tableHeaders[i2]?.link){
                    if (props?.tableHeaders[i2]?.t2 === "datetime"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key[0] + curIndex}
                                key={props?.tableHeaders[i2].key[0] + curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key[0]]}<br/>{props?.tableData[i][props?.tableHeaders[i2].key[1]] ? convertIsoToLocal(props?.tableData[i][props?.tableHeaders[i2].key[1]]) : ""}
                            </td>
                        );
                    }else{
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key[0] + curIndex}
                                key={props?.tableHeaders[i2].key[0] + curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key[0]]}<br/>{props?.tableData[i][props?.tableHeaders[i2].key[1]]}
                            </td>
                        );
                    }
                }else{
                    if (props?.tableHeaders[i2]?.type === "custom") {
                        if (props?.tableHeaders[i2].key === "report_filter_terms"){
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                    onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                    {props?.tableData[i][props?.tableHeaders[i2].key]}
                                </td>
                            );
                        }
                    }else if (props?.tableHeaders[i2]?.type === "iconswap") {
                        internalIcon = null

                        for (let ic=0; ic < props?.tableHeaders[i2]?.condition?.length; ic++){
                            if (!props?.tableData[i][props?.tableHeaders[i2].key]){
                                internalIcon = "N/A"
                                break
                            }
                            if (props?.tableHeaders[i2]?.dataType === "number"){
                                if (props?.tableHeaders[i2]?.condition[ic].type === "minimum"){
                                    if (props?.tableData[i][props?.tableHeaders[i2].key] >= props?.tableHeaders[i2]?.condition[ic].minvalue){
                                        if (props?.tableHeaders[i2]?.condition[ic].iconType === "google"){
                                            internalIcon = <MaterialIcon size={"small"} className={"material-icons md-24 md-dark"}
                                                                         title={props?.tableHeaders[i2]?.condition[ic]?.title}
                                                                         color={props?.tableHeaders[i2]?.condition[ic]?.color ? props?.tableHeaders[i2]?.condition[ic]?.color : "#0000008a"}
                                                                         icon={props?.tableHeaders[i2]?.condition[ic]?.icon}></MaterialIcon>
                                        }else{
                                            internalIcon = <i className={`fe fe-${props?.tableHeaders[i2]?.condition[ic]?.icon}`}></i>
                                        }
                                    }
                                }else if (props?.tableHeaders[i2]?.condition[ic].type === "equal"){
                                    if (props?.tableData[i][props?.tableHeaders[i2].key] === props?.tableHeaders[i2]?.condition[ic].minvalue ||
                                        props?.tableData[i][props?.tableHeaders[i2].key] === props?.tableHeaders[i2]?.condition[ic].maxvalue){
                                        if (props?.tableHeaders[i2]?.condition[ic].iconType === "google"){
                                            internalIcon = <MaterialIcon size={"small"} className={"material-icons md-24 md-dark"}
                                                                         title={props?.tableHeaders[i2]?.condition[ic]?.title}
                                                                         color={props?.tableHeaders[i2]?.condition[ic]?.color ? props?.tableHeaders[i2]?.condition[ic]?.color : "#0000008a"}
                                                                         icon={props?.tableHeaders[i2]?.condition[ic]?.icon}></MaterialIcon>
                                        }else{
                                            internalIcon = <i className={`fe fe-${props?.tableHeaders[i2]?.condition[ic]?.icon}`}></i>
                                        }
                                    }
                                }else if (props?.tableHeaders[i2]?.condition[ic].type === "range"){
                                    if (props?.tableData[i][props?.tableHeaders[i2].key] >= props?.tableHeaders[i2]?.condition[ic].minvalue &&
                                        props?.tableData[i][props?.tableHeaders[i2].key] <= props?.tableHeaders[i2]?.condition[ic].maxvalue){
                                        if (props?.tableHeaders[i2]?.condition[ic].iconType === "google"){
                                            internalIcon = <MaterialIcon size={"small"} className={"material-icons md-24 md-dark"}
                                                                         title={props?.tableHeaders[i2]?.condition[ic]?.title}
                                                                         color={props?.tableHeaders[i2]?.condition[ic]?.color ? props?.tableHeaders[i2]?.condition[ic]?.color : "#0000008a"}
                                                                         icon={props?.tableHeaders[i2]?.condition[ic]?.icon}></MaterialIcon>
                                        }else{
                                            internalIcon = <i className={`fe fe-${props?.tableHeaders[i2]?.condition[ic]?.icon}`}></i>
                                        }
                                    }
                                }else if (props?.tableHeaders[i2]?.condition[ic].type === "maximum"){
                                    if (props?.tableData[i][props?.tableHeaders[i2].key] <= props?.tableHeaders[i2]?.condition[ic].maxvalue){
                                        if (props?.tableHeaders[i2]?.condition[ic].iconType === "google"){
                                            internalIcon = <MaterialIcon size={"small"} className={"material-icons md-24 md-dark"}
                                                                         title={props?.tableHeaders[i2]?.condition[ic]?.title}
                                                                         color={props?.tableHeaders[i2]?.condition[ic]?.color ? props?.tableHeaders[i2]?.condition[ic]?.color : "#0000008a"}
                                                                         icon={props?.tableHeaders[i2]?.condition[ic]?.icon}></MaterialIcon>
                                        }else{
                                            internalIcon = <i className={`fe fe-${props?.tableHeaders[i2]?.condition[ic]?.icon}`}></i>
                                        }
                                    }
                                }
                            }else{
                                internalIcon = "N/A"
                            }
                        }
                        if (internalIcon === null){
                            internalIcon = "N/A"
                        }
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key + curIndex} key={`${props?.unk}-${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {internalIcon}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "stack") {
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key + curIndex} key={`${props?.unk}-${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableHeaders[i2].key.map((item, index, curIndex) => {
                                    if (index === props?.tableHeaders[i2].key.length - 1){
                                        return <div key={`${props?.unk}-${props?.tableHeaders[i2].key}Key${curIndex}subitem${index}`}>{props?.tableData[i][item]}</div>
                                    }
                                    return <div key={`${props?.unk}-${props?.tableHeaders[i2].key}Key${curIndex}subitem${index}`}>{props?.tableData[i][item]} /<br/></div>
                                })}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "date") {
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key + curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? formatDate(new Date(props?.tableData[i][props?.tableHeaders[i2].key])) : ""}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "tel"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? props?.tableData[i][props?.tableHeaders[i2].key].slice(0,3) + "-" + props?.tableData[i][props?.tableHeaders[i2].key].slice(3,6) + "-" + props?.tableData[i][props?.tableHeaders[i2].key].slice(6,10) : ""}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "switch"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key2]?.toLocaleString()}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "concat") {
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key[0]+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key[0]}Key${curIndex}`} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key[0]]} {props?.tableData[i][props?.tableHeaders[i2].key[1]]}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "color") {
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key[0]+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key[0]}Key${curIndex}`} style={tdStyle}>
                                <p className={"mb-0"} title={`Color rgb(${props?.tableData[i][props?.tableHeaders[i2].key[0]]},${props?.tableData[i][props?.tableHeaders[i2].key[1]]},${props?.tableData[i][props?.tableHeaders[i2].key[2]]})`}
                                   style={{width: "23px", height: "23px", borderRadius: "12px", border: "2px solid black",
                                       textAlign: "center", backgroundColor: `rgb(${props?.tableData[i][props?.tableHeaders[i2].key[0]]},
                                       ${props?.tableData[i][props?.tableHeaders[i2].key[1]]},${props?.tableData[i][props?.tableHeaders[i2].key[2]]})`}}>
                                </p>
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "timeofday"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? secToTime(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "timeofday-utc"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? secToTimeUTC(props?.tableData[i][props?.tableHeaders[i2].key], props?.tzOffset) : ""}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "status"){
                        if (props?.tableData[i][props?.tableHeaders[i2].key]){
                            tdStyle.color = "green";
                        }else{
                            tdStyle.color = "red";
                        }
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableHeaders[i2][props?.tableData[i][props?.tableHeaders[i2].key]]}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "capitalize"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-capitalize text-"+props?.tableHeaders[i2]?.align : "px-1 text-capitalize"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key]}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "datetime") {
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key + curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {
                                }} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoToLocal(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "datetime-utc"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoUTCToLocal(props?.tableData[i][props?.tableHeaders[i2].key], props?.tzOffset) : ""}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "datetime-date"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoToLocalDate(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "bool"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key + curIndex}
                                key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? props?.tableHeaders[i2].t : props?.tableHeaders[i2]?.f}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "nullsafe"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] != null ? props?.tableData[i][props?.tableHeaders[i2].key] : props?.tableHeaders[i2]?.nullReplace}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "duration"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} style={tdStyle}>
                                {durationToNow(props?.tableData[i][props?.tableHeaders[i2].key])}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "button"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} style={tdStyle}>
                                <button id={`${props?.tableHeaders[i2]?.button?.id}${i}`} className={props?.tableHeaders[i2]?.button?.className}
                                        onClick={() => {
                                            props?.tableHeaders[i2]?.button?.clickFunc(props?.tableData[i][props?.tableHeaders[i2]?.key], i);
                                }}>
                                    {props?.tableHeaders[i2]?.button?.text}
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={`${props?.tableHeaders[i2]?.button?.id}Load${i}`}/>
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "ellipsis"){
                        tdStyle.maxWidth = "225px";
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-2 text-truncate text-"+props?.tableHeaders[i2]?.align : "px-2 text-truncate"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                title={props?.tableData[i][props?.tableHeaders[i2].key]}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {props?.tableData[i][props?.tableHeaders[i2].key]}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "list"){
                        let nameList = [];
                        for (let ii=0; ii < props?.tableHeaders[i2].key?.length; ii++){
                            nameList.push(props?.tableData[i][props?.tableHeaders[i2].key[ii]].toString());
                        }
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                {nameList.join(", ")}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "conditional"){
                        if (["eq", "equal", "=", "==", "==="].includes(props?.tableHeaders[i2]?.condition?.operator)){
                            if (props?.tableHeaders[i2]?.condition?.compareValue === props?.tableData[i][props?.tableHeaders[i2].key]){
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}
                                        onClick={!props?.modal ? () => props?.editModal(i) : () => {}}>
                                        {props?.tableHeaders[i2]?.condition?.t?.toLocaleString()}
                                    </td>
                                );
                            }else{
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}
                                        onClick={!props?.modal ? () => props?.editModal(i) : () => {}}>
                                        {props?.tableHeaders[i2]?.condition?.f?.toLocaleString()}
                                    </td>
                                );
                            }
                        }else{
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}
                                    onClick={!props?.modal ? () => props?.editModal(i) : () => {}}>
                                    {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                </td>
                            );
                        }
                    }else{
                        if (props?.tableHeaders[i2]?.link?.enabled){
                            if (props?.tableHeaders[i2]?.link?.type === "obj"){
                                let links = [];
                                for (let ii=0; ii < props?.tableData[i][props?.tableHeaders[i2].key]?.length; ii++){
                                    if (ii+1 === props?.tableData[i][props?.tableHeaders[i2].key]?.length){
                                        links.push(
                                            <Link key={`${props?.unk}link${ii}${props?.tableHeaders[i2].key}`}
                                                  to={`${props?.tableHeaders[i2]?.link?.linkto}/${props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.linkkey]}`}
                                                  onClick={() => props?.tableHeaders[i2]?.link?.inmodal ? closeModal(props?.tableHeaders[i2]?.link?.modalKey) : {}}>
                                                {props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.displayKey]?.toLocaleString()}
                                            </Link>
                                        );
                                    }else{
                                        links.push(
                                            <>
                                                <Link key={`${props?.unk}link${ii}${props?.tableHeaders[i2].key}`}
                                                      to={`${props?.tableHeaders[i2]?.link?.linkto}/${props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.linkkey]}`}
                                                      onClick={() => props?.tableHeaders[i2]?.link?.inmodal ? closeModal(props?.tableHeaders[i2]?.link?.modalKey) : {}}>
                                                    {props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.displayKey]?.toLocaleString()}
                                                </Link>
                                                <br />
                                            </>
                                        );
                                    }
                                }
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}Key${curIndex}`} style={tdStyle}>
                                        {links}
                                    </td>
                                );
                            }else if (props?.tableHeaders[i2]?.link?.type === "conditional"){
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key + curIndex}
                                        key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                        <Link
                                            to={`${props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2]?.link?.conditionKey]?.toString()]?.linkto}/${props?.tableData[i][props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2]?.link?.conditionKey]]?.linkkey]}`}
                                                onClick={() => props?.tableHeaders[i2]?.link?.inmodal ? closeModal(props?.tableHeaders[i2]?.link?.modalKey) : {}}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                        </Link>
                                    </td>
                                );
                            }else if (props?.tableHeaders[i2]?.link?.type === "conditional-obj"){
                                let links = [];
                                for (let ii=0; ii < props?.tableData[i][props?.tableHeaders[i2].key]?.length; ii++){
                                    if (ii+1 === props?.tableData[i][props?.tableHeaders[i2].key]?.length){
                                        links.push(
                                            <Link key={`${props?.unk}-${curIndex}-condlink${ii}${props?.tableHeaders[i2].key}`}
                                                  to={`${props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.conditionKey].toString()]?.linkto}/${props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.conditionKey]]?.linkkey]}`}>
                                                {props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.conditionKey]]?.displayKey]?.toLocaleString()}
                                            </Link>
                                        )
                                    }else{
                                        links.push(
                                            <>
                                                <Link key={`${props?.unk}-${curIndex}-condlink${ii}${props?.tableHeaders[i2].key}`}
                                                      to={`${props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.conditionKey].toString()]?.linkto}/${props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.conditionKey]]?.linkkey]}`}>
                                                    {props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.condition[props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.conditionKey]]?.displayKey]?.toLocaleString()}
                                                </Link>
                                                <br/>
                                            </>
                                        )
                                    }
                                }
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${i2}Key${curIndex}`}
                                        style={tdStyle}>
                                        {links}
                                    </td>
                                );
                            }else if(props?.tableHeaders[i2]?.link?.type === "datetime"){
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key + curIndex}
                                        key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                        <Link to={`${props?.tableHeaders[i2].link.linkto}/${props?.tableData[i][props?.tableHeaders[i2].link.linkkey]}`}
                                            onClick={() => props?.tableHeaders[i2]?.link?.inmodal ? closeModal(props?.tableHeaders[i2]?.link?.modalKey) : {}}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoToLocal(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                                        </Link>
                                    </td>
                                );
                            } else {
                                if (props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString() !== "Totals") {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                            <Link to={`${props?.tableHeaders[i2].link.linkto}/${props?.tableData[i][props?.tableHeaders[i2].link.linkkey]}`}
                                                    onClick={() => props?.tableHeaders[i2]?.link?.inmodal ? closeModal(props?.tableHeaders[i2]?.link?.modalKey) : {}}>
                                                {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                            </Link>
                                        </td>
                                    );
                                }else{
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                                {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                        </td>
                                    );
                                }
                            }
                        }else if (props?.tableHeaders[i2]?.highlight?.enabled){
                            if (isThreshold(props?.tableData[i][props?.tableHeaders[i2]?.key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.extremeValue,
                                props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)) {
                                if (props?.tableHeaders[i2]?.highlight?.useIcon) {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                            <MaterialIcon size={"small"} className={"material-icons ps-2 md-18 text-danger align-text-bottom"} icon={"radio_button_checked"}
                                                          title={highlightText("Extremely", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.extremeValue)} />
                                        </td>
                                    );
                                } else {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-danger text-" + props?.tableHeaders[i2]?.align : "px-1 bg-pal-danger"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                        </td>
                                    );
                                }
                            }else if (isThreshold(props?.tableData[i][props?.tableHeaders[i2]?.key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.dangerValue,
                                props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)) {
                                if (props?.tableHeaders[i2]?.highlight?.useIcon) {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                            <MaterialIcon size={"small"} className={"material-icons ps-2 md-18 text-risk align-text-bottom"} icon={"radio_button_checked"}
                                                          title={highlightText("Very", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.dangerValue)} />
                                        </td>
                                    );
                                } else {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-risk text-" + props?.tableHeaders[i2]?.align : "px-1 bg-pal-danger"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                        </td>
                                    );
                                }
                            }else if (isThreshold(props?.tableData[i][props?.tableHeaders[i2].key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.warnValue,
                                props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)){
                                if (props?.tableHeaders[i2]?.highlight?.useIcon){
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                            id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                            <MaterialIcon size={"small"} className={"material-icons ps-2 md-18 text-warning align-text-bottom"} icon={"radio_button_checked"}
                                                          title={highlightText("", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.warnValue)} />
                                        </td>
                                    );
                                }else{
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-warning text-"+props?.tableHeaders[i2]?.align : "px-1 bg-pal-warning"}
                                            id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                        </td>
                                    );
                                }
                            }else{
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`} style={tdStyle}>
                                        {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                    </td>
                                );
                            }
                        }else{
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key+curIndex} key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}
                                    onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={tdStyle}>
                                    {props?.tableData[i][props?.tableHeaders[i2].key]?.toLocaleString()}
                                </td>
                            );
                        }
                    }
                }
                if (rowObj !== ""){
                    tmp.push(rowObj);
                }
            }
            returnData.push(
                <tr key={`${props?.unk}tableRow${curIndex}`} id={`tableRow${curIndex}`}>
                    {tmp}
                </tr>
            );
        }
    }

    return (
        <>
            {returnData}
        </>
    );
};

export default TableBody;