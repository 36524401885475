import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import {
    actionAppearance,
    arraysEqual, bulkDeleteModal, checkAll, formCheck,
    formFail,
    formSuccess,
    handleSubmitActions,
    isBannerPresent, rangeSliderHandler, showModal,
    sortFunc
} from "../libs";
import TableBody from "./component-assets/TableBody";
import {ToastContainer} from "react-toastify";
import TableHeaders from "./component-assets/TableHeaders";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";

const SystemSettings = (props) => {

    let dData, reasonHeaderData;

    reasonHeaderData = [
        {label: "Reason", key: "reason_code_description", align: "center"},
        {label: "Reason Category", key: "pal_reason_description", align: "center"}
    ];

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Page Control
    const [tabItem, setTabItem] = useState("init");
    const [loaded, setLoaded] = useState(false);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [count, setCount] = useState(1);

    // Alert Behavior Section
    const [configData, setConfigData] = useState([]);
    const [configLoaded, setConfigLoaded] = useState(false);
    const [afrAccept, setAFRAccept] = useState(false);
    const [afrFinish, setAFRFinish] = useState(false);
    const [afrReason, setAFRReason] = useState(false);
    const [rTimeout, setRTimeout] = useState(0);
    const [fTimeout, setFTimeout] = useState(0);
    const [cTimeout, setCTimeout] = useState(0);
    const [alertListOrigin, setAlertListOrigin] = useState([]);
    const [alertList, setAlertList] = useState([]);
    const [alertSettingId, setAlertSettingId] = useState(0);

    // Custom User Section
    const [customData, setCustomData] = useState([]);
    const [customLoaded, setCustomLoaded] = useState(false);
    const [customList, setCustomList] = useState([]);
    const [customOriginList, setCustomOriginList] = useState([]);
    const [displayItem, setDisplayItem] = useState(false);
    const [groupItem, setGroupItem] = useState(false);

    // High Priority Event Section
    const [priorityData, setPriorityData] = useState([]);
    const [priorityLoaded, setPriorityLoaded] = useState(false);
    const [priorityOriginList, setPriorityOriginList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [priorityListLoaded, setPriorityListLoaded] = useState(false);
    const [eventTypeData, setEventTypeData] = useState([]);
    const [eventTypesLoaded, setEventTypesLoaded] = useState(false);
    const [prioritySetList, setPrioritySetList] = useState([]);

    // AFR Device Section
    const [afrData, setAFRData] = useState([]);
    const [afrOriginList, setAFROriginList] = useState([]);
    const [afrList, setAFRList] = useState([]);
    const [afrLoaded, setAFRLoaded] = useState(false);
    const [afrListLoaded, setAFRListLoaded] = useState(false);
    const [devTypesData, setDevTypesData] = useState([]);
    const [typesLoaded, setTypesLoaded] = useState(false);
    const [afrSetList, setAFRSetList] = useState([]);

    // Custom Reason Section
    const [reasonData, setReasonData] = useState([]);
    const [globalReasons, setGlobalReasons] = useState([]);
    const [globalOptions, setGlobalOptions] = useState([]);
    const [reasonRows, setReasonRows] = useState([]);
    const [reasonLoaded, setReasonLoaded] = useState(false);
    const [globalReasonsLoaded, setGlobalReasonsLoaded] = useState(false);
    const [reasonIdList, setReasonIdList] = useState([]);
    const [reasonStoredIndex, setReasonStoredIndex] = useState(0);
    const [reasonDContent, setReasonDContent] = useState("");
    const [reasonSorter, setReasonSorter] = useState({
        sortColumn: "cloud_reason_code_id",
        sortOrder: "desc"
    })

    // Server Section
    const [serverData, setServerData] = useState({});
    const [serverLoaded, setServerLoaded] = useState(false);
    const [serverEmail, setServerEmail] = useState("");
    const [serverList, setServerList] = useState([]);
    const [serverOriginList, setServerOriginList] = useState([]);


    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0) {
            // Alert Settings Data
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                Tbl: "core_campus_alert_settings",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setConfigData(data ? data : []);
                setConfigLoaded(true);
            });
            // AFR Settings DATA
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                payload: {campus_id: campusId},
                                procedure: "getAFRDevices", reqType: "stored"}).then(data => {
                setAFRData(data ? data : []);
                setAFRLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_inovonics_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setDevTypesData(data ? data : []);
                setTypesLoaded(true);
            });
            // Reason Code Settings Data
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                payload: {campus_id: campusId},
                                procedure: "getReasonCodes", reqType: "stored"}).then(data => {
                setReasonData(data ? data : []);
                setReasonLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                Tbl: "att_palcare_reasoncode", procedure: "fetchall",
                                reqType: "direct"}).then(data => {
                setGlobalReasons(data ? data : []);
                setGlobalReasonsLoaded(true);
            });
            // Custom User Settings
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_campus",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setCustomData(data ? data : []);
                setCustomLoaded(true);
            });
            // Server Settings Data
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "server_system_config", reqType: "stored"}).then(data => {
                setServerData(data ? data : {});
                setServerLoaded(true);
            });
            // High Priority Data
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_high_priority_entity",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setPriorityData(data ? data : []);
                setPriorityLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_event_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEventTypeData(data ? data : []);
                setEventTypesLoaded(true);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![reasonLoaded, typesLoaded, afrLoaded, configLoaded, serverLoaded, customLoaded, globalReasonsLoaded,
              priorityLoaded, eventTypesLoaded].includes(false)){
            setLoaded(true);
            setTabItem("alert");
        }
    }, [reasonLoaded, typesLoaded, afrLoaded, configLoaded, serverLoaded, customLoaded, globalReasonsLoaded,
             priorityLoaded, eventTypesLoaded]);

    useEffect(() => {
        let dItem, gItem;
        if (customLoaded && customData && customData.length > 0){
            dItem = customData[0].is_name_editable === 1;
            gItem = customData[0]?.is_group_editable === 1;
            setDisplayItem(dItem);
            setGroupItem(gItem);
            setCustomOriginList([dItem, gItem]);
        }
    }, [customData]);

    useEffect(() => {
        setCustomList([displayItem, groupItem]);
    }, [displayItem, groupItem]);

    useEffect(() => {
        if (arraysEqual(customList, customOriginList)){
            document.getElementById("custom-btn").classList.add("d-none");
            document.getElementById("custom-cancel-btn").classList.add("d-none");
        }else{
            document.getElementById("custom-btn").classList.remove("d-none");
            document.getElementById("custom-cancel-btn").classList.remove("d-none");
        }
    }, [customList, customOriginList]);

    useEffect(() => {
        let accept, finish, reason, releaseT, finishT, clearT;
        if (configData && configData.length > 0 && configLoaded){
            setAlertSettingId(configData[0]?.alert_setting_id);
            accept = configData[0]?.accept_required === 1;
            finish = configData[0]?.finish_required === 1;
            reason = configData[0]?.reason_required === 1;
            releaseT = configData[0]?.release_timeout ? configData[0].release_timeout/60 : 0;
            finishT = configData[0]?.finish_timeout ? configData[0].finish_timeout/60 : 0;
            clearT = configData[0]?.clear_timeout ? configData[0].clear_timeout/60 : 0;
            setAFRAccept(accept);
            setAFRFinish(finish);
            setAFRReason(reason);
            setRTimeout(releaseT);
            setFTimeout(finishT);
            setCTimeout(clearT);
            setAlertListOrigin([accept, finish, reason, releaseT, clearT, finishT]);
        }
    }, [configData]);

    useEffect(() => {
        setAlertList([afrAccept, afrFinish, afrReason, rTimeout, cTimeout, fTimeout]);
    }, [afrAccept, afrFinish, afrReason, rTimeout, fTimeout, cTimeout]);

    useEffect(() => {
        if (arraysEqual(alertList, alertListOrigin)){
            document.getElementById("alert-btn").classList.add("d-none");
            document.getElementById("alert-cancel-btn").classList.add("d-none");
        }else{
            document.getElementById("alert-btn").classList.remove("d-none");
            document.getElementById("alert-cancel-btn").classList.remove("d-none");
        }
    }, [alertList, alertListOrigin]);

    useEffect(() => {
        if (afrAccept){
            document.getElementById("alert-finish").disabled = false;
        }else{
            setAFRFinish(false);
            setAFRReason(false);
            document.getElementById("alert-finish").disabled = true;
            document.getElementById("alert-reason").disabled = true;
        }
    }, [afrAccept]);

    useEffect(() => {
        if (afrFinish){
            document.getElementById("alert-reason").disabled = false;
        }else{
            setAFRReason(false);
            document.getElementById("alert-reason").disabled = true;
        }
    }, [afrFinish]);

    useEffect(() => {
        let tmp = [];
        if (afrLoaded){
            for (let i=0; i < afrData.length; i++){
                tmp.push(afrData[i].device_type_id);
            }
            setAFRList(tmp);
            setAFROriginList(tmp);
            setAFRListLoaded(true);
        }
    }, [afrData, afrLoaded]);

    useEffect(() => {
        if (arraysEqual(afrList, afrOriginList)){
            document.getElementById("afr-btn").classList.add("d-none");
            document.getElementById("afr-cancel-btn").classList.add("d-none");
        }else{
            document.getElementById("afr-btn").classList.remove("d-none");
            document.getElementById("afr-cancel-btn").classList.remove("d-none");
        }
    }, [afrList, afrOriginList]);

    useEffect(() => {
        let tmp = [];
        if (priorityLoaded){
            for (let i=0; i < priorityData.length; i++){
                tmp.push(priorityData[i].event_type_id);
            }
            setPriorityList(tmp);
            setPriorityOriginList(tmp);
            setPriorityListLoaded(true);
        }
    }, [priorityData, priorityLoaded]);

    useEffect(() => {
        if (arraysEqual(priorityList, priorityOriginList)){
            document.getElementById("priority-btn").classList.add("d-none");
            document.getElementById("priority-cancel-btn").classList.add("d-none");
        }else{
            document.getElementById("priority-btn").classList.remove("d-none");
            document.getElementById("priority-cancel-btn").classList.remove("d-none");
        }
    }, [priorityList, priorityOriginList]);

    useEffect(() => {
        let tmp = [];
        if (typesLoaded && afrListLoaded && devTypesData.length > 0){
            devTypesData.sort((a,b) => sortFunc(a,b,"device_type_name", "asc"));
            for (let i=0; i < devTypesData.length; i++){
                tmp.push(
                    <li className={"my-1"}>
                        <div className={"form-check form-switch"}>
                            <input className={"form-check-input"} type={"checkbox"}
                                   id={`afr-${devTypesData[i].device_type_name}`}
                                   value={devTypesData[i].inovonics_type_id}
                                   checked={afrList.includes(devTypesData[i].inovonics_type_id)}
                                   onChange={(e) => {
                                       if (e.target.checked){
                                           if (!afrList.includes(parseInt(e.target.value))){
                                               setAFRList((prevState) => {
                                                   return [...prevState, parseInt(e.target.value)];
                                               });
                                           }
                                       }else{
                                           setAFRList(afrList.filter((item) => {
                                               return item !== parseInt(e.target.value);
                                           }));
                                       }
                                   }}/>
                            <label className={"form-check-label text-dark"}
                                   htmlFor={`afr-${devTypesData[i].device_type_name}`}>
                                {devTypesData[i].device_type_name}
                            </label>
                        </div>
                    </li>
                );
            }
            setAFRSetList(tmp);
        }
    }, [afrData, devTypesData, afrList, afrListLoaded, typesLoaded]);

    useEffect(() => {
        let tmp = [];
        if (eventTypesLoaded && priorityListLoaded && eventTypeData.length > 0){
            eventTypeData.sort((a,b) => sortFunc(a,b,"event_type_name", "asc"));
            for (let i=0; i < eventTypeData.length; i++){
                tmp.push(
                    <li className={"my-1"}>
                        <div className={"form-check form-switch"}>
                            <input className={"form-check-input"} type={"checkbox"}
                                   id={`priority-${eventTypeData[i].event_type_name}`}
                                   value={eventTypeData[i].event_type_id}
                                   checked={priorityList.includes(eventTypeData[i].event_type_id)}
                                   onChange={(e) => {
                                       if (e.target.checked){
                                           if (!priorityList.includes(parseInt(e.target.value))){
                                               setPriorityList((prevState) => {
                                                   return [...prevState, parseInt(e.target.value)];
                                               });
                                           }
                                       }else{
                                           setPriorityList(priorityList.filter((item) => {
                                               return item !== parseInt(e.target.value);
                                           }));
                                       }
                                   }}/>
                            <label className={"form-check-label text-dark"}
                                   htmlFor={`priority-${eventTypeData[i].event_type_name}`}>
                                {eventTypeData[i].event_type_name}
                            </label>
                        </div>
                    </li>
                );
            }
            setPrioritySetList(tmp);
        }
    }, [priorityData, eventTypeData, priorityList, priorityListLoaded, eventTypesLoaded]);

    useEffect(() => {
        let tmp = [];
        if (globalReasonsLoaded && globalReasons && globalReasons.length > 0){
            for (let i=0; i < globalReasons.length; i++){
                tmp.push(
                    <option key={`category-${i}`} value={globalReasons[i]?.pal_reason_id}>
                        {globalReasons[i]?.pal_reason_description}
                    </option>
                )
            }
            setGlobalOptions(tmp);
        }
    }, [globalReasons]);

    useEffect(() => {
        if (reasonData.length > 0) {
            reasonData.sort((a, b) => sortFunc(a, b, reasonSorter.sortColumn, reasonSorter.sortOrder));
        }
        setReasonRows(<TableBody start={0} end={reasonData.length} tableData={reasonData} checkbox={true} unk={"b-"}
                                 writeAccess={true} addlEditKey={""} addlEditCheck={false}
                                 actionAppearance={() => actionAppearance(0, reasonData.length, ["reasonDeleteBtn"])}
                                 tableHeaders={reasonHeaderData} editModal={editReasonModal}
                                 parentComponent={"SystemSettings"} dData={dData} modal={true}
                                 checkboxlist={reasonIdList} setCheckboxId={(value) => {setReasonIdList(value)}}
                                 checkidkey={"cloud_reason_code_id"} />);
    }, [reasonData, reasonSorter, reasonIdList]);

    useEffect(() => {
        rangeSliderHandler(rTimeout, "rangeSpan1", "range1");
        rangeSliderHandler(fTimeout, "rangeSpan2", "range2");
        rangeSliderHandler(cTimeout, "rangeSpan3", "range3");
    }, [rTimeout, fTimeout, cTimeout]);

    useEffect(() => {
        let tmp;
        if (serverLoaded && serverData){
            tmp = serverData?.emergency_contact ? serverData.emergency_contact : "";
            setServerEmail(tmp);
            setServerList([tmp]);
            setServerOriginList([tmp]);
        }
    }, [serverData])

    useEffect(() => {
        setServerList([serverEmail]);
    }, [serverEmail])

    useEffect(() => {
        if (arraysEqual(serverList, serverOriginList)){
            document.getElementById("server-btn").classList.add("d-none");
            document.getElementById("server-cancel-btn").classList.add("d-none");
        }else{
            document.getElementById("server-btn").classList.remove("d-none");
            document.getElementById("server-cancel-btn").classList.remove("d-none");
        }
    }, [serverList, serverOriginList]);

    function editReasonModal(editIndex){
        let dataIndex;
        dataIndex = parseInt(editIndex);
        setReasonStoredIndex(dataIndex);
        document.getElementById("edit-reason").value = reasonData[dataIndex]?.reason_code_description;
        document.getElementById("edit-category").value = reasonData[dataIndex]?.pal_reason_code_id;
        formCheck("reasonEditForm", "reasonEditSubmit");
        showModal("reasonEditModal");
    }

    function reasonAddSubmit(){
        let categoryId, payload, name;
        handleSubmitActions("reasonAddSubmit", "loadReasonAddSubmit");
        name = document.getElementById("add-reason").value;
        categoryId = document.getElementById("add-category").value;
        payload = [{reason_code_description: name, pal_reason_code_id: parseInt(categoryId), campus_id: campusId}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_reason_codes", Payload: payload, urlType: "PUT",
                            User: dData.user, PKey: "cloud_reason_code_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId}).then(data => {
            if (data && data[0] === true){
                setCount(0);
                formSuccess("Reason code added successfully", "reasonAddSubmit", "loadReasonAddSubmit",
                    "cancelReasonAddBtn");
            }
            else{
                formFail(`Error during save, error code: ${data[1]}`, "reasonAddSubmit", "loadReasonAddSubmit");
            }
        });
    }

    function reasonEditSubmit(){
        let categoryId, name, categoryIdOld, nameOld, payload,  oldPayload, id;
        handleSubmitActions("reasonEditSubmit, loadReasonEditSubmit");
        id = reasonData[reasonStoredIndex]?.cloud_reason_code_id;
        name = document.getElementById("edit-reason").value;
        categoryId = document.getElementById("edit-category").value;
        nameOld = reasonData[reasonStoredIndex].reason_code_description;
        categoryIdOld = reasonData[reasonStoredIndex].pal_reason_code_id;
        payload = [{cloud_reason_code_id: id, campus_id: campusId, pal_reason_code_id: categoryId,
                    reason_code_description: name, modified_by: dData.User}];
        oldPayload = [{cloud_reason_code_id: id, pal_reason_code_id: categoryIdOld, reason_code_description: nameOld,
                       campus_id: campusId}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_reason_codes", Payload: payload, urlType: "POST",
                            User: dData.user, PKey: "cloud_reason_code_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId, OldPayload: oldPayload}).then(data => {
            if (data && data[0] === true){
                setCount(0);
                formSuccess("Reason code saved successfully", "reasonEditSubmit", "loadReasonEditSubmit",
                    "cancelReasonEditBtn");
            }
            else{
                formFail(`Error during save, error code: ${data[1]}`, "reasonEditSubmit", "loadReasonEditSubmit");
            }
        });
    }

    function customSubmit() {
        let payload, oldPayload;
        handleSubmitActions("custom-btn", "load-custom-btn");
        payload = [{campus_id: campusId, is_name_editable: displayItem ? 1 : 0, is_group_editable: groupItem ? 1 : 0,
                   modified_by: dData.user}];
        oldPayload = [{campus_id: campusId, is_name_editable: customOriginList[0],
                       is_group_editable: customOriginList[1]}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_campus", Payload: payload, urlType: "POST",
                            User: dData.user, PKey: "campus_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId, OldPayload: oldPayload}).then(data => {
            if (data[0]) {
                setCount(0);
                formSuccess("Custom User Settings Saved", "custom-btn", "load-custom-btn");
            }
            else{
                formFail("Error encountered, save failed", "custom-btn", "load-custom-btn");
            }
        });
    }

    function alertSubmit(){
        let payload, oldPayload, accept, finish, reason, releaseTimer, finishTimer, clearTimer;
        handleSubmitActions("alert-btn", "load-alert-btn");
        accept = afrAccept ? 1 : 0;
        finish = afrFinish ? 1 : 0;
        reason = afrReason ? 1 : 0;
        releaseTimer = rTimeout * 60;
        finishTimer = fTimeout * 60;
        clearTimer = cTimeout * 60;
        payload = [{alert_setting_id: alertSettingId, campus_id: campusId, accept_required: accept,
                   finish_required: finish, reason_required: reason, release_timeout: releaseTimer,
                   finish_timeout: finishTimer, clear_timeout: clearTimer}];
        oldPayload = [{alert_setting_id: alertSettingId, campus_id: campusId, accept_required: alertListOrigin[0],
                      finish_required: alertListOrigin[1], reason_required: alertListOrigin[2],
                      release_timeout: alertListOrigin[3]*60, finish_timeout: alertListOrigin[4]*60,
                      clear_timeout: alertListOrigin[5]*60}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_campus_alert_settings", Payload: payload, urlType: "POST",
                            User: dData.user, PKey: "alert_setting_id", Condition: "primary", CampusId: campusId,
                            ClientId: dData.sessionId, OldPayload: oldPayload}).then(data => {
            if(data[0]){
                setCount(0);
                formSuccess("Alert Behavior Settings Saved", "alert-btn", "load-alert-btn");
            }
            else{
                formFail("Error encountered, save failed", "alert-btn", "load-alert-btn");
            }
        });
    }

    function afrSubmit(){
        let payload, oldPayload;
        handleSubmitActions("afr-btn", "load-afr-btn");
        payload = [{campus_id: campusId, afr_entities: afrList}];
        oldPayload = [{campus_id: campusId, afr_entities: afrOriginList}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "afrSave", Payload: payload, CampusId: campusId,
                            OldPayload: oldPayload, ClientId: dData.sessionId, User: dData.user}).then(data => {
            if(data[0]){
                setCount(0);
                formSuccess("AFR Devices Saved", "afr-btn", "load-afr-btn");
            }
            else{
                formFail("Error encountered, save failed", "afr-btn", "load-afr-btn");
            }
        });
    }

    function prioritySubmit(){
        let payload, oldPayload;
        handleSubmitActions("priority-btn", "load-priority-btn");
        payload = [{campus_id: campusId, priority_entities: priorityList}];
        oldPayload = [{campus_id: campusId, priority_entities: priorityOriginList}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "prioritySave", Payload: payload,
                            CampusId: campusId, OldPayload: oldPayload, ClientId: dData.sessionId,
                            User: dData.user}).then(data => {
            if(data[0]){
                setCount(0);
                formSuccess("High Priority Event Types Saved", "priority-btn", "load-priority-btn");
            }
            else{
                formFail("Error encountered, save failed", "priority-btn", "load-priority-btn");
            }
        });
    }

    function serverSubmit(){
        let payload, oldPayload;
        handleSubmitActions("server-btn", "load-server-btn");
        payload = [{emergency_contact: serverEmail}];
        oldPayload = [{emergency_contact: serverOriginList[0]}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "system_server_settings", Payload: payload,
                            OldPayload: oldPayload, CampusId: campusId, User: dData.user,
                            ClientId: dData.sessionId}).then(data => {
            if(data[0]){
                setCount(0);
                formSuccess("Saved server settings", "server-btn", "load-server-btn");
            }
            else{
                formFail("Error encountered, save failed", "server-btn", "load-server-btn");
            }
        })
    }

    function reasonDeletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        handleSubmitActions(submitBtn, loadBtn);
        payload = [];
        for (let i=0; i < reasonDContent.length; i++){
            payload.push({cloud_reason_code_id: reasonDContent[i][0]});
        }
        ApiRequester({reqEndpoint: "mytransaction", TransType: "reason_delete", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0]){
                setCount(0);
                setReasonIdList([]);
                formSuccess("Reasons deleted.", submitBtn, loadBtn, closeId);
                document.getElementById("uCheckHead").checked = false;
                checkAll(start, end);
                actionAppearance(start, end, ["deleteBtn"]);
            }
            else{
                formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
            }
        });
    }

    const reasonHeaders = <TableHeaders checkbox={true} headerData={reasonHeaderData}
                                        checkAll={() => checkAll(0, reasonData.length, reasonIdList, setReasonIdList)}
                                        writeAccess={true} sortOrder={reasonSorter.sortOrder} modal_name={"Edit"}
                                        sortColumn={reasonSorter.sortColumn} sorter={reasonSorter}
                                        setSorter={setReasonSorter} modal={true} checkboxlist={reasonIdList}
                                        actionAppearance={() => actionAppearance(0, reasonData.length, ["reasonDeleteBtn"])}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)}
                            setCount={(item) => setCount(item)} pageAction={() => {
                setLoaded(false);
                setTypesLoaded(false);
                setAFRLoaded(false);
                setReasonLoaded(false);
                setAFRListLoaded(false);
                setConfigLoaded(false);
                setCustomLoaded(false);
            }} />
        </div>
    );

    return (
        <div className={"main-content"}>
            <Header preTitle={"Settings"} Title={"System Settings"} content={headerContent}/>
            <div className={"container-fluid"}>
                <div
                    className={`row align-items-center ${isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}`}
                    style={{backgroundColor: "#f9fbfd", zIndex: 2}}>
                    <div className={"col"}>
                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                            <li key={"set-alert"} className={"nav-item"}>
                                <Button text={"Alert Behavior"}
                                        class={tabItem === "alert" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("alert")}/>
                            </li>
                            <li key={"set-custom"} className={"nav-item"}>
                                <Button text={"Customizations"}
                                        class={tabItem === "custom" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("custom")}/>
                            </li>
                            <li key={"set-priority"} className={"nav-item"}>
                                <Button text={"High Priority Alerts"}
                                        class={tabItem === "priority" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("priority")}/>
                            </li>
                            <li key={"set-device"} className={"nav-item"}>
                                <Button text={"Accept/Finish Devices"}
                                        class={tabItem === "afr" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("afr")}/>
                            </li>
                            <li key={"set-reason"} className={"nav-item"}>
                                <Button text={"Custom Reasons"}
                                        class={tabItem === "reason" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("reason")}/>
                            </li>
                            <li key={"set-server"} className={"nav-item"}>
                                <Button text={"Server"} class={tabItem === "server" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("server")}/>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={tabItem === "init" ? "d-block" : "d-none"}>
                    <p>System settings loading. . .</p>
                </div>
                <div className={tabItem === "alert" ? "d-block" : "d-none"}>
                    <div className={"card"} style={{maxWidth: "1200px", minWidth: "600px"}}>
                        <div className={"card-header"}>
                            <h4 className={"card-header-title"}>Alert Behavior Settings</h4>
                            <Button text={"Cancel"} id={"alert-cancel-btn"} class={"btn btn-secondary d-none me-3"}
                                    onClick={() => {
                                        setAFRAccept(alertListOrigin[0]);
                                        setAFRFinish(alertListOrigin[1]);
                                        setAFRReason(alertListOrigin[2]);
                                        setRTimeout(alertListOrigin[3]);
                                        setCTimeout(alertListOrigin[4]);
                                        setFTimeout(alertListOrigin[5]);
                                    }}/>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                    spinner={true} text={"Saving"} id={"load-alert-btn"}/>
                            <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Save"} id={"alert-btn"}
                                    class={"btn btn-primary d-none"} onClick={() => alertSubmit()}/>
                        </div>
                        <div className={"card-body"}>
                            <div className={"list-group list-group-flush my-3"}>
                                <div className={"list-group-item pt-0"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} type={"checkbox"}
                                               id={"alert-accept"} checked={afrAccept}
                                               onChange={(e) => {
                                                   setAFRAccept(e.target.checked);
                                               }}/>
                                        <label className={"form-check-label text-dark"}
                                               htmlFor={"alert-accept"}>
                                            Accept Alerts
                                        </label>
                                    </div>
                                    <p className={"text-muted"}>Users must accept alerts being responded to.</p>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} type={"checkbox"}
                                               id={"alert-finish"} checked={afrFinish}
                                               onChange={(e) => {
                                                   setAFRFinish(e.target.checked);
                                               }}/>
                                        <label className={"form-check-label text-dark"}
                                               htmlFor={"alert-finish"}>
                                            Finish Alerts
                                        </label>
                                    </div>
                                    <p className={"text-muted"}>User must mark when they are completed with an alert</p>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} type={"checkbox"}
                                               id={"alert-reason"} checked={afrReason}
                                               onChange={(e) => {
                                                   setAFRReason(e.target.checked);
                                               }}/>
                                        <label className={"form-check-label text-dark"}
                                               htmlFor={"alert-reason"}>
                                            Record Reason
                                        </label>
                                    </div>
                                    <p className={"text-muted"}>Users must accept alerts being responded to.</p>
                                </div>
                                <div className={"list-group-item"}>
                                    <div className={"row align-items-center"}>
                                        <div className={"col"}>
                                            <h4 className={"font-weight-base mb-1 d-inline-block"}
                                                style={{width: "120px"}}>Release Timeout</h4>
                                            <input className={"form-control form-text range-input"} max={60} min={1}
                                                   type={"number"} placeholder={rTimeout} onBlur={(e) => {
                                                if (parseInt(e.target.value) >= parseInt(e.target.min) && parseInt(e.target.value) <= parseInt(e.target.max)) {
                                                    setRTimeout(parseInt(e.target.value));
                                                }
                                                e.target.value = "";
                                            }}/>
                                            <p className={"text-muted d-block"}>Time to release alert after accepted
                                                with no
                                                device clear.</p>
                                            <div className={"range-container"}>
                                                <span id={"rangeSpan1"}>{rTimeout} Min</span>
                                                <input id={"range1"} className={"form-range"} type={"range"} min={"1"}
                                                       step={"1"} max={"60"} value={rTimeout}
                                                       onChange={(e) => {
                                                           setRTimeout(parseInt(e.target.value));
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"list-group-item"}>
                                    <div className={"row align-items-center"}>
                                        <div className={"col"}>
                                            <h4 className={"font-weight-base mb-1 d-inline-block"}
                                                style={{width: "120px"}}>Clear Timeout</h4>
                                            <input className={"form-control form-text range-input"} max={59} min={4}
                                                   type={"number"} placeholder={cTimeout}
                                                   onBlur={(e) => {
                                                       if (parseInt(e.target.value) >= parseInt(e.target.min) && parseInt(e.target.value) <= parseInt(e.target.max)) {
                                                           setCTimeout(parseInt(e.target.value));
                                                           if (parseInt(e.target.value) >= fTimeout) {
                                                               setFTimeout(parseInt(e.target.value) + 1);
                                                           }
                                                       }
                                                       e.target.value = "";
                                                   }}/>
                                            <p className={"text-muted d-block"}>Time to autoclear alert after no contact
                                                from
                                                system.</p>
                                            <div className={"range-container"}>
                                                <span id={"rangeSpan3"}>{cTimeout} Min</span>
                                                <input id={"range3"} className={"form-range"} type={"range"} min={"4"}
                                                       step={"1"} max={"59"} value={cTimeout}
                                                       onChange={(e) => {
                                                           setCTimeout(parseInt(e.target.value));
                                                           if (parseInt(e.target.value) >= fTimeout) {
                                                               setFTimeout(parseInt(e.target.value) + 1);
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"list-group-item"}>
                                    <div className={"row align-items-center"}>
                                        <div className={"col"}>
                                            <h4 className={"font-weight-base mb-1 d-inline-block"}
                                                style={{width: "120px"}}>Finish Timeout</h4>
                                            <input className={"form-control form-text range-input"} max={60} min={5}
                                                   type={"number"} placeholder={fTimeout}
                                                   onBlur={(e) => {
                                                       if (parseInt(e.target.value) >= parseInt(e.target.min) && parseInt(e.target.value) <= parseInt(e.target.max)) {
                                                           setFTimeout(parseInt(e.target.value));
                                                           if (parseInt(e.target.value) <= cTimeout && cTimeout >= parseInt(e.target.min)) {
                                                               setCTimeout(parseInt(e.target.value) - 1);
                                                           }
                                                       }
                                                       e.target.value = "";
                                                   }}/>
                                            <p className={"text-muted d-block"}>Time to auto finish alarm if stale after
                                                accept
                                                and clear.</p>
                                            <div className={"range-container"}>
                                                <span id={"rangeSpan2"}>{fTimeout} Min</span>
                                                <input id={"range2"} className={"form-range"} type={"range"} min={"5"}
                                                       step={"1"} max={"60"} value={fTimeout}
                                                       onChange={(e) => {
                                                           if (parseInt(e.target.value) >= parseInt(e.target.min) && parseInt(e.target.value) <= parseInt(e.target.max)) {
                                                               setFTimeout(parseInt(e.target.value));
                                                               if (parseInt(e.target.value) <= cTimeout && cTimeout >= parseInt(e.target.min)) {
                                                                   setCTimeout(parseInt(e.target.value) - 1)
                                                               }
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={tabItem === "custom" ? "d-block" : "d-none"}>
                    <div className={"card"} style={{maxWidth: "1200px", minWidth: "600px"}}>
                        <div className={"card-header"}>
                            <h4 className={"card-header-title"}>User Customizations</h4>
                            <Button text={"Cancel"} id={"custom-cancel-btn"}
                                    class={"btn btn-secondary d-none me-3"}
                                    onClick={() => {
                                        setDisplayItem(customOriginList[0]);
                                        setGroupItem(customOriginList[1]);
                                    }}/>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                    spinner={true} text={"Saving"} id={"load-custom-btn"}/>
                            <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Save"} id={"custom-btn"}
                                    class={"btn btn-primary d-none"} onClick={() => customSubmit()}/>
                        </div>
                        <div className={"card-body"}>
                            <div className={"list-group list-group-flush my-3"}>
                                <div className={"list-group-item pt-0"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} type={"checkbox"}
                                               id={"custom-display"} checked={displayItem}
                                               onChange={(e) => {
                                                   setDisplayItem(e.target.checked);
                                               }}/>
                                        <label className={"form-check-label text-dark"}
                                               htmlFor={"alert-accept"}>
                                            Display Name
                                        </label>
                                    </div>
                                    <p className={"text-muted"}>User can change their display name.</p>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} type={"checkbox"}
                                               id={"alert-finish"} checked={groupItem}
                                               onChange={(e) => {
                                                   setGroupItem(e.target.checked);
                                               }}/>
                                        <label className={"form-check-label text-dark"}
                                               htmlFor={"alert-finish"}>
                                            Group
                                        </label>
                                    </div>
                                    <p className={"text-muted"}>User can change the group of alerts they receive.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={tabItem === "priority" ? "d-block" : "d-none"}>
                    <div className={"card"} style={{maxWidth: "1200px", minWidth: "600px"}}>
                        <div className={"card-header"}>
                            <h4 className={"card-header-title"}>High Priority Alert Types</h4>
                            <Button text={"Cancel"} id={"priority-cancel-btn"}
                                    class={"btn btn-secondary d-none me-3"}
                                    onClick={() => setPriorityList(priorityOriginList)}/>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                    spinner={true} text={"Saving"} id={"load-priority-btn"}/>
                            <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Save"} id={"priority-btn"}
                                    class={"btn btn-primary d-none"} onClick={() => prioritySubmit()}/>
                        </div>
                        <div className={"card-body"}>
                            <ul className={"my-3"} style={{listStyle: "none", columns: 2, columnFill: "balance"}}>
                                {prioritySetList}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={tabItem === "afr" ? "d-block" : "d-none"}>
                    <div className={"card"} style={{maxWidth: "1200px", minWidth: "600px"}}>
                        <div className={"card-header"}>
                            <h4 className={"card-header-title"}>Accept Finish Reason Devices (Default)</h4>
                            <Button text={"Cancel"} id={"afr-cancel-btn"}
                                    class={"btn btn-secondary d-none me-3"}
                                    onClick={() => setAFRList(afrOriginList)}/>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                    spinner={true} text={"Saving"} id={"load-afr-btn"}/>
                            <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Save"} id={"afr-btn"}
                                    class={"btn btn-primary d-none"} onClick={() => afrSubmit()}/>
                        </div>
                        <div className={"card-body"}>
                            <ul className={"my-3"} style={{listStyle: "none", columns: 2, columnFill: "balance"}}>
                                {afrSetList}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={tabItem === "reason" ? "d-block" : "d-none"}>
                    <div className={"card"}>
                        <div className={"card-header"}>
                            <h4 className={"card-header-title"}>Reason Codes</h4>
                            <Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                    id={"reasonDeleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                    onClick={() => bulkDeleteModal(0, reasonData.length, reasonData,
                                        "reason_code_description", ["cloud_reason_code_id", ""], "", setReasonDContent)}/>
                            <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Add Reason"}
                                    class={"btn btn-primary ms-4"}
                                    onClick={() => {
                                        document.getElementById("add-reason").value = "";
                                        formCheck("reasonAddForm", "reasonAddSubmit");
                                        showModal("reasonAddModal");
                                    }}/>
                        </div>
                        <div className={"card-body"}>
                            <table className={"table table-sm table-responsive-sm table-hover"}>
                                <thead className={"table-header"}>
                                {reasonHeaders}
                                </thead>
                                <tbody>
                                {reasonRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={tabItem === "server" ? "d-block" : "d-none"}>
                    <div className={"card"} style={{maxWidth: "1200px", minWidth: "600px"}}>
                        <div className={"card-header"}>
                            <h4 className={"card-header-title"}>Server Settings</h4>
                            <Button text={"Cancel"} id={"server-cancel-btn"}
                                    class={"btn btn-secondary d-none me-3"}
                                    onClick={() => setServerEmail(serverOriginList[0])}/>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                    spinner={true} text={"Saving"} id={"load-server-btn"}/>
                            <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Save"} id={"server-btn"}
                                    class={"btn btn-primary d-none"} onClick={() => serverSubmit()}/>
                        </div>
                        <div className={"card-body"}>
                            <form className={"was-validated"}>
                                <div className={"list-group list-group-flush my-n3"}>
                                    <div className={"list-group-item"}>
                                        <div className={"form-floating"} style={{maxWidth: "400px"}}>
                                            <input className={"form-control"} id={"emergcontact"} value={serverEmail}
                                                   pattern={"^([A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,})(?:,\\s*([A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}))*$"}
                                                   required={true}
                                                   onChange={(e) => {
                                                       setServerEmail(e.target.value);
                                                   }}
                                                   onKeyUp={(e) => {
                                                       setServerEmail(e.target.value);
                                                   }}/>
                                            <label htmlFor={"emergcontact"}>System Offline Notification Email
                                                Address</label>
                                            <div className={"invalid-feedback"}>
                                                Provide a valid email address to get notified when your system is
                                                offline!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"modal fade"} id={"reasonAddModal"} data-bs-backdrop={"static"}
                     data-bs-keyboard={"false"}
                     tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                    <div className={"modal-dialog"}>
                        <div className={"modal-content"}>
                            <div className={"modal-header bg-pal"}>
                                <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Custom Reason</h2>
                                <button type={"button"} className={"btn-close"} id={"editClose"}
                                        data-bs-dismiss={"modal"} aria-label={"Close"}/>
                            </div>
                            <div className={"modal-body"}>
                                <form id={"reasonAddForm"}>
                                    <div className={"list-group list-group-flush my-n3 was-validated"}>
                                        <h4>Reason {reasonData.length + 1}</h4>
                                        <div className={"form-floating mb-3"}>
                                            <input className={"form-control"} id={"add-reason"} required={true}
                                                   pattern={"^[0-9a-zA-Z_\\- ]+$"}
                                                   onKeyUp={() => formCheck("reasonAddForm", "reasonAddSubmit")}/>
                                            <label className={"floatingInput"} htmlFor={"add-reason"}>
                                                Reason Name
                                            </label>
                                        </div>
                                        <div className={"form-floating"}>
                                            <select className={"form-select"} id={"add-category"} required={true}
                                                    onChange={() => {
                                                        formCheck("reasonAddForm", "reasonAddSubmit");
                                                    }}>
                                                {globalOptions}
                                            </select>
                                            <label htmlFor={"add-category"}>
                                                Reason Category
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelReasonAddBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadReasonAddSubmit"}/>
                                <Button type={"button"} class={"btn btn-primary ms-3"} id={"reasonAddSubmit"}
                                        onClick={() => reasonAddSubmit()}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal fade"} id={"reasonEditModal"} data-bs-backdrop={"static"}
                     data-bs-keyboard={"false"}
                     tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                    <div className={"modal-dialog"}>
                        <div className={"modal-content"}>
                            <div className={"modal-header bg-pal"}>
                                <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Reason</h2>
                                <button type={"button"} className={"btn-close"} id={"editClose"}
                                        data-bs-dismiss={"modal"} aria-label={"Close"}/>
                            </div>
                            <div className={"modal-body"}>
                                <form id={"reasonEditForm"}>
                                    <div className={"list-group list-group-flush my-n3 was-validated"}>
                                        <h4>Reason {reasonData.length + 1}</h4>
                                        <div className={"form-floating mb-3"}>
                                            <input className={"form-control"} id={"edit-reason"} required={true}
                                                   pattern={"^[0-9a-zA-Z_\\- ]+$"}
                                                   onKeyUp={() => formCheck("reasonEditForm", "reasonEditSubmit")}/>
                                            <label className={"floatingInput"} htmlFor={"edit-reason"}>
                                                Reason Name
                                            </label>
                                        </div>
                                        <div className={"form-floating"}>
                                            <select className={"form-select"} id={"edit-category"} required={true}
                                                    onChange={() => {
                                                        formCheck("reasonEditForm", "reasonEditSubmit");
                                                    }}>
                                                {globalOptions}
                                            </select>
                                            <label htmlFor={"edit-category"}>
                                                Reason Category
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelReasonEditBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadReasonEditSubmit"}/>
                                <Button type={"button"} class={"btn btn-primary ms-3"} id={"reasonEditSubmit"}
                                        onClick={() => reasonEditSubmit()}/>
                            </div>
                        </div>
                    </div>
                </div>
                <BulkDeleteModal
                    deletePerform={() => reasonDeletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", 0, reasonData.length)}
                    start={0} end={reasonData.length} header={"Delete Areas"}/>
                <input type={"hidden"} id={"uContent"}/>
                <div className={"footer-spacer"}>
                </div>
            </div>
            <Footer/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </div>
    );
};

export default SystemSettings;