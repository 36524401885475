import Button from "./Button";
import {showModal} from "../../libs";

const ShowMeBanner = (props) => {

    function showMe(){

    }

    return (
        <>
            <div style={{height: "55px", backgroundColor: "#FBAE4AFF", boxShadow: "0 1px 4px"}}
                 className={"text-center pt-3"}>
                <h4 className={"d-inline-block pt-2"} style={{color: "black"}}>A new version of portal has been deployed, check out the changes.</h4>
                <Button text={"View Changes"} class={"btn btn-secondary d-inline-block py-1 ms-3"}
                        style={{width: "150px", minWidth: "150px"}}
                        onClick={() => showModal("previewmodal")} />
            </div>
            <div id={"previewmodal"}>

            </div>
        </>
    );
};

export default ShowMeBanner;