import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import ApiRequester from "./ApiRequester";
import {
    paginateData,
    sortFunc,
    formFail,
    handleSubmitActions,
    actionAppearance,
    bulkDeleteModal,
    checkAll,
    formCheck,
    formSuccess,
    showModal,
    reloadSessionCorp, iAmHigherAuthority,
    getCountryOptions, getStateOptionsByCountry, getZipRegexByCountry, setRoleInfo, isBannerPresent
} from "../libs"
import {toast, ToastContainer} from "react-toastify";
import {CSVLink} from "react-csv";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Header from "./Header";
import Footer from "./Footer";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import PerPageModal from "./component-assets/PerPageModal";

const Campuses = (props) => {
    let dData, bData, headerData, csvHeaders;

    headerData = [
        {label: "Campus Name", key: "campus_name", align: "left",
            link: {enabled: true, linkto: "/profiles/campus", linkkey: "campus_id"}},
        {label: "Corporation", key: "corporate_name", align: "left",
            link: {enabled: true, linkto: "/profiles/corporate", linkkey: "corporate_id"}},
        {label: "Address", key: "campus_street_address", align: "left"},
        {label: "City", key: "campus_city", align: "left"},
        {label: "State", key: "campus_state", align: "center"},
        {label: "Zip Code", key: "campus_zip", align: "center"},
        {label: "Email", key: "campus_email", align: "left"},
        {label: "Phone #", key: "campus_phone", align: "center"},
        {label: "Service Plan", key: "service_plan_active", align: "center", type: "bool", t: "Plan Active", f: "No Plan"}
    ];
    csvHeaders = [
        {label: "Campus Name", key: "campus_name"},
        {label: "Corporation Name", key: "corporate_name"},
        {label: "Address", key: "campus_street_address"},
        {label: "City", key: "campus_city"},
        {label: "State", key: "campus_state"},
        {label: "Zip Code", key: "campus_zip"},
        {label: "Email", key: "campus_email"},
        {label: "Phone #", key: "campus_phone"},
        {label: "Service Plan", key: "service_plan_active"}
    ];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(80, dData.my_role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(80, dData.my_role_weight));
    // Main Table Data Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [corpData, setCorpData] = useState([]);
    const [corpOptions, setCorpOptions] = useState([]);
    const [tzData, setTZData] = useState([]);
    const [tzOptions, setTZOptions] = useState([]);
    const [country, setCountry] = useState("");
    const [oldIndex, setOldIndex] = useState(-1);
    const [oldIndexText, setOldIndexText] = useState("");
    const [roleData, setRoleData] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [rowCorpId, setRowCorpId] = useState(0);
    const [rowCampusId, setRowCampusId] = useState(0);
    const [rowIndex, setRowIndex] = useState(0);
    // Main Table Control Variables
    const [count, setCount] = useState(0);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [search, setSearch] = useState("");
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [sorter, setSorter] = useState({
        sortColumn: "campus_name",
        sortOrder: "asc"
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "Campuses.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>)

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                payload: {campus_filter: ""}, procedure: "getcampuses", reqType: "stored"}).then(data => {
                setDataOrigin(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_corporate",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setCorpData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_timezones",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setTZData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_user_roles",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setRoleData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (corpData.length > 0){
            let tmpOptions = [];
            for (let i=0; i < corpData.length; i++){
                tmpOptions.push(
                    <option key={`corp-option-${i}`} value={corpData[i].corporate_id}>{corpData[i].corporate_name}</option>
                );
            }
            setCorpOptions(() => tmpOptions);
        }
    }, [corpData]);

    useEffect(() => {
        let tmp = [];
        if (tzData && tzData?.length > 0){
            for (let i=0; i < tzData?.length; i++){
                tmp.push(<option key={`tz-option-${i}`} value={tzData[i]?.timezone_id}>
                    {tzData[i]?.timezone_name} ({tzData[i]?.timezone})
                </option>);
            }
            setTZOptions(tmp);
        }
    }, [tzData]);

    useEffect(() => {
        let tmp = [];
        if (roleData){
            roleData.sort((a, b) => sortFunc(a, b, "user_role_weight", "asc"));
            for (let i=0; i < roleData.length; i++){
                if (iAmHigherAuthority(roleData[i]?.user_role_weight, dData.my_role_weight)) {
                    tmp.push(
                        <option key={`role-option-${i}`} value={roleData[i]?.user_role_weight}>
                            {roleData[i]?.user_role_name}
                        </option>
                    );
                }
            }
            setRoleOptions(tmp);
        }
    }, [roleData]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={false}
                                writeAccess={false} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={() => {}} modal={true} parentComponent={"Customers"}
                                iconAction={iconAction}
                                dData={dData} checkboxlist={[]} modalType={"eye"} corpId={dData?.corp}
                                setCheckboxId={() => {}} checkidkey={"campus_id"} />);
    }, [activeData, sorter, page, perPage]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        let tmpActiveData, input, pRules;
        tmpActiveData = [];
        input = search.toString().toLowerCase();
        for (let i=0; i<dataOrigin.length; i++){
            if ((dataOrigin[i]?.campus_name && dataOrigin[i]?.campus_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.corporate_name && dataOrigin[i]?.corporate_name?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.campus_street_address && dataOrigin[i]?.campus_street_address?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.campus_city && dataOrigin[i]?.campus_city?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.campus_state && dataOrigin[i]?.campus_state?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.campus_zip && dataOrigin[i]?.campus_zip?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.campus_email && dataOrigin[i]?.campus_email?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.campus_phone && dataOrigin[i]?.campus_phone?.toLowerCase()?.search(input) !== -1)){
                tmpActiveData.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpActiveData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpActiveData);
    }, [search, dataOrigin]);

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function formAddSubmit(){
        // User add form submit function
        let campusName, corpId, payload, guid, address, city, state, zipcode, country, timezone, planexpire;
        if (addRights){
            handleSubmitActions("addSubmit", "loadAddSubmit");
            campusName = document.getElementById("name").value;
            corpId = document.getElementById("corpId").value;
            guid = document.getElementById("campusGuid")?.value;
            address = document.getElementById("address").value;
            city = document.getElementById("city").value;
            state = document.getElementById("state").value;
            zipcode = document.getElementById("zip").value;
            country = document.getElementById("country").value;
            timezone = document.getElementById("timezone").value;
            planexpire = document.getElementById("planexpires").value;
            payload = [{campus_name: campusName, corporate_id: parseInt(corpId), plan_expire: `${planexpire} 00:00:00`,
                        campus_guid: guid, address: address, city: city, state: state, zipcode: zipcode,
                        timezone_id: parseInt(timezone), country: country}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "campus_add", Payload: payload,
                                CampusId: dData.acnt, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0] === true){
                    formSuccess(`${campusName} has been added.`, "addSubmit", "loadAddSubmit",
                        "cancelAddBtn", "addForm");
                    if (dData?.corp.toString() === corpId.toString()){
                        reloadSessionCorp(parseInt(corpId), -1, dData.cognito, dData);
                    }
                    setCount(0);
                }
                else{
                    formFail(`Error during add, error code: ${data[1]}`, "addSubmit", "loadAddSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function iconAction(corpId, rowIndex, campId){
        setRowIndex(rowIndex);
        setRowCorpId(corpId);
        setRowCampusId(campId);
        document.getElementById("userrole").value = dData.my_role_weight;
        showModal("impersonatemodal");
    }

    function addModal(){
        if (addRights){
            document.getElementById("campusMigrate").checked = false;
            document.getElementById("campusGuid").required = false;
            document.getElementById("name").required = true;
            document.getElementById("address").required = true;
            document.getElementById("city").required = true;
            document.getElementById("state").required = true;
            document.getElementById("country").required = true;
            document.getElementById("zip").required = true;
            document.getElementById("timezone").required = true;
            document.getElementById("planexpires").required = true;
            document.getElementById("corpId").value = "";
            document.getElementById("campusGuid").value = "";
            document.getElementById("name").value = "";
            document.getElementById("address").value = "";
            document.getElementById("city").value = "";
            document.getElementById("state").value = "";
            document.getElementById("country").value = "";
            document.getElementById("zip").value = "";
            document.getElementById("timezone").value = "";
            document.getElementById("planexpires").value = "";
            document.getElementById("campusGuidDiv").classList.add("d-none");
            document.getElementById("name-div").classList.remove("d-none");
            document.getElementById("address-div").classList.remove("d-none");
            document.getElementById("city-div").classList.remove("d-none");
            document.getElementById("state-div").classList.remove("d-none");
            document.getElementById("country-div").classList.remove("d-none");
            document.getElementById("zip-div").classList.remove("d-none");
            document.getElementById("timezone-div").classList.remove("d-none");
            document.getElementById("planexpires-div").classList.remove("d-none");
            showModal("addmodal");
            formCheck("addForm", "addSubmit");
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function toggleMigrateForm(migratingOn){
        document.getElementById("campusGuid").required = migratingOn;
        document.getElementById("name").required = !migratingOn;
        document.getElementById("address").required = !migratingOn;
        document.getElementById("city").required = !migratingOn;
        document.getElementById("state").required = !migratingOn;
        document.getElementById("country").required = !migratingOn;
        document.getElementById("zip").required = !migratingOn;
        document.getElementById("planexpires").required = !migratingOn;
        if (migratingOn) {
            document.getElementById("campusGuidDiv").classList.remove("d-none");
            document.getElementById("name-div").classList.add("d-none");
            document.getElementById("address-div").classList.add("d-none");
            document.getElementById("city-div").classList.add("d-none");
            document.getElementById("state-div").classList.add("d-none");
            document.getElementById("country-div").classList.add("d-none");
            document.getElementById("zip-div").classList.add("d-none");
            document.getElementById("planexpires-div").classList.add("d-none");
       } else {
            document.getElementById("campusGuidDiv").classList.add("d-none");
            document.getElementById("name-div").classList.remove("d-none");
            document.getElementById("address-div").classList.remove("d-none");
            document.getElementById("city-div").classList.remove("d-none");
            document.getElementById("state-div").classList.remove("d-none");
            document.getElementById("country-div").classList.remove("d-none");
            document.getElementById("zip-div").classList.remove("d-none");
            document.getElementById("planexpires-div").classList.remove("d-none");
       }
        formCheck("addForm", "addSubmit");
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end, [], () => {})}
                                       writeAccess={false} sortOrder={sorter.sortOrder} checkboxHeaderId={"uCheckHead"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} modal={true} checkboxlist={[]}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData,
                                         "campus_name", ["campus_id", ""], "", setDContent)} />}
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
                {addRights && <Button text={"New Campus"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                     icon={true} iconClass={"fe fe-plus me-2"}
                                     onClick={() => addModal()} />
                }
            </div>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Customers"} Title={"Campuses"} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead
                            className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                        {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                        {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Campus</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"align-top ms-3 mb-3"}>
                                    <input className={"form-check-input"} id={"campusMigrate"}
                                           style={{width: "1.25rem", height: "1.25rem"}} type={"checkbox"}
                                           onChange={(e) => toggleMigrateForm(e.target.checked)}/>
                                    <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                           htmlFor={"campusMigrate"}>Migrating from 2.0?</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} id={"corpId"} value={undefined} required={true}>
                                        <option value={""}></option>
                                        {corpOptions}
                                    </select>
                                    <label htmlFor={"corpId"}>Corporation</label>
                                    <div className={"invalid-feedback"}>
                                        Please select the corporation this campus will belong too.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"name-div"}>
                                    <input type={"text"} className={"form-control"} id={"name"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"name"}>Campus Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the name of the campus.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"country-div"}>
                                    <select className={"form-select"} id={"country"} value={undefined} required={true}
                                            onChange={(e) => {
                                                setOldIndex(-1);
                                                setOldIndexText("");
                                                document.getElementById("state").value = "";
                                                setCountry(e.target.value);
                                            }}>
                                        <option value={""}></option>
                                        {getCountryOptions()}
                                    </select>
                                    <label htmlFor={"country"}>Country</label>
                                    <div className={"invalid-feedback"}>
                                        Select country
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"address-div"}>
                                    <input type={"text"} className={"form-control"} id={"address"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"address"}>Street Address</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid street address.
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3"} id={"city-div"} style={{width: "47%"}}>
                                        <input type={"text"} className={"form-control"} id={"city"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        <label htmlFor={"city"}>City</label>
                                        <div className={"invalid-feedback"}>
                                            Enter a valid city.
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3 mx-3"} id={"state-div"} style={{width: "22%"}}>
                                        <select className={"form-select"} id={"state"} value={undefined}
                                                required={true} onChange={(e) => {
                                            if (oldIndex !== -1) {
                                                e.target.options[oldIndex].innerText = oldIndexText;
                                            }
                                            setOldIndex(e.target.selectedIndex);
                                            setOldIndexText(e.target.options[e.target.selectedIndex].innerText);
                                            e.target.options[e.target.selectedIndex].innerText = e.target[e.target.selectedIndex].value;
                                            formCheck("addForm", "addSubmit");
                                        }}>
                                            <option value={""}></option>
                                            {getStateOptionsByCountry(country)}
                                        </select>
                                        <label htmlFor={"state"}>State</label>
                                        <div className={"invalid-feedback"}>
                                            Select the state/province
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"} id={"zip-div"} style={{width: "27%"}}>
                                        <input type={"text"} className={"form-control"} id={"zip"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               required={true} pattern={getZipRegexByCountry(country)}/>
                                        <label htmlFor={"zip"}>Zipcode</label>
                                        <div className={"invalid-feedback"}>
                                            Enter a valid zip code.
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"timezone-div"}>
                                    <select className={"form-select"} id={"timezone"} value={undefined} required={true}
                                            onChange={() => formCheck("addForm", "addSubmit")}>
                                        <option value={""}></option>
                                        {tzOptions}
                                    </select>
                                    <label htmlFor={"timezone"}>Timezone</label>
                                    <div className={"invalid-feedback"}>
                                        Select timezone for campus.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"planexpires-div"}>
                                    <input type={"date"} className={"form-control"} id={"planexpires"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true}/>
                                    <label htmlFor={"planexpires"}>Service Plan Expiry Date</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the date the plan will expire.
                                    </div>
                                </div>
                                <div id={"campusGuidDiv"} className={"form-floating mb-3 d-none"}>
                                    <input type={"text"} className={"form-control"} id={"campusGuid"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"campusGuid"}>Campus 2.0 Guid</label>
                                    <div className={"invalid-feedback"}>
                                        Guid required for 2.0 migration to be successful.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                {addRights &&
                                    <>
                                        <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                                spinner={true}
                                                text={"Loading"} id={"loadAddSubmit"}/>
                                        <button type={"button"} className={"btn btn-primary"}
                                                onClick={() => formAddSubmit()}
                                                id={"addSubmit"}>Submit
                                        </button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"impersonatemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Role to impersonate as?</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"impersonateForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating"}>
                                    <select className={"form-select"} required={true} id={"userrole"}
                                            onChange={(e) => {
                                                formCheck("impersonateForm", "impersonateSubmit");
                                            }}>
                                        <option value={dData.my_role_weight}>My Role</option>
                                        {roleOptions}
                                    </select>
                                    <label htmlFor={"userrole"}>Impersonating User Role</label>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelImpersonateBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadImpersonateSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} id={"impersonateSubmit"}
                                        onClick={() => {
                                            let rweight;
                                            handleSubmitActions("impersonateSubmit", "loadImpersonateSubmit");
                                            rweight = document.getElementById("userrole").value;
                                            setRoleInfo(parseInt(rweight), dData);
                                            reloadSessionCorp(rowCorpId, rowIndex, dData.cognito, dData, rowCampusId);
                                        }}>Impersonate
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)}
                          setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default Campuses;