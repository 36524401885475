import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {useEffect, useState} from "react";
import Button from "../component-assets/Button";
import {formCheck} from "../../libs";
import pcc_logo from "../../img/pointclickcarelogo.svg"
import {toast} from "react-toastify";

const PCC = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const originData = props?.originData?.length > 0 ? props.originData[0] : {}

    const [isActive, setIsActive] = useState(originData?.active ? originData.active : false);

    useEffect(() => {
        formCheck("mainForm", "editSubmit");
    }, [isActive])

    function performSave(){
        let payload;
        toast.warn("Feature not yet implemented.")
    }


    return (
        <>
            <div className={"card"} style={{maxWidth: "700px", minWidth: "515px"}}>
                <div className={"card-header"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"}>PCC Settings</h4>
                        <div className={"form-check form-switch ms-4 d-inline-block"}>
                            <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"integration-on"}
                                   defaultChecked={isActive} onChange={(e) => {
                                document.getElementById("editSubmit").classList.remove("d-none");
                                setIsActive(e.target.checked);
                                if (e.target.checked){
                                    e.target.nextElementSibling.children[0].classList.remove("text-danger");
                                    e.target.nextElementSibling.children[0].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("text-success-bright");
                                }else{
                                    e.target.nextElementSibling.children[0].classList.add("text-danger");
                                    e.target.nextElementSibling.children[0].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("text-success-bright");
                                }
                            }} />
                            <label className={"form-check-label"} htmlFor={"integration-on"}>
                                <span id={"in-label-off"} className={!isActive ? "text-danger fw-bold" : ""}>Off</span>/<span id={"in-label-on"} className={isActive ? "text-success-bright fw-bold" : ""}>On</span>
                            </label>
                        </div>
                        <a href={"https://pointclickcare.com/"} target={"_blank"}>
                            <img width={"140px"} className={"ms-4 align-top"} src={pcc_logo} alt={"Point Click Care Company Logo"} />
                        </a>
                    </div>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary d-none"} onClick={() => performSave()} />
                </div>
                <div className={"card-body"}>
                    <div className={isActive ? "list-group list-group-flush my-n3" : "list-group list-group-flush my-n3 d-none"}>
                        <form id={"mainForm"} className={"was-validated"}>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PCC;