import {Link} from 'react-router-dom';
import {ReactSession} from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import {
    actionAppearance, checkAll, showModal, formFail, handleSubmitActions,
    paginateData, sortFunc, formSuccess, iAmHigherAuthority, callActionModal, isBannerPresent
} from "../libs";
import ApiRequester from "./ApiRequester";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import {CSVLink} from "react-csv";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Header from "./Header";
import Footer from "./Footer";
import BulkActionModal from "./component-assets/BulkActionModal";
import PerPageModal from "./component-assets/PerPageModal";
import {toast, ToastContainer} from "react-toastify";
import CampusDropDown from "./component-assets/CampusDropDown";

const Devices = (props) => {
    let dData, bData, headerData, csvHeaders;

    headerData = [
        {label: "Name", key: "device_name", align: "left",
            link: {enabled: true, type: "conditional", conditionKey: "manufacture_id",
                condition: {"1": {linkto: "/profiles/inovonics", linkkey: "device_id"}}}},
        {label: "Description", key: "descriptions", align: "left"},
        {label: "Model", key: "inovonics_model_name", align: "center"},
        {label: "Serial Number", key: "manufacture_uuid", align: "center"},
        {label: "Resident", key: "resident_name", align: "left", link: {enabled: true, type: "standard",
                linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Area", key: "area_name", align: "left", link: {enabled: true, type: "standard",
                linkto: "/profiles/area", linkkey: "area_id"}},
        {label: `Battery`, key: "current_battery", align: "center", type: "iconswap", dataType: "number",
            condition: [
                {type: "minimum", minvalue: 2.8, maxvalue: "", icon: "battery_full",
                    iconType: "google", title: "Battery Full"},
                {type: "equal", minvalue: 2.7, maxvalue: 2.7, icon: "battery_5_bar",
                    iconType: "google", title: "Battery Good"},
                {type: "range", minvalue: 2.5, maxvalue: 2.6, icon: "battery_2_bar",
                    iconType: "google", color: "#EAC452", title: "Battery Low"},
                {type: "maximum", minvalue: "", maxvalue: 2.4, icon: "battery_alert",
                    iconType: "google", color: "#BB271A", title: "Battery Critically Low"},
            ]},
        {label: `Temperature \xB0F`, key: "current_temperature", align: "center", type: "nullsafe", nullReplace: "N/A"},
        {label: "Humidity %", key: "current_humidity", align: "center", type: "nullsafe", nullReplace: "N/A"},
        {label: "Environ Report Date", key: "env_create_time", align: "center", type: "date"},
        {label: "Last Alarm", key: "last_alarm", align: "center", type: "datetime"},
        {label: "Last Active", key: "last_active", align: "center", type: "datetime"},
        {label: "Active Status", key: "is_active", align: "center", type: "bool", t: "Yes", f: "No"}
    ];
    csvHeaders = [
        {label: "Name", key: "device_name"},
        {label: "Description", key: "descriptions"},
        {label: "Device Model", key: "inovonics_model_name"},
        {label: "Serial Number", key: "manufacture_uuid"},
        {label: "Resident Name", key: "resident_name"},
        {label: "Area Name", key: "area_name"},
        {label: "Category", key: "device_category"},
        {label: "Temperature", key: "current_temperature"},
        {label: "Humidity", key: "current_humidity"},
        {label: "Battery", key: "current_battery"},
        {label: "Environmental Report Time", key: "env_create_time"},
        {label: "Active Status", key: "is_active"}
    ];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Permission Variables
    const [editRights, setEditRights] = useState(iAmHigherAuthority(80, dData.role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(80, dData.role_weight));
    // Main Table Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [loadState, setLoadState] = useState("inovonics");
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [deviceIdList, setDeviceIdList] = useState([]);
    const [search, setSearch] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "descriptions",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    //Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "devices.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                    setDataOrigin(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={editRights || deleteRights}
                                writeAccess={editRights || deleteRights} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["disableBtn", "deleteBtn"])}
                                tableHeaders={headerData} editModal={() => {}} parentComponent={"Community"}
                                dData={dData} checkboxlist={deviceIdList} setCheckboxId={(value) => {setDeviceIdList(value)}}
                                checkidkey={"device_id"} />);
    }, [activeData, sorter, page, perPage, dataOrigin, deviceIdList]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        )
    }, [csvLink]);

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = search.toString().toLowerCase();
        if (input && !isNaN(input)){
            input = parseInt(input).toString();
        }
        for (let i=0; i<dataOrigin.length; i++){
            if ((dataOrigin[i]?.device_name && dataOrigin[i]?.device_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.descriptions && dataOrigin[i]?.descriptions?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.manufacture_uuid && dataOrigin[i]?.manufacture_uuid?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.inovonics_model_name && dataOrigin[i]?.inovonics_model_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.area_name && dataOrigin[i]?.area_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.resident_name && dataOrigin[i]?.resident_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.current_battery && dataOrigin[i]?.current_battery?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.current_humidity && dataOrigin[i]?.current_humidity?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.current_temperature && dataOrigin[i]?.current_temperature?.toString()?.toLowerCase()?.search(input) !== -1)){
                tmpData.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpData);
    }, [search, dataOrigin]);

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setDeviceIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function disablePerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        if (editRights){
            payload = [];
            handleSubmitActions(submitBtn, loadBtn);
            for (let i=0; i < dContent.length; i++){
                payload.push({device_id: dContent[i][0], is_active: false, resident_id: null, area_id: null, modified_by: dData.user});
            }
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "device_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    setCount(0);
                    setDeviceIdList([]);
                    formSuccess("Devices marked inactive.", submitBtn, loadBtn, closeId);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["disableBtn", "deleteBtn"]);
                }
                else{
                    formFail(`Failed to delete with error: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        if (deleteRights){
            handleSubmitActions(submitBtn, loadBtn);
            payload = [];
            for (let i=0; i < dContent.length; i++){
                payload.push({device_id: dContent[i][0], modified_by: dData.user});
            }
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "DELETE",
                                PKey: "device_id", User: dData.user, Condition: "primary", CampusId: dData.acnt}).then(data => {
                if (data[0]){
                    setCount(0);
                    setDeviceIdList([]);
                    formSuccess("Devices deleted.", submitBtn, loadBtn, closeId);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["deleteBtn", "disableBtn"]);
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const tableHeaders = <TableHeaders checkbox={editRights || deleteRights} headerData={headerData}
                                       checkAll={() => checkAll(ppRules.start, ppRules.end, deviceIdList, setDeviceIdList)}
                                       writeAccess={editRights || deleteRights} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} checkboxlist={deviceIdList}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => callActionModal(ppRules.start, ppRules.end, activeData, ["device_name", "manufacture_uuid"],
                                         ["device_id", ""], "", setDContent, "bulkdeletemodal", "deleteBulkModalContent",
                                         "Are you sure you wish to delete the following devices, this can not be undone?")} />}
                {editRights && <Button text={"Disable"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"disableBtn"} icon={true} iconClass={"fe fe-alert-octagon me-2"}
                                     onClick={() => callActionModal(ppRules.start, ppRules.end, activeData, ["device_name", "manufacture_uuid"],
                                         ["device_id", ""], "", setDContent, "bulkdisablemodal", "disableBulkModalContent",
                                         "Are you sure you wish to disable the following records, this will deactivate them and remove them from their assigned area and resident?")} />}
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"} style={{height: "42px"}}
                            icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Devices"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Link to={"/community/devices"}>
                                <Button text={"Nurse Call"}
                                        class={loadState === "inovonics" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("inovonics")}/>
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/community/wander_devices"}>
                                <Button text={"Wandering"}
                                        class={loadState === "accutech" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("accutech")}/>
                            </Link>
                        </li>
                        {iAmHigherAuthority(99, dData?.role_weight)
                            ? <>
                                <li className={"nav-item"}>
                                    <Link to={"/community/system_devices"}>
                                        <Button text={"System Devices"}
                                                class={loadState === "system" ? "nav-link active" : "nav-link"}
                                                onClick={() => setLoadState("system")}/>
                                    </Link>
                                </li>
                                <li className={"nav-item"}>
                                    <Link to={"/community/server_devices"}>
                                        <Button text={"Servers"}
                                                class={loadState === "server" ? "nav-link active" : "nav-link"}
                                                onClick={() => setLoadState("server")}/>
                                    </Link>
                                </li>
                            </>
                            : <></>
                        }
                    </ul>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={isBannerPresent() ? "sticky-table-headX" : "sticky-table-head"}>
                        {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                        {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <BulkActionModal start={ppRules.start} end={ppRules.end} header={"Disable Devices"} modalName={"bulkdisablemodal"} closeId={"bulkDisableConfirmClose"}
                             modalContentId={"disableBulkModalContent"} cancelId={"bulkCancelDisable"} spinnerId={"loadBulkDisableSubmit"} submitId={"bulkDisableSubmit"}
                             clickFunction={() => disablePerform("bulkDisableSubmit", "loadBulkDisableSubmit", "bulkDisableConfirmClose", ppRules.start, ppRules.end)} />
            <BulkActionModal start={ppRules.start} end={ppRules.end} header={"Delete Devices"} modalName={"bulkdeletemodal"} closeId={"bulkDeleteConfirmClose"}
                             modalContentId={"deleteBulkModalContent"} cancelId={"bulkCancelDelete"} spinnerId={"loadBulkDeleteSubmit"} submitId={"bulkDeleteSubmit"}
                             clickFunction={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkDeleteConfirmClose", ppRules.start, ppRules.end)} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default Devices;