import Button from "./Button";

const ConfirmModal = (props) => {

    return (
        <>
            <div className={"modal fade"} id={"confirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticConfirmModalBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticConfirmModalBackdropLabel"}></h2>
                            <button type={"button"} className={"btn-close"} id={"confirmModalConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <h2 className={"m-3"} style={{textAlign: "left"}} id={"confirmModalModalContent"}></h2>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    data-bs-dismiss={"modal"} id={"confirmModalCancel"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none"} style={{width: "75px"}}
                                    disabled={true} spinner={true} text={"Loading"} id={"loadConfirmModalSubmit"}/>
                            <button type={"button"} className={"btn btn-primary"} style={{width: "75px"}}
                                    id={"confirmModalSubmit"}>Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <input type={"hidden"} id={"uContent"}/>
        </>
    )

};

export default ConfirmModal;