import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {ToastContainer} from "react-toastify";
import {campusToGuid, formSuccess, getUTCTimestamp, handleSubmitActions, formFail} from "../../libs";

const LegacyAlertSystemSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [saveBtn, setSaveBtn] = useState(false);
    const [item1, setItem1] = useState(props?.AcceptButtonEnabled);
    const [item2, setItem2] = useState(props?.FinishButtonEnabled);
    const [item3, setItem3] = useState(props?.ReasonCodeEnabled);
    const [item4, setItem4] = useState(props?.ClearTimeInterval);
    const [item5, setItem5] = useState(props?.FinishTimeInterval);
    const [item6, setItem6] = useState(props?.ReleaseTimeInterval);
    const [item7, setItem7] = useState(props?.HighPriorityAlertTypes);

    useEffect(() => {
        let newVal, newPos, span, e;
        span = document.getElementById("rangeSpan1");
        e = document.getElementById("range1")
        newVal = Number((e.value - e.min) * 100 / (e.max - e.min));
        newPos = 10 - (newVal * .175);
        span.style.left = `calc(${newVal}% + (${newPos}px))`
    }, [item6])

    useEffect(() => {
        let newVal, newPos, span, e;
        span = document.getElementById("rangeSpan2");
        e = document.getElementById("range2")
        newVal = Number((e.value - e.min) * 100 / (e.max - e.min));
        newPos = 10 - (newVal * .175);
        span.style.left = `calc(${newVal}% + (${newPos}px))`
    }, [item5])

    useEffect(() => {
        let newVal, newPos, span, e;
        span = document.getElementById("rangeSpan3");
        e = document.getElementById("range3")
        newVal = Number((e.value - e.min) * 100 / (e.max - e.min));
        newPos = 10 - (newVal * .175);
        span.style.left = `calc(${newVal}% + (${newPos}px))`
    }, [item4])

    function performSave(){
        let payload, timestamp;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        timestamp = getUTCTimestamp();
        payload = {AcceptButtonEnabled: item1, FinishButtonEnabled: item2, ReasonCodeEnabled: item3,
            ClearTimeInterval: parseInt(item4), FinishTimeInterval: parseInt(item5),
            ReleaseTimeInterval: parseInt(item6), CampusId: campusToGuid(dData, dData?.acnt), HighPriorityAlertTypes: item7,
            ModifiedBy: dData.username, DateModified: timestamp}

        ApiRequester({reqEndpoint: "save", Tbl: "CampusConfig", PKey: "CampusId", Payload: [payload],
                            CampusId: campusToGuid(dData, dData?.acnt)}).then(data => {
            if(data[0]){
                setSaveBtn(false);
                props?.setGlobalConfigData(prevVal => ({
                    ...prevVal, AcceptButtonEnabled: item1, FinishButtonEnabled: item2, ReasonCodeEnabled: item3,
                    ClearTimeInterval: parseInt(item4), FinishTimeInterval: parseInt(item5),
                    ReleaseTimeInterval: parseInt(item6), HighPriorityAlertTypes: item7
                }));
                formSuccess("Saved alert settings", "editSubmit", "loadEditSubmit");
            }
            else{
                formFail("Error encountered, save failed", "editSubmit", "loadEditSubmit");
            }
        })
    }


    function cascadeOff(chgItem){
        let input2, input3;
        input2 = document.getElementById("item2");
        input3 = document.getElementById("item3");
        if (chgItem === "item1"){
            if (!item1){
                input2.disabled = false;
            }else{
                setItem2(false);
                setItem3(false);
                input2.disabled = true;
                input3.disabled = true;
            }
        }
        else if (chgItem === "item2"){
            if (!item2){
                input3.disabled = false;
            }
            else{
                setItem3(false);
                input3.checked = "unchecked";
                input3.disabled = true;
            }
        }
    }

    function removeItem(theArray, theValue){
        theArray = theArray.filter(item => item !== theValue)
        return theArray
    }

    return (
        <>
            <div className={"card"}>
                <div className={"card-header"}>
                    <h4 className={"card-header-title"}>Alert Interactions</h4>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    {saveBtn && <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary"} onClick={() => performSave()} />}
                </div>
                <div className={"card-body"}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Accept Alerts</h4>
                                    <p className={"text-muted"}>Users must accept alerts being responded to.</p>
                                </div>
                                <div className={"col-auto"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} id={"item1"} type={"checkbox"}
                                               checked={item1}
                                               onChange={() => {
                                                   setItem1(!item1);
                                                   setSaveBtn(true);
                                                   cascadeOff("item1")
                                               }} />
                                        <label className={"form-check-label"} htmlFor={"item1"} id={"label1"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Finish Alerts</h4>
                                    <p className={"text-muted"}>User must mark when alerts are complete.</p>
                                </div>
                                <div className={"col-auto"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} id={"item2"} type={"checkbox"}
                                               checked={item2}
                                               onChange={() => {
                                                   setItem2(!item2);
                                                   setSaveBtn(true);
                                                   cascadeOff("item2")
                                               }} />
                                        <label className={"form-check-label"} htmlFor={"item2"} id={"label2"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Record Reason</h4>
                                    <p className={"text-muted"}>User can record a reason for the alert.</p>
                                </div>
                                <div className={"col-auto"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} id={"item3"} type={"checkbox"}
                                               checked={item3}
                                               onChange={() => {
                                                   setItem3(!item3);
                                                   setSaveBtn(true);
                                               }} />
                                        <label className={"form-check-label"} htmlFor={"item3"} id={"label3"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Release Timeout</h4>
                                    <p className={"text-muted"}>Time to release alert after accepted with no device clear.</p>
                                    <input className={"form-control form-text range-input"} max={60} min={1} type={"number"} placeholder={item6} onBlur={(e) => {
                                        if (parseInt(e.target.value) >= parseInt(e.target.min) && parseInt(e.target.value) <= parseInt(e.target.max)) {
                                            setItem6(parseInt(e.target.value));
                                            setSaveBtn(true);
                                        }
                                        e.target.value = "";
                                    }}/>
                                    <div className={"range-container"}>
                                        <span id={"rangeSpan1"}>{item6} Min</span>
                                        <input id={"range1"} className={"form-range"} type={"range"} min={"1"} step={"1"}
                                               max={"60"} value={item6} onChange={(e) => {
                                                   setItem6(parseInt(e.target.value));
                                                   setSaveBtn(true);
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Finish Timeout</h4>
                                    <p className={"text-muted"}>Time to auto finish alarm if stale after accept and clear.</p>
                                    <input className={"form-control form-text range-input"} max={60} min={5} type={"number"} placeholder={item5} onBlur={(e) => {
                                        if (parseInt(e.target.value) >= parseInt(e.target.min) && parseInt(e.target.value) <= parseInt(e.target.max) && parseInt(e.target.value) > item4) {
                                            setItem5(parseInt(e.target.value));
                                            setSaveBtn(true);
                                        }
                                        e.target.value = "";
                                    }}/>
                                    <div className={"range-container"}>
                                        <span id={"rangeSpan2"}>{item5} Min</span>
                                        <input id={"range2"} className={"form-range"} type={"range"} min={"5"} step={"1"} max={"60"} value={item5}
                                               onChange={(e) => {
                                                   if (parseInt(e.target.value) > item4){
                                                       setItem5(parseInt(e.target.value));
                                                        setSaveBtn(true);
                                                   }
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Clear Timeout</h4>
                                    <p className={"text-muted"}>Time to autoclear alert after no contact from system.</p>
                                    <input className={"form-control form-text range-input"} max={59} min={4} type={"number"} placeholder={item4} onBlur={(e) => {
                                        if (parseInt(e.target.value) >= parseInt(e.target.min) && parseInt(e.target.value) <= parseInt(e.target.max)) {
                                            setItem4(parseInt(e.target.value));
                                            if (parseInt(e.target.value) >= item5){
                                                setItem5(parseInt(e.target.value)+1);
                                            }
                                            setSaveBtn(true);
                                        }
                                        e.target.value = "";
                                    }}/>
                                    <div className={"range-container"}>
                                        <span id={"rangeSpan3"}>{item4} Min</span>
                                        <input id={"range3"} className={"form-range"} type={"range"} min={"4"} step={"1"} max={"59"} value={item4}
                                               onChange={(e) => {
                                                   setItem4(parseInt(e.target.value));
                                                    if (parseInt(e.target.value) >= item5){
                                                        setItem5(parseInt(e.target.value)+1);
                                                    }
                                                   setSaveBtn(true);
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"} style={{minWidth: "110px"}}>
                                    <h4 className={"font-weight-base mb-1"}>High Priority Alert Types</h4>
                                    <p className={"text-muted"}>Alert types that behave like High Priority</p>
                                </div>
                                <div className={"col-auto"}>
                                    <div className={"list-group list-group-horizontal"}>
                                        <label className={"form-check-label"} htmlFor={"hpat2"}>
                                            <div className={"list-group-item"}>
                                                <input id={"hpat2"} className={"form-check-input me-2"} type={"checkbox"} value={"Alert"}
                                                    checked={item7.includes("Alert")}
                                                    onChange={() => {
                                                        item7.includes("Alert") ? setItem7(() => removeItem(item7, "Alert")) : setItem7(prevVal => [...prevVal, "Alert"]);
                                                        setSaveBtn(true);
                                                    }} />
                                                Alert
                                            </div>
                                        </label>
                                        <label className={"form-check-label"} htmlFor={"hpat1"}>
                                            <div className={"list-group-item"}>
                                                <input id={"hpat1"} className={"form-check-input me-2"} type={"checkbox"} value={"Assist"}
                                                       checked={item7.includes("Assist")}
                                                       onChange={() => {
                                                           item7.includes("Assist") ? setItem7(() => removeItem(item7, "Assist")) : setItem7(prevVal => [...prevVal, "Assist"])
                                                           setSaveBtn(true);
                                                       }} />
                                                Assist
                                            </div>
                                        </label>
                                        <label className={"form-check-label"} htmlFor={"hpat3"}>
                                            <div className={"list-group-item"}>
                                                <input id={"hpat3"} className={"form-check-input me-2"} type={"checkbox"} value={"CallCordMissing"}
                                                    checked={item7.includes("CallCordMissing")}
                                                    onChange={() => {
                                                        item7.includes("CallCordMissing") ? setItem7(() => removeItem(item7, "CallCordMissing")) : setItem7(prevVal => [...prevVal, "CallCordMissing"]);
                                                        setSaveBtn(true);
                                                    }} />
                                                Call Cord Missing
                                            </div>
                                        </label>
                                        <label className={"form-check-label"} htmlFor={"hpat4"}>
                                            <div className={"list-group-item"}>
                                                <input id={"hpat4"} className={"form-check-input me-2"} type={"checkbox"} value={"Alert"}
                                                    checked={item7.includes("Fall")}
                                                    onChange={() => {
                                                        item7.includes("Fall") ? setItem7(() => removeItem(item7, "Fall")) : setItem7(prevVal => [...prevVal, "Fall"]);
                                                        setSaveBtn(true);
                                                    }} />
                                                Fall
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default LegacyAlertSystemSettings;